import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import * as ScreenOrientation from 'expo-screen-orientation';
import { Fragment, useCallback } from 'react';
import { Platform, RefreshControl, StatusBar } from 'react-native';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { LearnProgress } from '@src/components/LearnProgress';
import { ScrollHere, ScrollView } from '@src/components/ScrollView';
import { SessionCard } from '@src/components/SessionCard';
import { TabHeader } from '@src/components/TabHeader';
import { Heading } from '@src/components/Text';
import { View } from '@src/components/View';
import { Environment, environment } from '@src/constants';
import { SessionWithState, useFeed } from '@src/hooks/useFeed';
import { useI18n } from '@src/lib/i18n';
import { useLearnQuery } from '@src/screens/Learn.graphql.generated';
import Sentry from '@src/sentry';

export function Learn(props: { onSessionCardPress: (value: SessionWithState) => void }) {
  const { data, refetch, loading } = useLearnQuery();
  const isFocused = useIsFocused();
  const { $t } = useI18n();

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      }

      try {
        refetch();
      } catch (e) {
        // HMR error in dev
        if (environment !== Environment.DEVELOPMENT) {
          Sentry.captureException(e);
        }
      }

      return () => {
        if (Platform.OS !== 'web') {
          ScreenOrientation.unlockAsync();
        }
      };
    }, [refetch]),
  );

  const { feed, activeIndex } = useFeed(data?.treatmentV2);

  const numTotal = data?.treatmentV2?.length ?? 12;

  let currentIndex = 0;
  const numCompleted =
    data?.treatmentV2 && data.treatmentV2[data.treatmentV2.length - 1].progress === 'COMPLETED'
      ? numTotal
      : Math.max(activeIndex, 0);

  return (
    <View style={{ flex: 1 }}>
      {isFocused ? <StatusBar backgroundColor="white" barStyle="dark-content" /> : null}
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={!!(feed && loading)}
            onRefresh={() => {
              refetch();
            }}
            title={$t({ id: 'Learn_refreshIndicator', defaultMessage: 'Update' })}
            progressViewOffset={150}
          />
        }
        testID="Learn_scrollView"
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingBottom: 20,
        }}
      >
        <TabHeader heading={$t({ id: 'Learn_heading', defaultMessage: 'Learn' })} />
        <LearnProgress numCompleted={numCompleted} numTotal={numTotal} />
        {feed ? (
          <View spacing={50} style={{ marginTop: 20 }}>
            {feed.map((section) => (
              <View spacing={10} key={section.title}>
                <View style={{ marginTop: 10 }}>
                  <Heading text={section.title} style={{}} level={2} />
                </View>
                {section.data.map((sessionWithState) => {
                  const { slug, state, session: item } = sessionWithState;
                  currentIndex += 1;

                  return (
                    <Fragment key={slug}>
                      <SessionCard
                        collapsed={state !== 'active' || !item.details.length}
                        testID={`SessionCard_${slug}`}
                        state={state}
                        session={{ ...item }}
                        onPressBottom={() => {}}
                        onPressTop={() => {
                          props.onSessionCardPress(sessionWithState);
                        }}
                      />
                      {currentIndex === activeIndex ? (
                        <View style={{ marginTop: 20 }}>
                          <ScrollHere offsetY={0} />
                        </View>
                      ) : null}
                    </Fragment>
                  );
                })}
              </View>
            ))}
          </View>
        ) : (
          <ActivityIndicator />
        )}
      </ScrollView>
    </View>
  );
}
