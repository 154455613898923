import { Platform } from 'react-native';
import SystemSetting from 'react-native-system-setting';

import { nativeVersionAtLeast } from '@src/constants';

export async function getVolume() {
  if (Platform.OS !== 'web' && nativeVersionAtLeast('2.1.1')) {
    return SystemSetting.getVolume();
  }

  return 1;
}
