import { useState } from 'react';

import { Button } from '@oui/app-core/src/components/Button';
import { PickerInput } from '@oui/app-core/src/components/PickerInput';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { getPatientSupporterRelation } from '@oui/lib/src/getPatientSupporterRelation';
import { PatientSupporterRelation } from '@oui/lib/src/types';

export const PatientRelationPicker = (props: {
  onSubmit: (data: PatientSupporterRelation) => void;
  relation: PatientSupporterRelation;
}) => {
  const { $t } = useI18n();
  const [value, setValue] = useState<PatientSupporterRelation>(props.relation);

  return (
    <View
      testID="PatientRelationPicker"
      spacing={25}
      style={{
        flex: 1,
      }}
    >
      <PickerInput
        value={value}
        onChangeValue={setValue}
        items={Object.values(PatientSupporterRelation).map((v) => ({
          label: getPatientSupporterRelation({ $t, patientSupporterRelation: v }),
          value: v,
        }))}
        label={$t({
          id: 'PatientRelationPicker_patientRelationLabel',
          defaultMessage: 'Relation',
        })}
        testID="PatientRelationPicker_input"
      />

      <Button
        onPress={() => {
          props.onSubmit(value);
        }}
        text={$t({
          id: 'PatientRelationPicker_submit',
          defaultMessage: 'Next',
        })}
        testID="PatientRelationPicker_submitButton"
        variant="contained"
        alignSelf="center"
      />
    </View>
  );
};
