import { ParamListBase, StackNavigationState, TypedNavigator } from '@react-navigation/native';
import { StackNavigationEventMap, StackNavigationOptions } from '@react-navigation/stack';
import { ComponentType } from 'react';
import { Platform } from 'react-native';

import { IntlShape } from '@src/lib/i18n';
import Login from '@src/screens/Login';
import RequestResetPassword from '@src/screens/RequestResetPassword';
import ResetPassword from '@src/screens/ResetPassword';
import SignUp from '@src/screens/SignUp';
import { Color as ColorType, DarkColor } from '@src/styles';
import { AuthParamList } from '@src/types/navigation';

export default function AuthStack<T extends AuthParamList>({
  $t,
  Screen,
  theme,
  Color,
}: {
  $t: IntlShape['$t'];
  Screen: TypedNavigator<
    T,
    StackNavigationState<ParamListBase>,
    StackNavigationOptions,
    StackNavigationEventMap,
    ComponentType
  >['Screen'];
  theme: 'dark' | 'light' | 'no-preference';
  Color: typeof ColorType;
}) {
  const options: StackNavigationOptions = {
    headerRight: () => null,
    headerStyle: [
      {
        borderBottomWidth: 0,
        backgroundColor: theme === 'dark' ? DarkColor.backgroundColor : Color.backgroundColor,
      },
      Platform.OS === 'web'
        ? ({
            boxShadow: undefined,
            // eslint-disable-next-line
          } as any)
        : {
            shadowOpacity: 0,
            elevation: 0,
          },
    ],
  };
  return (
    <>
      <Screen name="Login" component={Login} options={{ ...options, headerTitle: '' }} />
      <Screen
        name="SignUp"
        component={SignUp as any} // eslint-disable-line
        options={{ ...options, headerShown: false }}
      />
      <Screen
        name="ResetPassword"
        component={ResetPassword as any} // eslint-disable-line
        options={{
          ...options,
          headerShown: false,
          title: $t({ id: 'ResetPassword_title', defaultMessage: 'Reset password' }),
        }}
      />
      <Screen
        name="RequestResetPassword"
        component={RequestResetPassword as any} // eslint-disable-line
        options={{
          ...options,
          headerTitle: $t({ id: 'RequestResetPassword_title', defaultMessage: 'Forgot password' }),
        }}
      />
    </>
  );
}
