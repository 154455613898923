// This file is automatically generated. See gqlcodegen.yml for details

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Action": [
      "ActivityAddAction",
      "ActivityRateAction",
      "CopingCardAddAction",
      "CopingCardReviewAction",
      "HopeKitAddAction",
      "HopeKitReviewAction",
      "MyPlanReviewAction",
      "RelaxAction",
      "SleepDiaryEntryMorningAction",
      "SleepDiaryEntryNightAction",
      "StaticAction",
      "ThoughtDiaryEntrySpotAction",
      "ThoughtDiaryEntrySwitchAction",
      "ThoughtDiaryEntryTestAction"
    ],
    "BaseError": [
      "InvalidPasswordError",
      "OutdatedBotStateError",
      "SimpleError",
      "UserDisabledError",
      "ValidationError"
    ],
    "CareTeamEntity": [
      "Organization",
      "Practitioner"
    ],
    "HopeKitItem": [
      "HopeKitImage",
      "HopeKitQuote",
      "HopeKitVideo"
    ],
    "IPatientProfile": [
      "Patient",
      "PatientProfile"
    ],
    "LegacyQuizSetItem": [
      "LegacyQuizSetItemMultipleChoiceQuestion",
      "LegacyQuizSetItemSingleChoiceQuestion",
      "LegacyQuizSetItemVideo"
    ],
    "MutationAcceptYstNominationResult": [
      "SimpleError",
      "YstNomination"
    ],
    "MutationAddYstSupporterEventResult": [
      "SimpleError",
      "YstSupportProfile"
    ],
    "MutationCreateJobResult": [
      "Job",
      "SimpleError",
      "ValidationError"
    ],
    "MutationDeclineYstNominationResult": [
      "SimpleError",
      "YstNomination"
    ],
    "MutationInviteYSTGuardianResult": [
      "Patient",
      "SimpleError",
      "ValidationError"
    ],
    "MutationInviteYSTYouthResult": [
      "Patient",
      "SimpleError",
      "ValidationError"
    ],
    "MutationLoginWithEmailResult": [
      "InvalidPasswordError",
      "SimpleError",
      "UserDisabledError",
      "UserSession"
    ],
    "MutationLoginWithTokenResult": [
      "InvalidPasswordError",
      "SimpleError",
      "UserDisabledError",
      "UserSession"
    ],
    "MutationNominateYstSupportersResult": [
      "MutationNominateYstSupportersSuccess",
      "SimpleError",
      "ValidationError"
    ],
    "MutationPersistBotStateResult": [
      "BotState",
      "OutdatedBotStateError",
      "SimpleError"
    ],
    "MutationPreviewYstPatientMessageResult": [
      "SimpleError",
      "YstPatientMessagePreview"
    ],
    "MutationRateYstSupporterCheckinResult": [
      "SimpleError",
      "YstSupporterCheckin"
    ],
    "MutationResendYstNominationInviteResult": [
      "SimpleError",
      "ValidationError",
      "YstNomination"
    ],
    "MutationResendYstPatientMessageResult": [
      "MutationResendYstPatientMessageSuccess",
      "SimpleError"
    ],
    "MutationSignupYSTGuardianResult": [
      "Patient",
      "SimpleError",
      "ValidationError"
    ],
    "MutationSignupYSTYouthResult": [
      "Patient",
      "SimpleError",
      "ValidationError"
    ],
    "MutationUpdateFirstCheckinDateResult": [
      "MutationUpdateFirstCheckinDateSuccess",
      "SimpleError",
      "ValidationError"
    ],
    "MutationUpdateOnboardingAvailabilityResult": [
      "MutationUpdateOnboardingAvailabilitySuccess",
      "SimpleError"
    ],
    "MutationUpdateOuiUserResult": [
      "CurrentOuiUser",
      "InvalidPasswordError",
      "SimpleError"
    ],
    "MutationUpdateRecurringAvailabilityResult": [
      "MutationUpdateRecurringAvailabilitySuccess",
      "SimpleError"
    ],
    "MutationUpdateYstMentalHealthConcernsResult": [
      "MutationUpdateYstMentalHealthConcernsSuccess",
      "SimpleError"
    ],
    "MutationUpdateYstSupporterEventResult": [
      "SimpleError",
      "YstSupportProfile"
    ],
    "OuiUserType": [
      "OuiAdmin",
      "Patient",
      "Practitioner",
      "Registrar"
    ],
    "PendingAction": [
      "PendingActivityAddAction",
      "PendingActivityRateAction",
      "PendingCopingCardAddAction",
      "PendingCopingCardReviewAction",
      "PendingHopeKitAddAction",
      "PendingHopeKitReviewAction",
      "PendingMyPlanReviewAction",
      "PendingRelaxAction",
      "PendingSleepDiaryEntryMorningAction",
      "PendingSleepDiaryEntryNightAction",
      "PendingThoughtDiaryEntrySpotAction",
      "PendingThoughtDiaryEntrySwitchAction",
      "PendingThoughtDiaryEntryTestAction"
    ],
    "Practice": [
      "ActivityPractice",
      "RelaxDiaryEntryPractice",
      "SleepDiaryEntryPractice",
      "ThoughtDiaryEntryPractice"
    ],
    "QuizSetItem": [
      "QuizSetItemChoice",
      "QuizSetItemVideo"
    ],
    "YstProfile": [
      "YstGuardianProfile",
      "YstSupportProfile",
      "YstYouthProfile"
    ],
    "YstSupporterActivity": [
      "YstSupporterCheckin",
      "YstSupporterEvent"
    ]
  }
};
      export default result;
    