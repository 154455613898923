import 'core-js/features/promise';
import 'core-js/features/object';
import 'core-js/features/array';
import 'cross-fetch/polyfill';

// @ts-expect-error
import Modal from 'modal-react-native-web';

import '@src/global.css';
// WebDatePickerInner
import 'react-datepicker/dist/react-datepicker.css';
// WebTooltip
import '@reach/tooltip/styles.css';

Modal.setAppElement('#root');

const isOldBrowser = typeof Number.isNaN !== 'function';
if (isOldBrowser) {
  // @ts-expect-error
  import('@src/global.oldbrowser.css');
}

export default isOldBrowser
  ? Promise.all([import('core-js'), import('@src/lib/intlPolyfill')])
  : Promise.resolve();
