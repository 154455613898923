// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientSupporterLinkDetailsQueryVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
}>;


export type PatientSupporterLinkDetailsQuery = { readonly patientSupporterLinkDetails: { readonly patientName: string, readonly relation: Types.PatientSupporterRelation } };


export const PatientSupporterLinkDetailsDocument = gql`
    query PatientSupporterLinkDetails($token: String!) {
  patientSupporterLinkDetails(token: $token) {
    patientName
    relation
  }
}
    `;

/**
 * __usePatientSupporterLinkDetailsQuery__
 *
 * To run a query within a React component, call `usePatientSupporterLinkDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientSupporterLinkDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientSupporterLinkDetailsQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function usePatientSupporterLinkDetailsQuery(baseOptions: Apollo.QueryHookOptions<PatientSupporterLinkDetailsQuery, PatientSupporterLinkDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientSupporterLinkDetailsQuery, PatientSupporterLinkDetailsQueryVariables>(PatientSupporterLinkDetailsDocument, options);
      }
export function usePatientSupporterLinkDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientSupporterLinkDetailsQuery, PatientSupporterLinkDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientSupporterLinkDetailsQuery, PatientSupporterLinkDetailsQueryVariables>(PatientSupporterLinkDetailsDocument, options);
        }
export type PatientSupporterLinkDetailsQueryHookResult = ReturnType<typeof usePatientSupporterLinkDetailsQuery>;
export type PatientSupporterLinkDetailsLazyQueryHookResult = ReturnType<typeof usePatientSupporterLinkDetailsLazyQuery>;

export function queryPatientSupporterLinkDetails(client: Apollo.ApolloClient<unknown>, variables: PatientSupporterLinkDetailsQueryVariables, options?: Omit<Apollo.QueryOptions<PatientSupporterLinkDetailsQuery, PatientSupporterLinkDetailsQueryVariables>, "query" | 'variables'>) {
  return client.query<PatientSupporterLinkDetailsQuery, PatientSupporterLinkDetailsQueryVariables>({
    query: PatientSupporterLinkDetailsDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







