import { ComponentProps, ReactElement } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
  useFormContext,
} from 'react-hook-form';

import { GQLDate, GQLDateTime, GQLTime } from '@oui/lib/src/types/scalars';

import { DateTimeInput, DateTimeInputProps } from '@src/components/DateTimeInput';
import { PickerInput } from '@src/components/PickerInput';
import { RadioInput } from '@src/components/RadioInput';
import { SwitchInput } from '@src/components/SwitchInput';
import { EmailInput, TextInput } from '@src/components/TextInput';

export { useZodForm, useZodFormContext } from '@oui/lib/src/form';

/*
 * Some of the input props we use have a discrimited union to safely accept label/accessibilityLabel
 * props in different combinations. That doesn't play nicely with built-in Omit
 * https://stackoverflow.com/questions/67794339/why-doesnt-discriminated-union-work-when-i-omit-require-props
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DistributiveOmit<T, K extends PropertyKey> = T extends any ? Omit<T, K> : never;
export type InputRenderer<TComponentProps extends { value?: unknown; onChangeValue?: unknown }> = (
  renderProps: {
    field: Omit<
      ControllerRenderProps<FieldValues, FieldPath<FieldValues>>,
      'value' | 'onChange'
    > & {
      value: TComponentProps['value'];
      onChange: NonNullable<TComponentProps['onChangeValue']>;
    };
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<FieldValues>;
  },
  props: DistributiveOmit<TComponentProps, 'value' | 'onChangeValue'>,
) => ReactElement;

export const TextInputRender: InputRenderer<ComponentProps<typeof TextInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  if (formContext && props.label) formContext.labels[name] = props.label as string;

  return (
    <TextInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onBlur={onBlur}
      onChangeValue={onChange}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export const EmailInputRender: InputRenderer<ComponentProps<typeof EmailInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  if (formContext && props.label) formContext.labels[name] = props.label as string;

  return (
    <EmailInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onBlur={onBlur}
      onChangeValue={onChange}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export const PickerInputRender: InputRenderer<ComponentProps<typeof PickerInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  if (formContext && props.label) formContext.labels[name] = props.label as string;

  return (
    <PickerInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onClose={onBlur}
      onChangeValue={onChange}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export const DateTimeInputRender: InputRenderer<DateTimeInputProps<GQLDateTime>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  if (formContext && props.label) formContext.labels[name] = props.label as string;
  return (
    <DateTimeInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      mode="datetime"
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export const DateInputRender: InputRenderer<DateTimeInputProps<GQLDate>> = (renderProps, props) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  if (formContext && props.label) formContext.labels[name] = props.label as string;
  return (
    <DateTimeInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      mode="date"
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export const TimeInputRender: InputRenderer<DateTimeInputProps<GQLTime>> = (renderProps, props) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  if (formContext && props.label) formContext.labels[name] = props.label as string;
  return (
    <DateTimeInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      mode="time"
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export const SwitchInputRender: InputRenderer<ComponentProps<typeof SwitchInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
  } = renderProps;
  return (
    <SwitchInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      showOnOff
      {...props}
    />
  );
};

export const RadioInputRender: InputRenderer<ComponentProps<typeof RadioInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  if (formContext && props.label) formContext.labels[name] = props.label as string;
  return (
    <RadioInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      error={error?.message}
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      {...props}
    />
  );
};

export { Controller };
