import { useI18n } from '@src/lib/i18n';

export function useFormatDuration() {
  const { formatNumber, formatList } = useI18n();

  const formatDuration = (
    duration: {
      hours?: number;
      minutes?: number;
      seconds?: number;
    },
    unitDisplay: 'short' | 'long' | 'narrow' = 'long',
  ) => {
    let list: string[] = [];

    if (duration?.hours) {
      list.push(
        formatNumber(duration.hours, {
          style: 'unit',
          unit: 'hour',
          unitDisplay,
        }),
      );
    }

    if (duration?.minutes) {
      list.push(
        formatNumber(duration.minutes, {
          style: 'unit',
          unit: 'minute',
          unitDisplay,
        }),
      );
    }

    if (duration?.seconds) {
      list.push(
        formatNumber(duration.seconds, {
          style: 'unit',
          unit: 'second',
          unitDisplay,
        }),
      );
    }

    return formatList(list);
  };

  return {
    formatDuration,
  };
}
