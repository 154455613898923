import { ComponentProps } from 'react';
import {
  Item,
  HeaderButton as RNHeaderButton,
  HeaderButtons as RNHeaderButtons,
} from 'react-navigation-header-buttons';

import { Icon } from '@src/components/Icon';

// define IconComponent, color, sizes and OverflowIcon in one place
const HeaderButton = (props: RNHeaderButton['props']) => (
  <RNHeaderButton
    IconComponent={Icon}
    {...props}
    // @ts-expect-error
    accessibilityRole="button"
  />
);

export const HeaderButtons = (props: RNHeaderButtons['props']) => {
  return <RNHeaderButtons HeaderButtonComponent={HeaderButton} {...props} />;
};

export const HeaderItem = (
  props: Omit<Item['props'], 'iconName'> & {
    iconName: ComponentProps<typeof Icon>['name'];
    accessibilityLabel: string | undefined;
  },
) => {
  // @ts-expect-error Our Icon component for Item accepts a different prop type
  return <Item {...props} />;
};
