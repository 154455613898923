import { Platform, Linking as RNLinking, ScrollView } from 'react-native';

import { AuthScreenContainer } from '@src/components/AuthScreenContainer';
import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { manifest } from '@src/constants';

export function AppError(props: { retry: () => unknown; type?: 'loading' | 'runtime' }) {
  return (
    <AuthScreenContainer
      heading="Something went wrong"
      // @src/components/ScrollView uses useIsFocused internally which depends on react-navigation mounting
      // successfully. Since in an error state, we may not be able to guarantee that, we use a
      // standard ScrollView here which should be sufficient for our needs
      _scrollView={ScrollView}
    >
      <View spacing={40} style={{ padding: Platform.OS === 'web' ? 20 : 0 }}>
        {props.type === 'runtime' ? (
          <Text
            text={`We're sorry, but something went wrong. Please try reloading ${manifest.name}.`}
          />
        ) : (
          <Text
            text={`We're sorry, but we couldn't load ${manifest.name}. Please make sure you're connected to the internet.`}
          />
        )}
        <View>
          <Text text="If you're in crisis, please call the National Suicide Prevention Lifeline." />
          <Button
            text="Call Lifeline"
            onPress={() => {
              RNLinking.openURL('tel:988');
            }}
            alignSelf="flex-start"
            variant="text"
            testID="AppError_callLifelineButton"
          />
        </View>
        <Button
          text={`Reload ${manifest.name}`}
          onPress={() => props.retry()}
          testID="AppError_reloadButton"
        />
      </View>
    </AuthScreenContainer>
  );
}
