import { NavigationProp } from '@react-navigation/core';
import { CommonActions } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useCallback } from 'react';

import { SessionWithState } from '@oui/app-core/src/hooks/useFeed';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { NumberParam } from '@oui/app-core/src/types/navigation';
import { ContentType } from '@oui/lib/src/types';

import { RootStackParamList, StaticNavigationProp } from '@src/types/navigation';

type NarrowNavigationType = StaticNavigationProp<keyof RootStackParamList>;

// We need the navigation prop to be generic since this hook is used in different navigation contexts
// and there is not single generic type that satisfies all our uses so we rely on any here and quickly
// cast to a more narrow type for safety within the hook
// eslint-disable-next-line
export const useNavigateToStaticSessionHook = (props: { navigation: NavigationProp<any> }) => {
  const navigation = props.navigation as unknown as NarrowNavigationType;
  const [staticSessionPages] = usePersistedState('staticSessionCurrentPages', {});

  const navigateToStaticSession = useCallback(
    (session: SessionWithState, previousScreenIcon: keyof AppCore.IconGlyphs) => {
      const savedPage = staticSessionPages[session.slug];

      if (!!savedPage) {
        (navigation.getParent() as StackNavigationProp<RootStackParamList>)?.dispatch((state) => {
          const { slug, session: item } = session;
          const newRoutes = [...state.routes];
          for (let i = 0; i < savedPage + 1; i = i + 1) {
            const params: RootStackParamList['StaticSession'] = {
              slug: slug as ContentType,
              page: i.toString() as NumberParam,
              title: item.title,
              previousScreenIcon,
            };
            newRoutes.push({
              name: 'StaticSession',
              key: `StaticSession-${slug}-${i}`,
              params,
            });
          }

          return CommonActions.reset({
            ...state,
            routes: newRoutes,
            index: newRoutes.length - 1,
          });
        });
      } else {
        navigation.navigate('StaticSession', {
          slug: session.slug as ContentType,
          page: '0' as NumberParam,
          title: session.session.title,
          previousScreenIcon,
        });
      }
    },
    [navigation, staticSessionPages],
  );

  return {
    navigateToStaticSession,
  };
};
