// This file is automatically generated. See gqlcodegen.yml for details
// @ts-ignore

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import * as Types from '@oui/lib/src/types/graphql.generated';
import * as CustomScalars from '@oui/lib/src/types/scalars';

const defaultOptions = {} as const;
export type StartMyPlanContactImageUploadMutationVariables = Types.Exact<{
  input: Types.ResumableAssetCreateInput;
}>;

export type StartMyPlanContactImageUploadMutation = {
  readonly uploadResumableAsset: {
    readonly resumableUploadAssetURI: string;
    readonly asset: {
      readonly __typename: 'Asset';
      readonly assetID: CustomScalars.GQLAssetID;
      readonly key: string;
      readonly context: string;
    };
  };
};

export type MyPlanAssetQueryVariables = Types.Exact<{
  context: Types.Scalars['String']['input'];
  key: Types.Scalars['String']['input'];
}>;

export type MyPlanAssetQuery = { readonly asset?: string | null };

export const StartMyPlanContactImageUploadDocument = gql`
  mutation StartMyPlanContactImageUpload($input: ResumableAssetCreateInput!) {
    uploadResumableAsset(input: $input) {
      resumableUploadAssetURI
      asset {
        __typename
        assetID
        key
        context
      }
    }
  }
`;

/**
 * __useStartMyPlanContactImageUploadMutation__
 *
 * To run a mutation, you first call `useStartMyPlanContactImageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMyPlanContactImageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMyPlanContactImageUploadMutation, { data, loading, error }] = useStartMyPlanContactImageUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartMyPlanContactImageUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartMyPlanContactImageUploadMutation,
    StartMyPlanContactImageUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartMyPlanContactImageUploadMutation,
    StartMyPlanContactImageUploadMutationVariables
  >(StartMyPlanContactImageUploadDocument, options);
}
export type StartMyPlanContactImageUploadMutationHookResult = ReturnType<
  typeof useStartMyPlanContactImageUploadMutation
>;

export function performStartMyPlanContactImageUpload(
  client: Apollo.ApolloClient<unknown>,
  variables: StartMyPlanContactImageUploadMutationVariables,
  options?: Omit<
    Apollo.MutationOptions<
      StartMyPlanContactImageUploadMutation,
      StartMyPlanContactImageUploadMutationVariables
    >,
    'mutation' | 'variables'
  >,
) {
  return client.mutate<
    StartMyPlanContactImageUploadMutation,
    StartMyPlanContactImageUploadMutationVariables
  >({
    mutation: StartMyPlanContactImageUploadDocument,
    variables,
    ...options,
  });
}

export const MyPlanAssetDocument = gql`
  query MyPlanAsset($context: String!, $key: String!) {
    asset(context: $context, key: $key)
  }
`;

/**
 * __useMyPlanAssetQuery__
 *
 * To run a query within a React component, call `useMyPlanAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPlanAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPlanAssetQuery({
 *   variables: {
 *      context: // value for 'context'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useMyPlanAssetQuery(
  baseOptions: Apollo.QueryHookOptions<MyPlanAssetQuery, MyPlanAssetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyPlanAssetQuery, MyPlanAssetQueryVariables>(MyPlanAssetDocument, options);
}
export function useMyPlanAssetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyPlanAssetQuery, MyPlanAssetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyPlanAssetQuery, MyPlanAssetQueryVariables>(
    MyPlanAssetDocument,
    options,
  );
}
export type MyPlanAssetQueryHookResult = ReturnType<typeof useMyPlanAssetQuery>;
export type MyPlanAssetLazyQueryHookResult = ReturnType<typeof useMyPlanAssetLazyQuery>;

export function queryMyPlanAsset(
  client: Apollo.ApolloClient<unknown>,
  variables: MyPlanAssetQueryVariables,
  options?: Omit<
    Apollo.QueryOptions<MyPlanAssetQuery, MyPlanAssetQueryVariables>,
    'query' | 'variables'
  >,
) {
  return client.query<MyPlanAssetQuery, MyPlanAssetQueryVariables>({
    query: MyPlanAssetDocument,
    variables,
    ...options,
  });
}
