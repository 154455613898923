import { useEffect, useMemo, useState } from 'react';

import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { VTTObject, parseRemoteVTT } from '@src/lib/vtt';

export function Subtitles(props: { uri: string; positionMillis: number; enabled: boolean }) {
  const [vtt, setVTT] = useState<VTTObject>({ captions: [] });

  useEffect(() => {
    parseRemoteVTT(props.uri).then(setVTT);
  }, [props.uri]);

  const currentCaption = useMemo(() => {
    return vtt.captions.find(
      (caption) =>
        caption.startMillis <= props.positionMillis && props.positionMillis < caption.endMillis,
    );
  }, [vtt, props.positionMillis]);

  return props.enabled && currentCaption?.caption ? (
    <View
      pointerEvents="none"
      style={{ backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: 10 }}
      importantForAccessibility="no-hide-descendants"
      accessibilityElementsHidden
    >
      <Text text={currentCaption.caption} color="white" textAlign="center" />
    </View>
  ) : null;
}
