import type { ApolloClient } from '@apollo/client';

import { ContentType } from '@oui/lib/src/types/graphql.generated';

import {
  CurrentUserDocument,
  CurrentUserQuery,
  SelfPatientQuery,
} from '@src/hooks/useCurrentUser.graphql.generated';

import { performFabricateStateV2End } from './mockUserState.graphql.generated';

export function mockConversationCompleteV2(client: ApolloClient<unknown>, convoID: ContentType) {
  return performFabricateStateV2End(
    client,
    { content: convoID },
    {
      update: (cache, result) => {
        if (!result.data) return;
        const query = cache.readQuery<CurrentUserQuery>({ query: CurrentUserDocument });
        const patientID = query?.currentUser?.user?.ID;
        const key = cache.identify({ __typename: 'PatientProfile', patient: { ID: patientID } });
        cache.modify({
          id: key,
          fields: {
            ID: (id) => {
              return id;
            },
            // @ts-expect-error apollo types changed in 3.8.x and are no longer happy with us
            // specifying the type of existingProgress. At some point should circle back and
            // figure out how to type this properly
            progress: (
              existingProgress: NonNullable<
                SelfPatientQuery['patientByPatientID']
              >['progress'] = [],
            ) => {
              if (!result.data) return existingProgress;
              const progress = existingProgress.filter((p) => p.content !== convoID);
              return [...progress, result.data.setOuiProgress];
            },
          },
        });
      },
    },
  );
}
