import { SegmentedProgressBar } from '@src/components/SegmentedProgressBar';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useI18n } from '@src/lib/i18n';
import { Color } from '@src/styles';

export function LearnProgress({
  numCompleted,
  numTotal,
}: {
  numCompleted: number;
  numTotal: number;
}) {
  const { $t } = useI18n();
  return (
    <View
      testID="LearnProgress"
      style={{ backgroundColor: Color.styleGuide.LogoCyan, padding: 10, marginHorizontal: -20 }}
      row
      accessibilityLabel={$t(
        {
          id: 'LearnProgress_accessibilityLabel',
          defaultMessage: 'Learn progress. {numCompleted} of {numTotal} completed',
        },
        { numCompleted, numTotal },
      )}
    >
      <Text
        text={$t(
          { id: 'LearnProgress_progressText', defaultMessage: '{numCompleted}/{numTotal}' },
          { numTotal, numCompleted },
        )}
        testID="LearnProgress_progressText"
        color="white"
        style={{ marginHorizontal: 12 }}
      />
      <SegmentedProgressBar length={numTotal} progress={numCompleted} variant="dark" />
    </View>
  );
}
