import { FunctionComponent, createElement } from 'react';
import { TouchableOpacity } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { Pill } from '@src/components/Pill';
import { SvgUri } from '@src/components/SvgUri';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useI18n } from '@src/lib/i18n';
import { Shadow, useTheme } from '@src/styles';

type Props = {
  assetName: string | FunctionComponent<SvgProps>;
  badgeText?: string;
  bannerText?: string;
  numCompleted: number;
  onPress: () => void;
  title: string;
  testID: string;
};

export function ArtifactButton({
  assetName,
  bannerText,
  badgeText,
  numCompleted,
  onPress,
  title,
  testID,
}: Props) {
  const { $t } = useI18n();
  const locked = numCompleted === 0;
  const { Color, scheme } = useTheme();
  const unlockedBackgroundColor = scheme === 'dark' ? Color.surfaceColor : '#ebf1ed';
  return (
    <TouchableOpacity
      testID={testID}
      onPress={onPress}
      disabled={locked}
      accessibilityState={{
        disabled: !!locked,
      }}
      style={[
        {
          borderRadius: 20,
          backgroundColor: locked ? Color.grayBackground : unlockedBackgroundColor,
        },
        locked ? null : Shadow.high,
      ]}
    >
      <View
        childFlex={1}
        row
        style={{
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            alignItems: 'flex-start',
            alignSelf: 'flex-end',
            borderBottomStartRadius: 20,
            overflow: 'hidden',
          }}
        >
          <View
            style={{
              borderBottomStartRadius: 20,
              borderTopStartRadius: 20,
              opacity: locked ? 0.1 : undefined,
              overflow: 'hidden',
            }}
          >
            {typeof assetName === 'string' ? (
              <SvgUri
                uri={`https://storage.googleapis.com/asset.oui.dev/static/artifacts/${assetName}.svg`}
                height={100}
                preserveAspectRatio="xMinYMax meet"
              />
            ) : (
              createElement(assetName, {
                height: 100,
                preserveAspectRatio: 'xMinYMax meet',
              })
            )}
          </View>
          {bannerText ? (
            <View
              style={{
                backgroundColor: 'white',
                bottom: 10,
                left: 0,
                padding: 5,
                position: 'absolute',
                right: 0,
                alignItems: 'center',
              }}
            >
              <Text text={bannerText} size={13} style={{ textAlign: 'center' }} />
            </View>
          ) : null}
        </View>
        <View
          spacing={8}
          style={{
            paddingHorizontal: 20,
            paddingVertical: 16,
            alignItems: 'center',
            height: 100,
          }}
        >
          <Text
            text={title}
            size={17}
            lineHeight={22}
            weight="semibold"
            style={{ textAlign: 'center' }}
            color={locked ? Color.styleGuide.Gray4 : undefined}
          />
          {locked ? (
            <Pill
              icon="lock"
              text={$t({ id: 'ArtifactButton_locked', defaultMessage: 'Locked' })}
              color={Color.styleGuide.Gray4}
            />
          ) : (
            <Pill
              text={
                badgeText ??
                $t(
                  {
                    id: 'ArtifactButton_completedBadge',
                    defaultMessage: '{numCompleted} completed',
                  },
                  { numCompleted },
                )
              }
            />
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
}
