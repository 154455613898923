import { useNavigation } from '@react-navigation/native';
import { useWindowDimensions } from 'react-native';

import { AuthScreenContainer } from '@oui/app-core/src/components/AuthScreenContainer';
import { Button } from '@oui/app-core/src/components/Button';
import { Heading, Label } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import SecureBadge from '@src/assets/secure_badge.svg';
import { RootStackScreenProps } from '@src/types/navigation';

export function Confidentiality(_props: {}) {
  const { $t } = useI18n();
  const navigation = useNavigation<RootStackScreenProps<'Confidentiality'>['navigation']>();
  const { width } = useWindowDimensions();
  const { data: user } = useCurrentUser();

  let homePath: 'home' | 'homeStatic' = 'home';
  if (user?.currentUser?.user?.__typename === 'Patient') {
    if (user?.currentUser.user.productStatic) {
      homePath = 'homeStatic';
    }
  }

  const onSubmit = () => {
    if (homePath === 'homeStatic') {
      navigation.navigate('homeStatic', {
        screen: 'Learn',
      });
    } else {
      navigation.navigate('home', {
        screen: 'Home',
      });
    }
  };

  return (
    <AuthScreenContainer testID="Confidentiality" heading={null}>
      <SecureBadge
        height={width / 4}
        accessibilityLabel={undefined}
        style={{
          alignSelf: 'center',
        }}
      />

      <Heading
        text={$t({ id: 'Confidentiality_title', defaultMessage: 'Confidential' })}
        level={1}
        textAlign="center"
      />

      <View
        spacing={16}
        style={{
          marginTop: 16,
          marginBottom: 28,
          paddingHorizontal: 20,
        }}
      >
        <Label
          textAlign="center"
          text={$t({
            id: 'Confidentiality_messageConfidential',
            defaultMessage: 'Everything shared in this app is confidential.',
          })}
        />

        <Label
          textAlign="center"
          text={$t({
            id: 'Confidentiality_messagePermissions',
            defaultMessage:
              'We will not share anything with others unless you give specific permission.',
          })}
        />
      </View>

      <Button
        alignSelf="center"
        testID="Confidentiality_submitButton"
        onPress={onSubmit}
        text={$t({ id: 'Confidentiality_submitButton', defaultMessage: 'Got it' })}
      />
    </AuthScreenContainer>
  );
}
