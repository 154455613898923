import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useTheme } from '@src/styles';

const DEFAULT_DURATION_MS = 5000;

type SnackbarItem = {
  text: string;
  durationMs?: number;
};

const SnackbarContext = createContext<{ addSnackbarItem: (item: SnackbarItem) => void }>({
  addSnackbarItem: () => {},
});

export function useSnackbarContext() {
  return useContext(SnackbarContext);
}

export function SnackbarProvider({ children }: { children: ReactNode }) {
  const [items, setItems] = useState<SnackbarItem[]>([]);
  const { theme, Shadow } = useTheme();
  const opacity = useSharedValue(0);

  const addSnackbarItem = useCallback((item: SnackbarItem) => {
    setItems((items) => [...items, item]);
  }, []);

  const value = useMemo(() => ({ addSnackbarItem }), [addSnackbarItem]);

  const currentItem = items[0];
  useEffect(() => {
    if (currentItem) {
      opacity.value = 1;
      let timeout: NodeJS.Timeout;
      timeout = setTimeout(() => {
        opacity.value = 0;
        timeout = setTimeout(() => {
          setItems((items) => items.slice(1));
        }, 800);
      }, currentItem.durationMs ?? DEFAULT_DURATION_MS);
      return () => clearTimeout(timeout);
    }

    return;
  }, [opacity, currentItem]);

  const style = useAnimatedStyle(() => {
    return {
      opacity: withTiming(opacity.value, {
        duration: 500,
      }),
    };
  });

  return (
    <>
      <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>
      {currentItem ? (
        <Animated.View
          pointerEvents="none"
          style={[{ position: 'absolute', width: '100%', alignItems: 'center', top: '15%' }, style]}
        >
          <View
            style={[
              {
                backgroundColor: theme.color.gray100,
                // We need to do an opacity on the element, and not on the background color for android
                // because otherwise the elevation shadow shows through the background and makes a weird
                // looking border. To compensate, the displayed text is absoutely positioned outside this
                // element and the inner text here is only for width purposes.
                opacity: 0.7,
                paddingVertical: 5,
                paddingHorizontal: 10,
                borderRadius: 5,
              },
              Shadow.medium,
            ]}
          >
            <Text
              text={currentItem.text}
              color="transparent"
              size={13}
              lineHeight={17}
              accessibilityRole="none"
            />
          </View>
          <Text
            text={currentItem.text}
            color="white"
            size={13}
            lineHeight={17}
            style={{ position: 'absolute', top: 5, elevation: 4 }}
          />
        </Animated.View>
      ) : null}
    </>
  );
}
