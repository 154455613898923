import { useNavigation } from '@react-navigation/native';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { Icon } from '@oui/app-core/src/components/Icon';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { card, useTheme } from '@oui/app-core/src/styles';

import { useSupporteeMyPlan } from '@src/hooks/useSupporteeMyPlan';
import { TabScreenProps } from '@src/types/navigation';

export const SupporteeMyPlanStatus = ({ style }: { style?: StyleProp<ViewStyle> }) => {
  const { Color, theme } = useTheme();
  const { $t } = useI18n();
  const navigation = useNavigation<TabScreenProps<'Home'>['navigation']>();

  const { supportee, loading, updatedSections, shouldShowMyPlanStatus } = useSupporteeMyPlan({
    updatesLastSeenDate: false,
  });

  let firstName = '';

  if (supportee?.patient) {
    firstName = supportee.patient.person.givenName || '';
  }

  let title = $t({
    id: 'SupporteeMyPlanStatus_review_completed',
    defaultMessage: 'Review completed environment safety plan',
  });

  let description = $t(
    {
      id: 'SupporteeMyPlanStatus_plan_about',
      defaultMessage: `It may help to talk to {firstName} about their plan to keep their environment safe`,
    },
    {
      firstName,
    },
  );

  if (updatedSections.length) {
    title = $t({
      id: 'SupporteeMyPlanStatus_review_updated',
      defaultMessage: 'Review updated environment safety plan',
    });

    description = $t(
      {
        id: 'SupporteeMyPlanStatus_plan_update',
        defaultMessage: '{firstName} made changes to their plan to keep their environment safe.',
      },
      {
        firstName,
      },
    );
  }

  if (loading || !shouldShowMyPlanStatus) {
    return null;
  }

  return (
    <View style={style}>
      <Heading
        text={$t(
          {
            id: 'SupporteeMyPlanStatus_header',
            defaultMessage: `{firstName}'s Environment Safety Plan`,
          },
          {
            firstName,
          },
        )}
        level={2}
        style={{ marginBottom: 12 }}
      />
      <View spacing={10} testID={`SupporteeMyPlanStatus`}>
        <TouchableOpacity
          accessibilityLabel={`${title}. ${description}}`}
          accessibilityRole="button"
          testID="SupporteeMyPlanStatus_top"
          onPress={() => {
            navigation.navigate('MyPlan');
          }}
          style={[card, { borderRadius: 20 }]}
        >
          <View
            row
            spacing={15}
            style={{
              padding: 20,
              alignItems: 'flex-start',
            }}
          >
            <View
              style={{
                borderRadius: 10,
                backgroundColor: '#F3E3F4',
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon name="my-plan" color={theme.color.gray500} />
            </View>
            <View flex={1}>
              <View
                row
                flex={1}
                style={{
                  marginTop: 3,
                }}
              >
                <Text
                  testID="SupporteeMyPlanStatus_title"
                  text={title}
                  weight="semibold"
                  style={{ lineHeight: 26, flex: 1 }}
                  size={17}
                />

                <Icon
                  name="arrow-right"
                  color={Color.accent}
                  style={{
                    marginTop: 2,
                  }}
                />
              </View>

              <Text
                testID="SupporteeMyPlanStatus_description"
                text={description}
                style={{
                  flex: 1,
                  marginRight: 44,
                  marginTop: 8,
                  fontSize: 15,
                  lineHeight: 20.4,
                }}
              />
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};
