import { useIsFocused, useScrollToTop } from '@react-navigation/native';
import noop from 'lodash/noop';
import { Component, ReactNode, createContext, useContext, useEffect, useRef } from 'react';
import { Platform, StyleSheet } from 'react-native';
import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from 'react-native-keyboard-aware-scroll-view';
import { useDebounce } from 'use-debounce';

import { View } from '@src/components/View';

// import { Text } from '@src/components/Text';
// import { Color } from '@src/styles';
type ElementLayout = {
  x: number;
  y: number;
  width: number;
  height: number;
};
type ContentOffset = {
  x: number;
  y: number;
};
type ScrollPosition = {
  x: number;
  y: number;
  animated: boolean;
};

export type ScrollIntoViewOptions = {
  getScrollPosition?: (
    parentLayout: ElementLayout,
    childLayout: ElementLayout,
    contentOffset: ContentOffset,
  ) => ScrollPosition;
};

type ContextValueType = {
  scrollToElement: (el: Component | null, options?: ScrollIntoViewOptions) => number;
};
export const ScrollViewContext = createContext<ContextValueType>({
  scrollToElement: noop as any,
});

export function ScrollHere(props: { animated?: boolean; offsetY?: number }) {
  const ref = useRef(null);
  const { scrollToElement } = useContext(ScrollViewContext);

  useEffect(() => {
    if (ref.current) {
      const timeout = scrollToElement(ref.current, {
        getScrollPosition: (parentLayout, childLayout, contentOffset) => {
          return {
            x: 0,
            y: Math.max(
              0,
              childLayout.y - parentLayout.y - (props.offsetY ?? contentOffset.y ?? 0),
            ),
            animated: props.animated ?? true,
          };
        },
      });
      return () => clearTimeout(timeout);
    }
    return;
  }, [scrollToElement, props.animated, props.offsetY]);

  // NB android requires size + backgroundColor to measure the layout properly
  return (
    <View
      ref={ref}
      style={{ width: '100%', height: 1, backgroundColor: 'transparent', marginBottom: -1 }}
    />
  );
}

const useIsFocusedSafe =
  Platform.OS === 'web'
    ? () => {
        return true;
      }
    : useIsFocused;
const useScrollToTopSafe = Platform.OS === 'web' ? () => {} : useScrollToTop;

export function ScrollView({
  topOverflowColor,
  bottomOverflowColor,
  ...props
}: KeyboardAwareScrollViewProps & {
  children?: ReactNode;
  topOverflowColor?: string;
  bottomOverflowColor?: string;
}) {
  const ref = useRef<KeyboardAwareScrollView>(null);
  const timeout = useRef(0);
  // KeyboardAwareScrollView by default listens to global Keyboard events for the logic
  // toggled by enableResetScrollToCoords. We dont want ScrollView to respond to keyboard events
  // unless they occur on this screen (i.e. this screen is currently focused)
  // We also need to debounce so that we dont start listening to events until the screen transition
  // is complete
  const [isFocused] = useDebounce(useIsFocusedSafe(), 100);

  const valueRef = useRef<ContextValueType>({
    scrollToElement: (el, options) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        if (ref.current && el) {
          (ref.current as any).scrollIntoView(el, options);
        }
      }, 20) as any as number;
      return timeout.current;
    },
  });

  useScrollToTopSafe(ref as any);

  return (
    <ScrollViewContext.Provider value={valueRef.current}>
      {topOverflowColor || bottomOverflowColor ? (
        <View style={StyleSheet.absoluteFillObject}>
          <View style={{ flex: 1, backgroundColor: topOverflowColor || 'transparent' }} />
          <View style={{ flex: 1, backgroundColor: bottomOverflowColor || 'transparent' }} />
        </View>
      ) : null}
      <KeyboardAwareScrollView
        ref={ref}
        enableResetScrollToCoords={isFocused}
        // hide scroll indicators in e2e tests for image snapshot stability
        showsHorizontalScrollIndicator={!global.e2e}
        showsVerticalScrollIndicator={!global.e2e}
        scrollIndicatorInsets={props.horizontal !== false ? { right: Number.MIN_VALUE } : undefined}
        // give extra padding / cushion so inputs aren't crammed against the keyboard
        extraHeight={150}
        {...props}
        keyboardShouldPersistTaps="handled"
      />
    </ScrollViewContext.Provider>
  );
}
