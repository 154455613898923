import { useMutation, useQuery } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/core';
import { Route, useRoute } from '@react-navigation/native';
import add from 'date-fns/add';
import addDays from 'date-fns/addDays';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';
import { useCallback, useMemo } from 'react';

import { getGQLDate } from '@oui/lib/src/getGQLDate';
import { parseHoursAndMinutes } from '@oui/lib/src/parseHoursAndMinutes';
import { times } from '@oui/lib/src/times';
import {
  ActionType,
  PracticeType,
  RatingDuration,
  RatingType,
  SleepDiaryEntryDuration,
  SleepDiaryEntryInput,
} from '@oui/lib/src/types/graphql.generated';
import { GQLDate, GQLDateTime, GQLTime, GQLUUID } from '@oui/lib/src/types/scalars';

import {
  AddActionMutationVariables,
  PracticeRatingsQueryVariables,
  ThoughtDiaryEntryQuery,
  useActionCountsQuery,
  useActivityDiaryEntryQuery,
  useAddActionMutation,
  usePracticeRatingsQuery,
  useSleepDiaryEntryQuery,
  useThoughtDiaryEntryQuery,
} from '@src/hooks/practices.graphql.generated';
import { useCurrentPatientID } from '@src/hooks/useCurrentUser';
import { IntlShape, useI18n } from '@src/lib/i18n';
import { logEvent } from '@src/lib/log';
import {
  KvRespondDocument,
  KvRespondMutation,
  KvResponseDocument,
  KvResponseQueryVariables,
} from '@src/lib/userResponse.graphql.generated';
import { namedAppCoreOperations } from '@src/types/namedOperations.generated';

export enum SleepDiaryConfigInfluencerFrequency {
  Regularly = 0,
  Sometimes = 1,
  Rarely = 2,
  Never = 3,
}

export type SleepDiaryConfig = {
  startHHMM?: GQLTime;
  endHHMM?: GQLTime;
  sleepDelay?: SleepDiaryEntryDuration;
  wakeupCount?: number;
  caffeine?: SleepDiaryConfigInfluencerFrequency;
  alcohol?: SleepDiaryConfigInfluencerFrequency;
  tobacco?: SleepDiaryConfigInfluencerFrequency;
  napping?: SleepDiaryConfigInfluencerFrequency;
  exercise?: SleepDiaryConfigInfluencerFrequency;
  medicine?: SleepDiaryConfigInfluencerFrequency;
  deviceInBed?: SleepDiaryConfigInfluencerFrequency;
};

export function getDefaultSleepDiaryEntry(
  sleepConfig: SleepDiaryConfig | undefined,
  baseDate: Date,
): SleepDiaryEntryInput {
  let startDateTime = parseHoursAndMinutes(sleepConfig?.startHHMM ?? '0:00', baseDate);
  const endDateTime = parseHoursAndMinutes(sleepConfig?.endHHMM ?? '8:00', baseDate);

  if (isAfter(startDateTime, endDateTime)) {
    startDateTime = addDays(startDateTime, -1);
  }

  return {
    startTime: startDateTime.toISOString() as GQLDateTime,
    endTime: endDateTime.toISOString() as GQLDateTime,
    sleepDelay: undefined,
    wakeupCount: -1,
    wakeupDuration: undefined,
    caffeine: undefined,
    alcohol: undefined,
    tobacco: undefined,
    medicine: undefined,
    exercise: undefined,
    napping: undefined,
    deviceInBed: undefined,
  };
}

export function useSleepDiaryConfig() {
  const { data: persistedData, ...rest } = useQuery<
    {
      kvResponse: {
        context: string;
        key: string;
        value?: SleepDiaryConfig;
      };
    },
    KvResponseQueryVariables
  >(KvResponseDocument, {
    variables: { context: 'sleepDiary', key: 'config' },
  });

  const isComplete = useMemo(() => {
    if (!persistedData?.kvResponse.value) return null;
    return (
      Object.keys(persistedData.kvResponse.value).length === 11 &&
      !Object.values(persistedData.kvResponse.value).find((v) => typeof v === 'undefined')
    );
  }, [persistedData?.kvResponse]);

  return { data: persistedData?.kvResponse?.value, isComplete, ...rest };
}

type CopingCard = { frontText: string; backText: string };
export function useCopingCards() {
  const {
    data: persistedData,
    refetch,
    ...rest
  } = useQuery<
    {
      kvResponse: {
        context: string;
        key: string;
        value?: CopingCard[];
      };
    },
    KvResponseQueryVariables
  >(KvResponseDocument, { variables: { context: 'session-10', key: 'copingCard' } });

  const [saveCopingCardsMutation] = useMutation<
    KvRespondMutation,
    {
      context: string;
      key: string;
      data: CopingCard[];
    }
  >(KvRespondDocument);

  const saveCopingCards = useCallback(
    async (data: CopingCard[]) => {
      await saveCopingCardsMutation({
        variables: {
          context: 'session-10',
          key: 'copingCard',
          data,
        },
      });
    },
    [saveCopingCardsMutation],
  );

  return { data: persistedData?.kvResponse?.value ?? [], ...rest, saveCopingCards };
}

export const getRatingsLabels = ($t: IntlShape['$t']) => {
  return {
    1: $t({ id: 'PracticeRating_label_1', defaultMessage: 'Awful' }),
    2: $t({ id: 'PracticeRating_label_2', defaultMessage: 'Bad' }),
    3: $t({ id: 'PracticeRating_label_3', defaultMessage: 'Ok' }),
    4: $t({ id: 'PracticeRating_label_4', defaultMessage: 'Good' }),
    5: $t({ id: 'PracticeRating_label_5', defaultMessage: 'Great' }),
  };
};

export function usePracticeRatings({
  practiceType,
  ratingType,
  timeScale,
}: {
  practiceType: PracticeType;
  ratingType: RatingType;
  timeScale: 'WEEK' | 'MONTH' | 'YEAR';
}) {
  const variables: PracticeRatingsQueryVariables = {
    practiceType,
    ratingType,
    ratingInterval: RatingDuration.WEEK,
  };
  let xGQLDates: GQLDate[] = [];
  let xLabels: string[] = [];
  const { $t, formatDate } = useI18n();

  if (timeScale === 'WEEK') {
    const start = startOfWeek(new Date());
    variables.after = getGQLDate(start);
    variables.ratingInterval = RatingDuration.DAY;
    xGQLDates = times(7, (i) => {
      return getGQLDate(add(start, { days: i }));
    });
    xLabels = xGQLDates.map((d) => formatDate(parseISO(d), { weekday: 'short' }));
  } else if (timeScale === 'MONTH') {
    const start = startOfWeek(startOfMonth(new Date()));
    const end = add(endOfWeek(endOfMonth(new Date())), { seconds: 1 });
    const numberOfWeeks = differenceInWeeks(end, start);
    variables.after = getGQLDate(start);
    variables.ratingInterval = RatingDuration.WEEK;

    xGQLDates = times(numberOfWeeks, (i) => {
      return getGQLDate(add(start, { weeks: i }));
    });
    xLabels = xGQLDates.map((d) => formatDate(parseISO(d), { month: 'numeric', day: 'numeric' }));
  } else if (timeScale === 'YEAR') {
    const start = startOfYear(new Date());
    variables.after = getGQLDate(start);
    variables.ratingInterval = RatingDuration.MONTH;

    xGQLDates = times(12, (i) => {
      return getGQLDate(add(start, { months: i }));
    });
    xLabels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  }

  const { loading, data, refetch } = usePracticeRatingsQuery({ variables });
  const practiceRatings = data?.practiceRatings ?? [];

  const inflatedRatingsData = xGQLDates.map((d) => {
    return practiceRatings.find((r) => r.startDate === d)?.practiceRating?.value;
  });

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  return {
    loading,
    xAxisLabel:
      timeScale === 'WEEK'
        ? $t({ id: 'Practices_xAxisWeekLabel', defaultMessage: 'Days' })
        : timeScale === 'MONTH'
        ? $t({ id: 'Practices_xAxisMonthLabel', defaultMessage: 'Week beginning' })
        : $t({ id: 'Practices_xAxisYearLabel', defaultMessage: 'Months' }),
    xLabels,
    data: { type: ratingType, data: inflatedRatingsData },
    refetch,
  };
}

export function useActionCounts() {
  const { data: counts, ...rest } = useActionCountsQuery();

  const countByActionType = useMemo(() => {
    let total = 0;
    const collection = (counts?.actionCounts ?? []).reduce<
      Partial<Record<ActionType | 'TOTAL', number>>
    >((carry, { actionType, count }) => {
      carry[actionType] = count;
      total += count;
      return carry;
    }, {});
    collection.TOTAL = total;
    return collection;
  }, [counts]);

  return { data: countByActionType, ...rest };
}

export function useAddAction() {
  const patientID = useCurrentPatientID()!;
  const [mutate, data] = useAddActionMutation();

  const mutation = useCallback(
    (variables: Omit<AddActionMutationVariables['input'], 'actionValues'>) => {
      logEvent('add_action', {
        actionType: variables.actionType,
        practiceID: variables.practiceID ?? null,
      });
      return mutate({
        variables: {
          input: {
            actionValues: {
              patientID,
            },
            ...variables,
          },
        },
        refetchQueries: [namedAppCoreOperations.Query.ActionCounts],
      });
    },
    [patientID, mutate],
  );

  return [mutation, data] as [typeof mutation, typeof data];
}

export function useActivityPractice() {
  const route = useRoute<Route<string, { practiceID: GQLUUID }>>();
  const practiceID = 'practiceID' in route.params ? route.params.practiceID : null;
  const { data, ...rest } = useActivityDiaryEntryQuery({
    variables: {
      practiceID: practiceID!,
    },
    skip: !practiceID,
  });
  return {
    data,
    ...rest,
    activityPractice: data?.activityPracticeByID,
    practiceID,
  };
}

export function shouldTestThought({
  completedSession07,
  thoughtDiaryEntry,
}: {
  completedSession07: boolean;
  thoughtDiaryEntry?: NonNullable<
    ThoughtDiaryEntryQuery['thoughtDiaryEntryPracticeByID']
  >['thoughtDiaryEntry'];
}) {
  const locked = !completedSession07;
  return (
    !locked &&
    thoughtDiaryEntry?.evidenceFor.length === 0 &&
    thoughtDiaryEntry?.evidenceAgainst.length === 0
  );
}

export function shouldSwitchThought({
  completedSession08,
  thoughtDiaryEntry,
}: {
  completedSession08: boolean;
  thoughtDiaryEntry?: NonNullable<
    ThoughtDiaryEntryQuery['thoughtDiaryEntryPracticeByID']
  >['thoughtDiaryEntry'];
}) {
  const locked = !completedSession08;
  return !locked && !thoughtDiaryEntry?.thoughtAfter;
}

export function useThoughtDiaryEntryPractice() {
  const route = useRoute<Route<string, { practiceID: GQLUUID }>>();
  const practiceID = route.params?.practiceID!;
  const { data, ...rest } = useThoughtDiaryEntryQuery({
    variables: {
      practiceID,
    },
    skip: !practiceID,
  });

  const practiceValues = data?.thoughtDiaryEntryPracticeByID?.practiceValues;
  const thoughtDiaryEntry = data?.thoughtDiaryEntryPracticeByID?.thoughtDiaryEntry;
  return { data, ...rest, practiceID, practiceValues, thoughtDiaryEntry };
}

export function useSleepDiaryEntryPractice() {
  const route = useRoute<Route<string, { practiceID: GQLUUID }>>();
  const practiceID = route.params?.practiceID!;
  const { data, ...rest } = useSleepDiaryEntryQuery({
    variables: {
      practiceID,
    },
    skip: !practiceID,
  });

  const practiceValues = data?.sleepDiaryEntryPracticeByID?.practiceValues;
  const sleepDiaryEntry = data?.sleepDiaryEntryPracticeByID?.sleepDiaryEntry;
  return { data, ...rest, practiceID, practiceValues, sleepDiaryEntry };
}
