import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';

import { getDataHashFromMyPlanComposition } from '@oui/app-core/src/hooks/useComposition';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { ContentType } from '@oui/lib/src/types';
import { GQLDateTime } from '@oui/lib/src/types/scalars';

import { useSupporteeMyPlanQuery } from '@src/hooks/useSupporteeMyPlan.graphql.generated';

export const useSupporteeMyPlan = ({
  updatesLastSeenDate = true,
}: {
  updatesLastSeenDate?: boolean;
}) => {
  const { loading, data, refetch } = useSupporteeMyPlanQuery();
  const [lastSeenAt, setLastSeenAt] = usePersistedState(
    'supporteeMyPlanLastSeenAt',
    () => new Date(0).toISOString() as GQLDateTime,
  );

  const supportee =
    data?.ouiUser?.user?.__typename === 'Patient' ? data.ouiUser.user.profile.supportees[0] : null;
  const composition = supportee?.patient.profile.myPlanComposition ?? undefined;

  useFocusEffect(
    useCallback(() => {
      refetch();
      return () => {
        if (updatesLastSeenDate) {
          setLastSeenAt(new Date().toISOString() as GQLDateTime);
        }
      };
    }, [setLastSeenAt, refetch, updatesLastSeenDate]),
  );

  const updatedSections = useMemo(() => {
    // don't show updated sections if this is the first time user is looking at this screen
    if (lastSeenAt === (new Date(0).toISOString() as GQLDateTime)) return [];

    return (
      composition?.sections
        .filter((s) => s.updatedAt > lastSeenAt)
        .map((s) => s.title as keyof ReturnType<typeof getDataHashFromMyPlanComposition>) ?? []
    );
  }, [composition, lastSeenAt]);

  const hasCompletedMyPlanDate = useMemo(() => {
    if (lastSeenAt && supportee) {
      const hasCompletedMyPlanDate = supportee.patient.profile.progress.filter((i) => {
        return i.content === ContentType.MYPLAN && i.completed === true;
      })[0]?.updatedAt;

      return hasCompletedMyPlanDate;
    }
    return null;
  }, [lastSeenAt, supportee]);

  const shouldShowMyPlanStatus = useMemo(() => {
    // If newly completed
    if (hasCompletedMyPlanDate && hasCompletedMyPlanDate > lastSeenAt) return true;
    // If ENVIRONMENT_SAFETY updated since last seen
    if (updatedSections.includes('ENVIRONMENT_SAFETY')) return true;

    return false;
  }, [lastSeenAt, updatedSections, hasCompletedMyPlanDate]);

  return {
    loading,
    updatedSections,
    supportee,
    composition,
    lastSeenAt,
    refetch,
    shouldShowMyPlanStatus,
    hasCompletedMyPlanDate,
  };
};
