'use client';

import { ReactElement, ReactNode, useState } from 'react';
import { Platform, Pressable, StyleProp, ViewStyle } from 'react-native';

import { Button } from '@src/components/Button';
import { Icon } from '@src/components/Icon';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useTheme } from '@src/styles';

type AccordionProps = {
  backgroundColor?: string;
  accentColor?: string;
  iconSize?: number;
  padding?: number;
  heading: ReactElement;
  content: ReactNode;
  testID?: string;
  style?: StyleProp<ViewStyle>;
  expandedHeadingStyle?: StyleProp<ViewStyle>;
};

export function ControlledAccordion({
  expanded,
  onSetExpanded: setExpanded,
  ...props
}: AccordionProps & { expanded: boolean; onSetExpanded: (newValue: boolean) => void }) {
  const { Color } = useTheme();
  return (
    <Pressable onPress={() => setExpanded(!expanded)} testID={props.testID} style={props.style}>
      <View
        style={{
          padding: props.padding ?? 12,
          borderRadius: 10,
          backgroundColor: props.backgroundColor ?? Color.grayBackground,
          alignSelf: 'stretch',
        }}
        spacing={15}
        // @ts-expect-error
        dataSet={{ noselect: '' }}
      >
        <View
          row
          style={[
            { justifyContent: 'space-between' },
            Platform.OS === 'web' ? ({ cursor: 'pointer' } as any) : null,
            expanded ? props.expandedHeadingStyle : null,
          ]}
        >
          {props.heading}
          <Icon
            name={expanded ? 'caret-up' : 'caret-down'}
            color={props.accentColor ?? Color.accent}
            size={props.iconSize ?? undefined}
          />
        </View>
        {expanded ? <View style={{}}>{props.content}</View> : null}
      </View>
    </Pressable>
  );
}

export function Accordion(props: AccordionProps) {
  const [expanded, setExpanded] = useState(false);
  return <ControlledAccordion expanded={expanded} onSetExpanded={setExpanded} {...props} />;
}

export function ButtonAccordion(props: {
  children?: ReactNode;
  color?: string;
  defaultExpanded?: boolean;
  expandedStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  text?: string;
}) {
  const [expanded, setExpanded] = useState(props.defaultExpanded ?? false);
  return (
    <View>
      <Button
        _accentColor={props.color}
        testID={props.testID}
        variant="text"
        size="small"
        accessibilityLabel={props.text}
        accessibilityState={{ expanded }}
        accessibilityActions={[{ name: 'activate', label: expanded ? 'collapse' : 'expand' }]}
        onAccessibilityAction={() => {
          setExpanded((e) => !e);
        }}
        onPress={() => {
          setExpanded((e) => !e);
        }}
        text={props.text}
        iconRight={expanded ? 'caret-up' : 'caret-down'}
        style={[props.style, expanded ? props.expandedStyle : null]}
      />
      {expanded ? props.children : null}
    </View>
  );
}

export function TextAccordion({ children, text }: { children?: ReactNode; text: string }) {
  const { Color } = useTheme();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Pressable
      accessibilityLabel={text}
      accessibilityState={{ expanded }}
      accessibilityActions={[{ name: 'activate', label: expanded ? 'collapse' : 'expand' }]}
      onAccessibilityAction={toggleExpanded}
      onPress={toggleExpanded}
    >
      <View row style={{ overflow: 'hidden' }}>
        <View spacing={15} style={{ marginRight: 2, flex: 6 }}>
          <Text numberOfLines={expanded ? undefined : 2} text={`"${text}"`} />
          {expanded ? children : null}
        </View>
        <Icon
          accessibilityLabel="expand text"
          color={Color.styleGuide.BrandCarminePink}
          name={expanded ? 'caret-up' : 'caret-down'}
          size={12}
          style={{ flex: 1 }}
        />
      </View>
    </Pressable>
  );
}
