// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetPushTokenMutationVariables = Types.Exact<{
  deviceID: Types.Scalars['UUID']['input'];
  platform: Types.Scalars['String']['input'];
  platformPushToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
  expoPushToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SetPushTokenMutation = { readonly setDevice: boolean };


export const SetPushTokenDocument = gql`
    mutation SetPushToken($deviceID: UUID!, $platform: String!, $platformPushToken: String, $expoPushToken: String) {
  setDevice(
    device: {installationID: $deviceID, platform: $platform, pushToken: $platformPushToken, expoToken: $expoPushToken}
  )
}
    `;

/**
 * __useSetPushTokenMutation__
 *
 * To run a mutation, you first call `useSetPushTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPushTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPushTokenMutation, { data, loading, error }] = useSetPushTokenMutation({
 *   variables: {
 *      deviceID: // value for 'deviceID'
 *      platform: // value for 'platform'
 *      platformPushToken: // value for 'platformPushToken'
 *      expoPushToken: // value for 'expoPushToken'
 *   },
 * });
 */
export function useSetPushTokenMutation(baseOptions?: Apollo.MutationHookOptions<SetPushTokenMutation, SetPushTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPushTokenMutation, SetPushTokenMutationVariables>(SetPushTokenDocument, options);
      }
export type SetPushTokenMutationHookResult = ReturnType<typeof useSetPushTokenMutation>;

export function performSetPushToken(client: Apollo.ApolloClient<unknown>, variables: SetPushTokenMutationVariables, options?: Omit<Apollo.MutationOptions<SetPushTokenMutation, SetPushTokenMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<SetPushTokenMutation, SetPushTokenMutationVariables>({
    mutation: SetPushTokenDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const SetPushTokenMutationSchema = z.lazy(() => z.object({
deviceID: Types.UUIDSchema,
platform: z.string(),
platformPushToken: z.string().nullish(),
expoPushToken: z.string().nullish()
}))