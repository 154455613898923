// Adapted from https://github.com/CharlesStover/use-dimensions/pull/4

import debounce from 'lodash/debounce';
import { createContext, useContext, useEffect, useState } from 'react';
import { Dimensions as RNDimensions } from 'react-native';

interface BothDimensions {
  screen: Dimensions;
  window: Dimensions;
}

interface Dimensions {
  height: number;
  width: number;
}

type Setter = (dimensions: Dimensions) => void;

type Type = 'screen' | 'window';

type Unsubscribe = () => void;

export const FakeDimensionsContext = createContext<{ height: number; width: number } | null>(null);

// adapted from https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/useWindowDimensions/index.js
const _useDimensions = (type: Type, debounceMs?: number): Dimensions => {
  const fakeDimensions = useContext(FakeDimensionsContext);

  const [dimensions, setDimensions]: [Dimensions, Setter] = useState(
    (): Dimensions => RNDimensions.get(type),
  );
  useEffect((): Unsubscribe => {
    const debouncedSetDimensions = debounceMs ? debounce(setDimensions, debounceMs) : setDimensions;

    // Event listener for when the dimensions change.
    const handleDimensionsChange = ({ window, screen }: BothDimensions): void => {
      const newDimensions: Dimensions = type === 'screen' ? screen : window;
      debouncedSetDimensions({ ...newDimensions });
    };

    const listener = RNDimensions.addEventListener('change', handleDimensionsChange);

    // We might have missed an update between calling `get` in render and
    // `addEventListener` in this handler, so we set it here. If there was
    // no change, React will filter out this update as a no-op.
    debouncedSetDimensions(RNDimensions.get(type));
    // When the component unmounts, remove the event listener.
    return (): void => {
      listener.remove();
    };
  }, [dimensions, type, debounceMs]);

  return fakeDimensions ?? dimensions;
};

export function useScreenDimensions(): Dimensions {
  return _useDimensions('screen', 20);
}

export function useWindowDimensions(): Dimensions {
  return _useDimensions('window', 20);
}

export default function useDimensions(): BothDimensions {
  const screen = useScreenDimensions();
  const window = useWindowDimensions();
  return { screen, window };
}
