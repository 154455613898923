// Adapted from https://github.com/jeanregisser/react-native-slider
// Should replace with react-native-community/slider at some point but it doesn't support thumbStyle
import { PureComponent } from 'react';
import {
  AccessibilityProps,
  Animated,
  Easing,
  GestureResponderEvent,
  I18nManager,
  LayoutChangeEvent,
  PanResponder,
  PanResponderCallbacks,
  PanResponderGestureState,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

interface SliderProps {
  /**
   * Initial value of the slider. The value should be between minimumValue
   * and maximumValue, which default to 0 and 1 respectively.
   * Default value is 0.
   *
   * *This is not a controlled component*, e.g. if you don't update
   * the value, the component won't be reset to its inital value.
   */
  value: number;

  /**
   * If true the user won't be able to move the slider.
   * Default value is false.
   */
  disabled?: boolean;

  /**
   * Initial minimum value of the slider. Default value is 0.
   */
  minimumValue: number;

  /**
   * Initial maximum value of the slider. Default value is 1.
   */
  maximumValue: number;

  /**
   * Step value of the slider. The value should be between 0 and
   * (maximumValue - minimumValue). Default value is 0.
   */
  step?: number;

  /**
   * The color used for the track to the left of the button. Overrides the
   * default blue gradient image.
   */
  minimumTrackTintColor?: string;

  /**
   * The color used for the track to the right of the button. Overrides the
   * default blue gradient image.
   */
  maximumTrackTintColor?: string;

  /**
   * The color used for the thumb.
   */
  thumbTintColor?: string;

  /**
   * Callback continuously called while the user is dragging the slider.
   */
  onValueChange: (value: number) => void;

  /**
   * Callback called when the user starts changing the value (e.g. when
   * the slider is pressed).
   */
  onSlidingStart?: (value: number) => void;

  /**
   * Callback called when the user finishes changing the value (e.g. when
   * the slider is released).
   */
  onSlidingComplete?: (value: number) => void;

  /**
   * The style applied to the slider container.
   */
  style?: StyleProp<ViewStyle>;

  /**
   * The style applied to the track.
   */
  trackStyle?: StyleProp<ViewStyle>;

  /**
   * The style applied to the thumb.
   */
  thumbStyle?: StyleProp<ViewStyle>;

  /**
   * Set this to true to visually see the thumb touch rect in green.
   */
  debugTouchArea?: boolean;

  /**
   * Set to true to animate values with default 'timing' animation type
   */
  animateTransitions?: boolean;

  /**
   * Custom Animation type. 'spring' or 'timing'.
   */
  animationType?: 'spring' | 'timing';

  allowSliding?: boolean;
}

const TRACK_SIZE = 4;
const THUMB_SIZE = 20;

class Rect {
  x: number;
  y: number;
  width: number;
  height: number;
  constructor(x: number, y: number, width: number, height: number) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  containsPoint(x: number, y: number) {
    return x >= this.x && y >= this.y && x <= this.x + this.width && y <= this.y + this.height;
  }
}

const DEFAULT_ANIMATION_CONFIGS: Record<NonNullable<SliderProps['animationType']>, any> = {
  spring: {
    friction: 7,
    tension: 100,
  },
  timing: {
    duration: 150,
    easing: Easing.inOut(Easing.ease),
    delay: 0,
  },
  // decay : { // This has a serious bug
  //   velocity     : 1,
  //   deceleration : 0.997
  // }
};

type WH = { width: number; height: number };
type SliderState = {
  containerSize: WH;
  trackSize: WH;
  thumbSize: WH;
  allMeasured: boolean;
  value: Animated.Value;
};

const THUMB_TOUCH_SIZE = { width: 40, height: 40 };

export class RNSlider extends PureComponent<SliderProps & AccessibilityProps, SliderState> {
  static defaultProps = {
    value: 0,
    minimumValue: 0,
    maximumValue: 1,
    step: 0,
    minimumTrackTintColor: '#3f3f3f',
    maximumTrackTintColor: '#b3b3b3',
    thumbTintColor: '#343434',
    debugTouchArea: false,
    animationType: 'timing',
    allowSliding: true,
  };

  _previousLeft: number = 0;
  _containerSize?: WH;
  _thumbSize?: WH;
  _trackSize?: WH;

  state: SliderState = {
    containerSize: { width: 0, height: 0 },
    trackSize: { width: 0, height: 0 },
    thumbSize: { width: 0, height: 0 },
    allMeasured: false,
    value: new Animated.Value(this.props.value),
  };

  componentDidUpdate(prevProps: SliderProps) {
    const nextProps = this.props;
    const newValue = nextProps.value;

    if (prevProps.value !== newValue) {
      if (this.props.animateTransitions) {
        this._setCurrentValueAnimated(newValue);
      } else {
        this._setCurrentValue(newValue);
      }
    }
  }

  render() {
    const {
      minimumValue,
      maximumValue,
      minimumTrackTintColor,
      maximumTrackTintColor,
      thumbTintColor,
      style,
      trackStyle,
      thumbStyle,
      debugTouchArea,
      onValueChange,
      animationType,
      animateTransitions,
      ...other
    } = this.props;
    const { value, containerSize, thumbSize, allMeasured } = this.state;
    const mainStyles = defaultStyles;
    const thumbLeft = value.interpolate({
      inputRange: [minimumValue, maximumValue],
      outputRange: I18nManager.isRTL
        ? [0, -(containerSize.width - thumbSize.width)]
        : [0, containerSize.width - thumbSize.width],
      // extrapolate: 'clamp',
    });
    const minimumTrackWidth = value.interpolate({
      inputRange: [minimumValue, maximumValue],
      outputRange: [0, containerSize.width - thumbSize.width],
      // extrapolate: 'clamp',
    });
    const valueVisibleStyle: ViewStyle = {};
    if (!allMeasured) {
      valueVisibleStyle.opacity = 0;
    }

    const minimumTrackStyle = {
      position: 'absolute' as const,
      width: Animated.add(minimumTrackWidth, thumbSize.width / 2),
      backgroundColor: minimumTrackTintColor,
      ...valueVisibleStyle,
    };

    const touchOverflowStyle = this._getTouchOverflowStyle();

    return (
      <View {...other} style={[mainStyles.container, style]} onLayout={this._measureContainer}>
        <View
          style={[{ backgroundColor: maximumTrackTintColor }, mainStyles.track, trackStyle]}
          renderToHardwareTextureAndroid
          onLayout={this._measureTrack}
        />
        <Animated.View
          renderToHardwareTextureAndroid
          style={[mainStyles.track, trackStyle, minimumTrackStyle]}
        />
        <Animated.View
          onLayout={this._measureThumb}
          renderToHardwareTextureAndroid
          style={[
            { backgroundColor: thumbTintColor },
            mainStyles.thumb,
            thumbStyle,
            {
              transform: [{ translateX: thumbLeft }, { translateY: 0 }],
              ...valueVisibleStyle,
            },
          ]}
        />
        <View
          renderToHardwareTextureAndroid
          style={[defaultStyles.touchArea, touchOverflowStyle]}
          {...(this.props.allowSliding ? this._panResponder.panHandlers : undefined)}
        >
          {debugTouchArea === true && this._renderDebugThumbTouchRect(minimumTrackWidth)}
        </View>
      </View>
    );
  }

  _getPropsForComponentUpdate(props: SliderProps) {
    const {
      value,
      onValueChange,
      onSlidingStart,
      onSlidingComplete,
      style,
      trackStyle,
      thumbStyle,
      ...otherProps
    } = props;

    return otherProps;
  }

  _handleStartShouldSetPanResponder: PanResponderCallbacks['onStartShouldSetPanResponder'] = (
    e,
  ) => {
    // Should we become active when the user presses down on the thumb?
    return this._thumbHitTest(e);
  };

  _handleMoveShouldSetPanResponder: PanResponderCallbacks['onMoveShouldSetPanResponder'] = () => {
    // Should we become active when the user moves a touch over the thumb?
    return false;
  };

  _handlePanResponderGrant: PanResponderCallbacks['onPanResponderGrant'] = () => {
    this._previousLeft = this._getThumbLeft(this._getCurrentValue());
    this.props.onSlidingStart?.(this._getCurrentValue());
  };

  _handlePanResponderMove: PanResponderCallbacks['onPanResponderMove'] = (_, gestureState) => {
    if (this.props.disabled) {
      return;
    }

    this._setCurrentValue(this._getValue(gestureState));
    this.props.onValueChange?.(this._getCurrentValue());
  };

  _handlePanResponderRequestEnd: PanResponderCallbacks['onPanResponderTerminationRequest'] = () => {
    // Should we allow another component to take over this pan?
    return false;
  };

  _handlePanResponderEnd: PanResponderCallbacks['onPanResponderEnd'] = (_, gestureState) => {
    if (this.props.disabled) {
      return;
    }

    this._setCurrentValue(this._getValue(gestureState));
    this.props.onSlidingComplete?.(this._getCurrentValue());
  };

  _measureContainer = (x: LayoutChangeEvent) => {
    this._handleMeasure('_containerSize', x);
  };

  _measureTrack = (x: LayoutChangeEvent) => {
    this._handleMeasure('_trackSize', x);
  };

  _measureThumb = (x: LayoutChangeEvent) => {
    this._handleMeasure('_thumbSize', x);
  };

  _handleMeasure = (name: '_containerSize' | '_trackSize' | '_thumbSize', x: LayoutChangeEvent) => {
    const { width, height } = x.nativeEvent.layout;
    const size = { width, height };

    const currentSize = this[name];
    if (currentSize && width === currentSize.width && height === currentSize.height) {
      return;
    }
    this[name] = size;

    if (this._containerSize && this._trackSize && this._thumbSize) {
      this.setState({
        containerSize: this._containerSize,
        trackSize: this._trackSize,
        thumbSize: this._thumbSize,
        allMeasured: true,
      });
    }
  };

  _getRatio = (value: number) =>
    (value - this.props.minimumValue) / (this.props.maximumValue - this.props.minimumValue);

  _getThumbLeft = (value: number) => {
    const nonRtlRatio = this._getRatio(value);
    const ratio = I18nManager.isRTL ? 1 - nonRtlRatio : nonRtlRatio;
    return ratio * (this.state.containerSize.width - this.state.thumbSize.width);
  };

  _getValue = (gestureState: PanResponderGestureState) => {
    const length = this.state.containerSize.width - this.state.thumbSize.width;
    const thumbLeft = this._previousLeft + gestureState.dx;

    const nonRtlRatio = thumbLeft / length;
    const ratio = I18nManager.isRTL ? 1 - nonRtlRatio : nonRtlRatio;

    if (this.props.step) {
      return Math.max(
        this.props.minimumValue,
        Math.min(
          this.props.maximumValue,
          this.props.minimumValue +
            Math.round(
              (ratio * (this.props.maximumValue - this.props.minimumValue)) / this.props.step,
            ) *
              this.props.step,
        ),
      );
    }
    return Math.max(
      this.props.minimumValue,
      Math.min(
        this.props.maximumValue,
        ratio * (this.props.maximumValue - this.props.minimumValue) + this.props.minimumValue,
      ),
    );
  };

  // @ts-expect-error
  _getCurrentValue = () => this.state.value.__getValue();

  _setCurrentValue = (value: number) => {
    this.state.value.setValue(value);
  };

  _setCurrentValueAnimated = (value: number) => {
    const animationType = this.props.animationType;
    const animationConfig = Object.assign({}, DEFAULT_ANIMATION_CONFIGS[animationType!], {
      toValue: value,
      useNativeDriver: false,
    });

    if (!(Platform.OS === 'android' && global.e2e)) {
      Animated[animationType!](this.state.value, animationConfig).start();
    }
  };

  _getTouchOverflowSize = () => {
    const state = this.state;

    const size: WH = { width: 0, height: 0 };
    if (state.allMeasured === true) {
      size.width = Math.max(0, THUMB_TOUCH_SIZE.width - state.thumbSize.width);
      size.height = Math.max(0, THUMB_TOUCH_SIZE.height - state.containerSize.height);
    }

    return size;
  };

  _getTouchOverflowStyle = () => {
    const { width, height } = this._getTouchOverflowSize();

    const touchOverflowStyle: ViewStyle = {};
    if (width !== undefined && height !== undefined) {
      const verticalMargin = -height / 2;
      touchOverflowStyle.marginTop = verticalMargin;
      touchOverflowStyle.marginBottom = verticalMargin;

      const horizontalMargin = -width / 2;
      touchOverflowStyle.marginLeft = horizontalMargin;
      touchOverflowStyle.marginRight = horizontalMargin;
    }

    if (this.props.debugTouchArea === true) {
      touchOverflowStyle.backgroundColor = 'orange';
      touchOverflowStyle.opacity = 0.5;
    }

    return touchOverflowStyle;
  };

  _thumbHitTest = (e: GestureResponderEvent) => {
    const nativeEvent = e.nativeEvent;
    const thumbTouchRect = this._getThumbTouchRect();
    return thumbTouchRect.containsPoint(nativeEvent.locationX, nativeEvent.locationY);
  };

  _getThumbTouchRect = () => {
    const state = this.state;
    const touchOverflowSize = this._getTouchOverflowSize();

    return new Rect(
      touchOverflowSize.width / 2 +
        this._getThumbLeft(this._getCurrentValue()) +
        (state.thumbSize.width - THUMB_TOUCH_SIZE.width) / 2,
      touchOverflowSize.height / 2 + (state.containerSize.height - THUMB_TOUCH_SIZE.height) / 2,
      THUMB_TOUCH_SIZE.width,
      THUMB_TOUCH_SIZE.height,
    );
  };

  _renderDebugThumbTouchRect = (thumbLeft: Animated.AnimatedInterpolation<number>) => {
    const thumbTouchRect = this._getThumbTouchRect();
    const positionStyle = {
      left: thumbLeft,
      top: thumbTouchRect.y,
      width: thumbTouchRect.width,
      height: thumbTouchRect.height,
    };

    return (
      <Animated.View
        style={[defaultStyles.debugThumbTouchArea, positionStyle]}
        pointerEvents="none"
      />
    );
  };

  _panResponder = PanResponder.create({
    onStartShouldSetPanResponder: this._handleStartShouldSetPanResponder,
    onMoveShouldSetPanResponder: this._handleMoveShouldSetPanResponder,
    onPanResponderGrant: this._handlePanResponderGrant,
    onPanResponderMove: this._handlePanResponderMove,
    onPanResponderRelease: this._handlePanResponderEnd,
    onPanResponderTerminationRequest: this._handlePanResponderRequestEnd,
    onPanResponderTerminate: this._handlePanResponderEnd,
  });
}

const defaultStyles = StyleSheet.create({
  container: {
    height: 40,
    justifyContent: 'center',
  },
  track: {
    height: TRACK_SIZE,
    borderRadius: TRACK_SIZE / 2,
  },
  thumb: {
    position: 'absolute',
    width: THUMB_SIZE,
    height: THUMB_SIZE,
    borderRadius: THUMB_SIZE / 2,
  },
  touchArea: {
    position: 'absolute',
    backgroundColor: 'transparent',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  debugThumbTouchArea: {
    position: 'absolute',
    backgroundColor: 'green',
    opacity: 0.5,
  },
});
