import { useNavigation, useRoute } from '@react-navigation/native';
import type { Contact } from 'expo-contacts';
import { useEffect } from 'react';

type Payload = { contacts: Contact[]; permissionDenied?: boolean };
export function useContactsPickerResult(callback: (payload: Payload) => void) {
  const { setParams } = useNavigation();
  // eslint-disable-next-line
  const { name, params } = useRoute<any>();

  useEffect(() => {
    if (params?._contactPickerResult) {
      const contactPickerResult: Payload = params._contactPickerResult;
      // @ts-expect-error
      setParams({ _contactPickerResult: undefined });
      callback(contactPickerResult);
    }
  }, [setParams, params?._contactPickerResult, callback]);

  return name;
}
