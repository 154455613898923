import { ReactNode } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { Icon } from '@src/components/Icon';
import { SvgUri } from '@src/components/SvgUri';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { Shadow, card, useTheme } from '@src/styles';

function getV2SessionSvg(num: number, completed: boolean) {
  return (
    <SvgUri
      uri={`https://storage.googleapis.com/asset.oui.dev/static/Learn_Session_${
        completed ? 'complete_' : ''
      }${num}.svg`}
    />
  );
}

function getV2StaticSessionSvg(num: number, completed: boolean) {
  return (
    <SvgUri
      uri={`https://storage.googleapis.com/asset.oui.dev/static/LearnStatic_Session_${
        completed ? 'complete_' : ''
      }${num}.svg`}
    />
  );
}

const COMPLETE_COLOR = '#035759';

export type SessionState =
  | 'locked'
  | 'active'
  | 'session-complete'
  | 'practice-complete'
  | 'unlocked';
export type Session = {
  num: number;
  title: string;
  subtitle?: string;
  details: string[];
  practiceTitle?: string;
};

type Props = {
  hideHeader?: boolean; // for AVIVA_STATIC
  static?: boolean;
  collapsed?: boolean;
  session: Session;
  state: SessionState;
  onPressTop: () => void;
  onPressBottom: () => void;
  testID?: string;
};

function SessionCardTitle({
  header,
  isPractice,
  state,
  title,
  subtitle,
}: {
  header: string;
  isPractice?: boolean;
  state: 'locked' | 'active' | 'complete' | 'review';
  title: string;
  subtitle?: string;
}) {
  const { Color } = useTheme();
  const isComplete = state === 'complete';
  return (
    <View row flex={1}>
      <View row flex={1} style={{ alignItems: 'flex-start' }} spacing={14}>
        {header ? (
          <Text
            // if header is '0' we don't actually want to show 0 as a number since a "0" session
            // isn't actually session 0, it's just the session before 1
            text={header === '0' ? ' ' : header}
            weight="semibold"
            size={17}
            color={isComplete ? COMPLETE_COLOR : undefined}
          />
        ) : null}
        {title ? (
          isPractice ? (
            <Text
              text={title}
              weight="semibold"
              style={{ lineHeight: 26 }}
              size={17}
              color={isComplete ? COMPLETE_COLOR : undefined}
            />
          ) : (
            <View flex={1}>
              <View row spacing={9}>
                <Text
                  text={title}
                  weight="semibold"
                  size={17}
                  color={isComplete ? COMPLETE_COLOR : undefined}
                />
                {state === 'active' ? (
                  <View pointerEvents="none" style={{ marginTop: 3 }}>
                    <Icon name="arrow-right" color={Color.accent} />
                  </View>
                ) : null}
              </View>
              {subtitle ? (
                <Text
                  text={subtitle}
                  color={isComplete ? COMPLETE_COLOR : Color.styleGuide.Gray3}
                  style={{ maxWidth: '85%' }}
                />
              ) : null}
            </View>
          )
        ) : null}
        {state === 'locked' ? (
          <View style={{ alignItems: 'flex-end' }}>
            <Icon name="lock" color="#9191A3" size={20} />
          </View>
        ) : null}
      </View>
    </View>
  );
}

export function SessionIncompleteLockCard(props: { num: number; testID: string }) {
  const { scheme } = useTheme();
  return (
    <View
      testID={props.testID}
      accessible
      accessibilityLabel={`Complete session ${props.num} to unlock.`}
      accessibilityRole="button"
      accessibilityState={{
        disabled: true,
      }}
      style={[
        styles.component,
        styles.locked,
        { backgroundColor: scheme === 'dark' ? '#121212' : '#EFF0F3' },
      ]}
    >
      <SessionCardTitle
        title={''}
        subtitle={undefined}
        state="locked"
        header={`Complete session ${props.num}`}
      />
    </View>
  );
}

export function SessionCard(props: Props) {
  const { scheme, Color } = useTheme();
  const header = props.hideHeader ? '' : props.session.num.toString();
  const state = props.state;

  if (state === 'locked') {
    return (
      <View
        accessible
        accessibilityLabel={`Session ${header}. ${props.session.title ?? ''}. ${
          props.session.subtitle ?? ''
        }. locked`}
        accessibilityRole="button"
        accessibilityState={{
          disabled: true,
        }}
        style={[
          styles.component,
          styles.locked,
          { backgroundColor: scheme === 'dark' ? '#121212' : '#EFF0F3' },
        ]}
        testID={`${props.testID}_locked`}
      >
        <SessionCardTitle
          title={props.session.title}
          subtitle={props.session.subtitle}
          state="locked"
          header={header}
        />
      </View>
    );
  }

  const isTopActive = state === 'active';
  const isTopComplete = state === 'session-complete' || state === 'practice-complete';
  const isBottomActive = state === 'session-complete';
  const isBottomComplete = state === 'practice-complete';

  const hasPractice = !!props.session.practiceTitle;

  const expandedLabel = props.collapsed ? undefined : `Agenda: ${props.session.details.join('.')}.`;
  const accessibilityLabel = `Session ${props.session.num}. ${props.session.title}. ${
    props.session.subtitle
  }. ${expandedLabel} ${isTopComplete ? 'completed' : ''}`;

  return (
    <View
      testID={props.testID}
      style={!isTopComplete ? [card, { borderRadius: 20, overflow: 'visible' }] : null}
    >
      <TouchableOpacity
        accessibilityLabel={accessibilityLabel}
        accessibilityRole="button"
        key={state} // Shouldn't be necessary but border doesn't update properly when isTopActive changes
        testID="SessionCard_top"
        onPress={props.onPressTop}
        style={[
          styles.component,
          hasPractice ? { borderBottomEndRadius: 0, borderBottomStartRadius: 0 } : null,
          isTopActive
            ? [
                {
                  borderColor: 'transparent',
                  backgroundColor: 'white',
                },
              ]
            : { borderBottomWidth: 0 },
          isTopComplete ? { borderColor: '#F4F8F5', backgroundColor: '#F4F8F5' } : null,
        ]}
      >
        <View style={{ position: 'absolute', right: 0, bottom: 0 }}>
          {props.static
            ? getV2StaticSessionSvg(props.session.num, isTopComplete)
            : getV2SessionSvg(props.session.num, isTopComplete)}
        </View>
        <View>
          <SessionCardTitle
            title={props.session.title}
            subtitle={props.session.subtitle}
            state={isTopActive || state === 'unlocked' ? 'active' : 'complete'}
            header={header}
          />
        </View>
        {props.collapsed ? null : (
          <View style={{ marginTop: 16, marginStart: 22 }}>
            <Text
              text="Agenda"
              color={Color.styleGuide.Gray3}
              weight="semibold"
              style={{ marginBottom: 5 }}
            />
            {props.session.details.map((detail, i) => (
              <View style={{ flexDirection: 'row', marginBottom: 8 }} key={i}>
                <Text
                  text={'•'}
                  style={{ marginEnd: 8, width: 10, textAlign: 'center', color: '#999' }}
                />
                <Text text={`${detail}`} />
              </View>
            ))}
          </View>
        )}
      </TouchableOpacity>
      {props.session.practiceTitle ? (
        <TouchableOpacity
          accessibilityRole="button"
          testID="SessionCard_bottom"
          disabled={!isBottomActive && !isBottomComplete}
          onPress={props.onPressBottom}
          style={[
            styles.component,
            {
              flexDirection: 'row',
              alignItems: 'center',
              borderTopEndRadius: 0,
              borderTopStartRadius: 0,
            },
            isBottomActive ? {} : { borderTopWidth: 0 },
            isBottomComplete
              ? {
                  borderColor: '#F4F8F5',
                  backgroundColor: '#F4F8F5',
                  borderTopColor: '#eee',
                  borderTopWidth: 2,
                }
              : null,
          ]}
        >
          <SessionCardTitle
            header=""
            isPractice
            state={isBottomActive ? 'review' : isBottomComplete ? 'complete' : 'locked'}
            title={props.session.practiceTitle}
          />
        </TouchableOpacity>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  component: {
    padding: 18,
    borderWidth: 2,
    borderColor: '#EFF0F3',
    borderRadius: 20,
    overflow: 'hidden',
  },
  locked: {
    padding: 22,
  },
});

export function SessionCardBlank(props: { children?: ReactNode; testID?: string }) {
  return (
    <View testID={props.testID} style={[Shadow.default, { overflow: 'visible' }]}>
      <View
        style={[
          card,
          styles.component,
          [
            {
              borderColor: 'transparent',
              minHeight: 80,
            },
          ],
        ]}
      >
        {props.children}
      </View>
    </View>
  );
}
