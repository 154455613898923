import {
  Identify,
  getUserId,
  identify,
  init,
  setUserId,
  track,
} from '@amplitude/analytics-react-native';
import * as Sentry from '@sentry/core';

import { AMPLITUDE_API_KEY, AMPLITUDE_URL } from '@src/constants';

if (AMPLITUDE_API_KEY) {
  init(AMPLITUDE_API_KEY, undefined, {
    trackingOptions: { ipAddress: false, adid: false },
    serverUrl: AMPLITUDE_URL,
  });
}

export function setUser({ userID, email }: { userID?: string | null; email?: string | null }) {
  const sentryPayload: Parameters<typeof Sentry.setUser>[0] = userID ? { id: userID } : null;
  if (sentryPayload && email) {
    sentryPayload.email = email;
  }
  Sentry.setUser(sentryPayload);
  setUserId(userID ?? undefined);
}

export function setUserProperties(
  properties: Record<string, number | string | boolean | undefined>,
) {
  const identifyObj = new Identify();
  Object.entries(properties).forEach(([key, value]) => {
    if (typeof value !== 'undefined') {
      identifyObj.set(key, value);
    }
  });
  const user_id = getUserId();
  identify(identifyObj, { user_id });
}

export const addBreadcrumb: typeof Sentry.addBreadcrumb = (crumb) => {
  Sentry.addBreadcrumb(crumb);
  if (crumb.category !== 'mqtt-connection') {
    const eventName =
      crumb.category && crumb.message
        ? `${crumb.category}:${crumb.message}`
        : crumb.category || crumb.message || 'Unknown event';
    if (crumb.data) {
      track(eventName, crumb.data);
    } else {
      track(eventName);
    }
  }
};

export const logEvent = (
  eventName: string,
  data?: Record<string, string | number | boolean | null>,
) => {
  if (data) {
    track(eventName, data);
  } else {
    track(eventName);
  }
};
