import { NavigationContainerRef } from '@react-navigation/native';
import { locale } from 'expo-localization';
import { createContext, useContext } from 'react';
import { SvgProps } from 'react-native-svg';

import { FLAGS } from '@src/constants';
import { DeeplinkConfigShape } from '@src/types/navigation';

export type DeeplinkOptions = DeeplinkConfigShape<string>;

export function useAppContext() {
  return useContext(AppContext);
}

const AppContext = createContext<{
  Logo: React.FunctionComponent<SvgProps & { accessibilityLabel: string | undefined }>;
  deeplinkConfig?: { screens: DeeplinkOptions };
  flags: AppCore.Flags;
  locale: string;
  navigationContainer: NavigationContainerRef<{}> | null;
}>({
  Logo: () => null,
  deeplinkConfig: undefined,
  flags: FLAGS,
  // We don't currently have a good way to access nextjs libs in app-core, so we just assume
  // en if no locale is available via expo
  // https://github.com/ouihealth/oui-aviva/pull/477#discussion_r1112090711
  locale: locale || 'en',
  navigationContainer: null,
});

export default AppContext;
