// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MinimumAppVersionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MinimumAppVersionQuery = { readonly minimumAppVersion: { readonly recommendedMinimumVersion: string, readonly requiredMinimumVersion: string } };


export const MinimumAppVersionDocument = gql`
    query MinimumAppVersion {
  minimumAppVersion {
    recommendedMinimumVersion
    requiredMinimumVersion
  }
}
    `;

/**
 * __useMinimumAppVersionQuery__
 *
 * To run a query within a React component, call `useMinimumAppVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinimumAppVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinimumAppVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useMinimumAppVersionQuery(baseOptions?: Apollo.QueryHookOptions<MinimumAppVersionQuery, MinimumAppVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MinimumAppVersionQuery, MinimumAppVersionQueryVariables>(MinimumAppVersionDocument, options);
      }
export function useMinimumAppVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MinimumAppVersionQuery, MinimumAppVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MinimumAppVersionQuery, MinimumAppVersionQueryVariables>(MinimumAppVersionDocument, options);
        }
export type MinimumAppVersionQueryHookResult = ReturnType<typeof useMinimumAppVersionQuery>;
export type MinimumAppVersionLazyQueryHookResult = ReturnType<typeof useMinimumAppVersionLazyQuery>;

export function queryMinimumAppVersion(client: Apollo.ApolloClient<unknown>, variables?: MinimumAppVersionQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<MinimumAppVersionQuery, MinimumAppVersionQueryVariables>, "query" | 'variables'>) {
  return client.query<MinimumAppVersionQuery, MinimumAppVersionQueryVariables>({
    query: MinimumAppVersionDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







