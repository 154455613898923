// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinishRegisteringQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FinishRegisteringQuery = { readonly ouiUser?: { readonly __typename: 'CurrentOuiUser', readonly userID: string, readonly user?: { readonly __typename: 'OuiAdmin' } | { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly profile: { readonly patient: { readonly ID: CustomScalars.GQLUUID }, readonly supportees: ReadonlyArray<{ readonly __typename: 'PatientSupporter', readonly supporterID: CustomScalars.GQLUUID, readonly patientID: CustomScalars.GQLUUID, readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID } }> } } | { readonly __typename: 'Practitioner' } | { readonly __typename: 'Registrar' } | null } | null };

export type SupportPatientRegistrationMutationVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
  update: Types.PatientUpdateInput;
  patientRelationInput: Types.PatientSupporterRelationInput;
}>;


export type SupportPatientRegistrationMutation = { readonly updatePatient: { readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly person: { readonly givenName?: string | null, readonly familyName?: string | null, readonly phone?: string | null, readonly email?: string | null } } }, readonly setPatientSupporterRelation: { readonly patientID: CustomScalars.GQLUUID, readonly supporterID: CustomScalars.GQLUUID, readonly relation: Types.PatientSupporterRelation } };


export const FinishRegisteringDocument = gql`
    query FinishRegistering {
  ouiUser {
    __typename
    userID
    user {
      __typename
      ... on Patient {
        ID
        profile {
          patient {
            ID
          }
          supportees {
            __typename
            supporterID
            patientID
            patient {
              __typename
              ID
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFinishRegisteringQuery__
 *
 * To run a query within a React component, call `useFinishRegisteringQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinishRegisteringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinishRegisteringQuery({
 *   variables: {
 *   },
 * });
 */
export function useFinishRegisteringQuery(baseOptions?: Apollo.QueryHookOptions<FinishRegisteringQuery, FinishRegisteringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinishRegisteringQuery, FinishRegisteringQueryVariables>(FinishRegisteringDocument, options);
      }
export function useFinishRegisteringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinishRegisteringQuery, FinishRegisteringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinishRegisteringQuery, FinishRegisteringQueryVariables>(FinishRegisteringDocument, options);
        }
export type FinishRegisteringQueryHookResult = ReturnType<typeof useFinishRegisteringQuery>;
export type FinishRegisteringLazyQueryHookResult = ReturnType<typeof useFinishRegisteringLazyQuery>;

export function queryFinishRegistering(client: Apollo.ApolloClient<unknown>, variables?: FinishRegisteringQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<FinishRegisteringQuery, FinishRegisteringQueryVariables>, "query" | 'variables'>) {
  return client.query<FinishRegisteringQuery, FinishRegisteringQueryVariables>({
    query: FinishRegisteringDocument,
    variables,
    ...options,
  });
}

export const SupportPatientRegistrationDocument = gql`
    mutation SupportPatientRegistration($patientID: UUID!, $update: PatientUpdateInput!, $patientRelationInput: PatientSupporterRelationInput!) {
  updatePatient(patientID: $patientID, update: $update) {
    patient {
      __typename
      ID
      person {
        givenName
        familyName
        phone
        email
      }
    }
  }
  setPatientSupporterRelation(input: $patientRelationInput) {
    patientID
    supporterID
    relation
  }
}
    `;

/**
 * __useSupportPatientRegistrationMutation__
 *
 * To run a mutation, you first call `useSupportPatientRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupportPatientRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supportPatientRegistrationMutation, { data, loading, error }] = useSupportPatientRegistrationMutation({
 *   variables: {
 *      patientID: // value for 'patientID'
 *      update: // value for 'update'
 *      patientRelationInput: // value for 'patientRelationInput'
 *   },
 * });
 */
export function useSupportPatientRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<SupportPatientRegistrationMutation, SupportPatientRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SupportPatientRegistrationMutation, SupportPatientRegistrationMutationVariables>(SupportPatientRegistrationDocument, options);
      }
export type SupportPatientRegistrationMutationHookResult = ReturnType<typeof useSupportPatientRegistrationMutation>;

export function performSupportPatientRegistration(client: Apollo.ApolloClient<unknown>, variables: SupportPatientRegistrationMutationVariables, options?: Omit<Apollo.MutationOptions<SupportPatientRegistrationMutation, SupportPatientRegistrationMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<SupportPatientRegistrationMutation, SupportPatientRegistrationMutationVariables>({
    mutation: SupportPatientRegistrationDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const SupportPatientRegistrationMutationSchema = z.lazy(() => z.object({
patientID: Types.UUIDSchema,
update: Types.PatientUpdateInputSchema,
patientRelationInput: Types.PatientSupporterRelationInputSchema
}))