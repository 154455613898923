import { useEffect, useState } from 'react';

import { Modal } from '@src/components/Modal';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { getVTTTranscript } from '@src/lib/vtt';

export function TranscriptModal(props: {
  heading?: string;
  uri: string;
  onRequestClose: () => void;
}) {
  const [transcript, setTranscript] = useState<string[]>([]);
  useEffect(() => {
    getVTTTranscript(props.uri).then(setTranscript);
  }, [props.uri]);
  return (
    <Modal
      visible
      heading={props.heading ?? 'Transcript'}
      onRequestClose={props.onRequestClose}
      maxHeight={500}
    >
      <View spacing={16}>
        {transcript.map((str, i) => (
          <Text text={str} key={i.toString()} />
        ))}
      </View>
    </Modal>
  );
}
