// https://github.com/necolas/react-native-web/issues/427#issuecomment-352474031
import { Component, ReactNode } from 'react';
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

export class AspectRatio extends Component<{
  onLayout?: () => void;
  aspectRatio: number;
  style?: StyleProp<ViewStyle>;
  children: ReactNode;
}> {
  render() {
    const { children, onLayout, aspectRatio, style } = this.props;
    const percentage = 100 / aspectRatio;

    return Platform.OS === 'web' ? (
      <View
        onLayout={onLayout}
        style={[
          {
            display: 'block' as any, // eslint-disable-line
            overflow: 'hidden',
          },
          style,
        ]}
      >
        <div style={{ display: 'block', width: '100%', paddingBottom: `${percentage}%` }}></div>
        <div style={styles.content}>{children}</div>
      </View>
    ) : (
      <View style={[{ aspectRatio: aspectRatio, maxHeight: '100%', maxWidth: '100%' }, style]}>
        {children}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
});
