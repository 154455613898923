'use client';

import * as Sentry from '@sentry/core';
import IntlMessageFormat, { PrimitiveType } from 'intl-messageformat';
import { PropsWithChildren, ReactElement, createElement, useCallback } from 'react';
import { IntlProvider, IntlShape, ResolvedIntlConfig, useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { useAppContext } from '@src/components/AppContext';
import { Environment, IS_PRODUCTION, environment } from '@src/constants';

const INVALID_FLAG = '🚫';
const DEFAULT_LOCALE = 'en-US';
const FLAGS: { [key: string]: string } = {
  en: ' 🇺🇸',
  es: '🇲🇽',
};

type Variables = Record<string, PrimitiveType>;

export const LOCALES = Object.keys(FLAGS);

function wrapInFlags(text: string, locale: string) {
  const flag = FLAGS[locale] ?? FLAGS[locale.split('-')[0]] ?? locale;
  return `${flag}${text}${flag}`;
}

export type { IntlShape };

// In production we dont want to override $t to show our flags and we'd rather not take a perf hit
export const useI18n = IS_PRODUCTION
  ? useIntl
  : (): IntlShape => {
      const {
        flags: { showI18NFlags, showI18NKeys },
      } = useAppContext();
      const intl = useIntl();
      const $t: (typeof intl)['formatMessage'] = useCallback(
        (...args) => {
          if (showI18NKeys) return args[0].id!;
          // @ts-expect-error
          const result = intl.formatMessage(...args) as string;
          const hasMessage = !!intl.messages[args[0].id!];
          return showI18NFlags
            ? intl.locale === 'en' || hasMessage
              ? wrapInFlags(result, intl.locale)
              : `${INVALID_FLAG}${result}${INVALID_FLAG}`
            : result;
        },
        [intl, showI18NFlags, showI18NKeys],
      );
      return { ...intl, $t };
    };

export function formatTemplate(
  messageTemplate: string,
  variables?: Variables,
  options?: { wrapInFlags?: boolean; locale: string },
) {
  const template = new IntlMessageFormat(messageTemplate, options?.locale ?? DEFAULT_LOCALE);
  const result = template.format<string>(variables) as string;
  return options?.wrapInFlags ? wrapInFlags(result, options?.locale ?? DEFAULT_LOCALE) : result;
}

export function I18nProvider({
  children,
  getMessages,
  ignoreMissingMessages,
}: PropsWithChildren<{
  getMessages: (context: { locale: string; lang: string }) => ResolvedIntlConfig['messages'];
  ignoreMissingMessages?: boolean;
}>): ReactElement {
  const { locale } = useAppContext();
  const forceDefaultMessages = locale.startsWith('en') && environment === Environment.DEVELOPMENT;

  return createElement(
    IntlProvider,
    {
      // not yet ready to serve other locales
      locale: environment === Environment.PRODUCTION ? 'en' : locale,
      messages: forceDefaultMessages ? {} : getMessages({ locale, lang: locale.split('-')[0] }),
      defaultLocale: 'en',
      fallbackOnEmptyString: false,
      onError: (err) => {
        if (ignoreMissingMessages) return;
        if (locale.startsWith('en') && environment !== Environment.PRODUCTION) {
          if (environment === Environment.DEVELOPMENT) return;
          if (Platform.OS === 'web' && global.window?.location.hostname.endsWith('.chromatic.com'))
            return;
          Sentry.captureMessage('i18n warning for en locale', { extra: { err, locale } });
        } else {
          Sentry.captureException(err, { extra: { locale } });
        }
      },
    },
    children,
  );
}
