import capitalize from 'lodash/capitalize';

export function humanizeEnumKey(key: string) {
  return key.split('_').map(capitalize).join(' ');
}

export const getPickerItemsFromEnum = <T extends string | number | boolean>(
  enumData: Record<string, T>,
) => {
  return Object.keys(enumData).map((key) => {
    return { label: humanizeEnumKey(key), value: key as T };
  });
};
