// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompositionSectionFieldsFragment = { readonly __typename: 'CompositionSection', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly json?: CustomScalars.GQLAny | null, readonly createdAt: CustomScalars.GQLDateTime, readonly updatedAt: CustomScalars.GQLDateTime };

export type CompositionFieldsFragment = { readonly __typename: 'Composition', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly sections: ReadonlyArray<{ readonly __typename: 'CompositionSection', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly json?: CustomScalars.GQLAny | null, readonly createdAt: CustomScalars.GQLDateTime, readonly updatedAt: CustomScalars.GQLDateTime }> };

export type CompositionByIdQueryVariables = Types.Exact<{
  compositionID: Types.Scalars['UUID']['input'];
}>;


export type CompositionByIdQuery = { readonly compositionByID?: { readonly __typename: 'Composition', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly sections: ReadonlyArray<{ readonly __typename: 'CompositionSection', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly json?: CustomScalars.GQLAny | null, readonly createdAt: CustomScalars.GQLDateTime, readonly updatedAt: CustomScalars.GQLDateTime }> } | null };

export type CompositionsQueryVariables = Types.Exact<{
  patientID?: Types.InputMaybe<Types.Scalars['UUID']['input']>;
  title?: Types.InputMaybe<Types.Scalars['String']['input']>;
  template?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CompositionsQuery = { readonly compositions: ReadonlyArray<{ readonly __typename: 'Composition', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly sections: ReadonlyArray<{ readonly __typename: 'CompositionSection', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly json?: CustomScalars.GQLAny | null, readonly createdAt: CustomScalars.GQLDateTime, readonly updatedAt: CustomScalars.GQLDateTime }> }> };

export type NewCompositionWithTemplateMutationVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
  title?: Types.InputMaybe<Types.Scalars['String']['input']>;
  template: Types.CompositionTemplate;
}>;


export type NewCompositionWithTemplateMutation = { readonly newCompositionWithTemplate: { readonly __typename: 'Composition', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly sections: ReadonlyArray<{ readonly __typename: 'CompositionSection', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly json?: CustomScalars.GQLAny | null, readonly createdAt: CustomScalars.GQLDateTime, readonly updatedAt: CustomScalars.GQLDateTime }> } };

export type UpdateCompositionSectionMutationVariables = Types.Exact<{
  sectionID: Types.Scalars['UUID']['input'];
  title?: Types.InputMaybe<Types.Scalars['String']['input']>;
  text?: Types.InputMaybe<Types.Scalars['Any']['input']>;
}>;


export type UpdateCompositionSectionMutation = { readonly updateCompositionSection: { readonly __typename: 'CompositionSection', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly json?: CustomScalars.GQLAny | null, readonly createdAt: CustomScalars.GQLDateTime, readonly updatedAt: CustomScalars.GQLDateTime } };

export type SetMyStoryMyPlanCompleteMutationVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type SetMyStoryMyPlanCompleteMutation = { readonly setMyPlan: { readonly content: Types.ContentType, readonly completion: number, readonly updatedAt: CustomScalars.GQLDateTime } };

export const CompositionSectionFieldsFragmentDoc = gql`
    fragment CompositionSectionFields on CompositionSection {
  __typename
  ID
  title
  json
  createdAt
  updatedAt
}
    `;
export const CompositionFieldsFragmentDoc = gql`
    fragment CompositionFields on Composition {
  __typename
  ID
  title
  sections {
    ...CompositionSectionFields
  }
}
    ${CompositionSectionFieldsFragmentDoc}`;
export const CompositionByIdDocument = gql`
    query CompositionByID($compositionID: UUID!) {
  compositionByID(compositionID: $compositionID) {
    ...CompositionFields
  }
}
    ${CompositionFieldsFragmentDoc}`;

/**
 * __useCompositionByIdQuery__
 *
 * To run a query within a React component, call `useCompositionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompositionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompositionByIdQuery({
 *   variables: {
 *      compositionID: // value for 'compositionID'
 *   },
 * });
 */
export function useCompositionByIdQuery(baseOptions: Apollo.QueryHookOptions<CompositionByIdQuery, CompositionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositionByIdQuery, CompositionByIdQueryVariables>(CompositionByIdDocument, options);
      }
export function useCompositionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositionByIdQuery, CompositionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositionByIdQuery, CompositionByIdQueryVariables>(CompositionByIdDocument, options);
        }
export type CompositionByIdQueryHookResult = ReturnType<typeof useCompositionByIdQuery>;
export type CompositionByIdLazyQueryHookResult = ReturnType<typeof useCompositionByIdLazyQuery>;

export function queryCompositionById(client: Apollo.ApolloClient<unknown>, variables: CompositionByIdQueryVariables, options?: Omit<Apollo.QueryOptions<CompositionByIdQuery, CompositionByIdQueryVariables>, "query" | 'variables'>) {
  return client.query<CompositionByIdQuery, CompositionByIdQueryVariables>({
    query: CompositionByIdDocument,
    variables,
    ...options,
  });
}

export const CompositionsDocument = gql`
    query Compositions($patientID: UUID, $title: String, $template: String) {
  compositions(patientID: $patientID, title: $title, template: $template) {
    ...CompositionFields
  }
}
    ${CompositionFieldsFragmentDoc}`;

/**
 * __useCompositionsQuery__
 *
 * To run a query within a React component, call `useCompositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompositionsQuery({
 *   variables: {
 *      patientID: // value for 'patientID'
 *      title: // value for 'title'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useCompositionsQuery(baseOptions?: Apollo.QueryHookOptions<CompositionsQuery, CompositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositionsQuery, CompositionsQueryVariables>(CompositionsDocument, options);
      }
export function useCompositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositionsQuery, CompositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositionsQuery, CompositionsQueryVariables>(CompositionsDocument, options);
        }
export type CompositionsQueryHookResult = ReturnType<typeof useCompositionsQuery>;
export type CompositionsLazyQueryHookResult = ReturnType<typeof useCompositionsLazyQuery>;

export function queryCompositions(client: Apollo.ApolloClient<unknown>, variables?: CompositionsQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<CompositionsQuery, CompositionsQueryVariables>, "query" | 'variables'>) {
  return client.query<CompositionsQuery, CompositionsQueryVariables>({
    query: CompositionsDocument,
    variables,
    ...options,
  });
}

export const NewCompositionWithTemplateDocument = gql`
    mutation NewCompositionWithTemplate($patientID: UUID!, $title: String, $template: CompositionTemplate!) {
  newCompositionWithTemplate(
    patientID: $patientID
    title: $title
    template: $template
  ) {
    ...CompositionFields
  }
}
    ${CompositionFieldsFragmentDoc}`;

/**
 * __useNewCompositionWithTemplateMutation__
 *
 * To run a mutation, you first call `useNewCompositionWithTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewCompositionWithTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newCompositionWithTemplateMutation, { data, loading, error }] = useNewCompositionWithTemplateMutation({
 *   variables: {
 *      patientID: // value for 'patientID'
 *      title: // value for 'title'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useNewCompositionWithTemplateMutation(baseOptions?: Apollo.MutationHookOptions<NewCompositionWithTemplateMutation, NewCompositionWithTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewCompositionWithTemplateMutation, NewCompositionWithTemplateMutationVariables>(NewCompositionWithTemplateDocument, options);
      }
export type NewCompositionWithTemplateMutationHookResult = ReturnType<typeof useNewCompositionWithTemplateMutation>;

export function performNewCompositionWithTemplate(client: Apollo.ApolloClient<unknown>, variables: NewCompositionWithTemplateMutationVariables, options?: Omit<Apollo.MutationOptions<NewCompositionWithTemplateMutation, NewCompositionWithTemplateMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<NewCompositionWithTemplateMutation, NewCompositionWithTemplateMutationVariables>({
    mutation: NewCompositionWithTemplateDocument,
    variables,
    ...options,
  });
}

export const UpdateCompositionSectionDocument = gql`
    mutation UpdateCompositionSection($sectionID: UUID!, $title: String, $text: Any) {
  updateCompositionSection(sectionID: $sectionID, title: $title, text: $text) {
    ...CompositionSectionFields
  }
}
    ${CompositionSectionFieldsFragmentDoc}`;

/**
 * __useUpdateCompositionSectionMutation__
 *
 * To run a mutation, you first call `useUpdateCompositionSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompositionSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompositionSectionMutation, { data, loading, error }] = useUpdateCompositionSectionMutation({
 *   variables: {
 *      sectionID: // value for 'sectionID'
 *      title: // value for 'title'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateCompositionSectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompositionSectionMutation, UpdateCompositionSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompositionSectionMutation, UpdateCompositionSectionMutationVariables>(UpdateCompositionSectionDocument, options);
      }
export type UpdateCompositionSectionMutationHookResult = ReturnType<typeof useUpdateCompositionSectionMutation>;

export function performUpdateCompositionSection(client: Apollo.ApolloClient<unknown>, variables: UpdateCompositionSectionMutationVariables, options?: Omit<Apollo.MutationOptions<UpdateCompositionSectionMutation, UpdateCompositionSectionMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<UpdateCompositionSectionMutation, UpdateCompositionSectionMutationVariables>({
    mutation: UpdateCompositionSectionDocument,
    variables,
    ...options,
  });
}

export const SetMyStoryMyPlanCompleteDocument = gql`
    mutation SetMyStoryMyPlanComplete($patientID: UUID!) {
  setMyPlan: setOuiProgressForPatient(
    patientID: $patientID
    content: MYPLAN
    value: 1.0
  ) {
    content
    completion
    updatedAt
  }
}
    `;

/**
 * __useSetMyStoryMyPlanCompleteMutation__
 *
 * To run a mutation, you first call `useSetMyStoryMyPlanCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMyStoryMyPlanCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMyStoryMyPlanCompleteMutation, { data, loading, error }] = useSetMyStoryMyPlanCompleteMutation({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function useSetMyStoryMyPlanCompleteMutation(baseOptions?: Apollo.MutationHookOptions<SetMyStoryMyPlanCompleteMutation, SetMyStoryMyPlanCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMyStoryMyPlanCompleteMutation, SetMyStoryMyPlanCompleteMutationVariables>(SetMyStoryMyPlanCompleteDocument, options);
      }
export type SetMyStoryMyPlanCompleteMutationHookResult = ReturnType<typeof useSetMyStoryMyPlanCompleteMutation>;

export function performSetMyStoryMyPlanComplete(client: Apollo.ApolloClient<unknown>, variables: SetMyStoryMyPlanCompleteMutationVariables, options?: Omit<Apollo.MutationOptions<SetMyStoryMyPlanCompleteMutation, SetMyStoryMyPlanCompleteMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<SetMyStoryMyPlanCompleteMutation, SetMyStoryMyPlanCompleteMutationVariables>({
    mutation: SetMyStoryMyPlanCompleteDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const NewCompositionWithTemplateMutationSchema = z.lazy(() => z.object({
patientID: Types.UUIDSchema,
title: z.string().nullish(),
template: Types.CompositionTemplateSchema
}));


export const UpdateCompositionSectionMutationSchema = z.lazy(() => z.object({
sectionID: Types.UUIDSchema,
title: z.string().nullish(),
text: Types.AnySchema.nullish()
}));


export const SetMyStoryMyPlanCompleteMutationSchema = z.lazy(() => z.object({
patientID: Types.UUIDSchema
}))