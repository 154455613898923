import { ApolloClient, useApolloClient } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Sentry from '@sentry/core';
import { ReactNode, createContext, useContext, useState } from 'react';

import { performLogout } from '@src/hooks/useLogout.graphql.generated';
import {
  clearPersistedState,
  preloadPersistedState,
  setPersistedState,
} from '@src/hooks/usePersistedState';
import { signOut } from '@src/lib/auth';
import { getInstallationIDAsync } from '@src/lib/getInstallationIDAsync';

export const LogoutContext = createContext({
  isLoggingOut: false,
  setIsLoggingOut: (value: boolean) => {},
});

export function LogoutProvider(props: { children: ReactNode }) {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  return (
    <LogoutContext.Provider value={{ isLoggingOut, setIsLoggingOut }}>
      {props.children}
    </LogoutContext.Provider>
  );
}

export async function logout({
  apollo,
  setIsLoggingOut,
}: {
  apollo: ApolloClient<unknown>;
  setIsLoggingOut: (val: boolean) => void;
}) {
  const installationID = await getInstallationIDAsync();
  return performLogout(apollo, { deviceID: installationID })
    .catch((e) => Sentry.captureException(e))
    .then(() => setIsLoggingOut(true))
    .then(() => signOut())
    .catch((e) => Sentry.captureException(e))
    .then(() => apollo.clearStore())
    .then(() => {
      const disableLocalAuthentication = preloadPersistedState('disableLocalAuthentication');
      clearPersistedState();
      if (typeof disableLocalAuthentication === 'boolean') {
        setPersistedState('disableLocalAuthentication', disableLocalAuthentication);
      }
    })
    .then(() =>
      AsyncStorage.multiSet([
        ['lastSeen', Date.now().toString()],
        ['installationID', installationID],
      ]),
    )
    .then(() => setPersistedState('SeenLocalAuthenticationPrompt', false))
    .catch((e) => Sentry.captureException(e))
    .then(() => setIsLoggingOut(false));
}

export function useLogout() {
  const { setIsLoggingOut } = useContext(LogoutContext);
  const client = useApolloClient();
  return async () => {
    return logout({ apollo: client, setIsLoggingOut });
  };
}
