import * as Font from 'expo-font';

// used b/c nextjs require returns { default: '' }
function interopDefault<T>(obj: T): string | number | undefined {
  // @ts-ignore
  return obj.default || obj;
}

export function initFonts() {
  return Font.loadAsync({
    OpenSansBold: {
      uri: interopDefault(require('../assets/font/OpenSans-Bold.ttf')),
      display: Font.FontDisplay.FALLBACK,
    },
    OpenSansRegular: {
      uri: interopDefault(require('../assets/font/OpenSans-Regular.ttf')),
      display: Font.FontDisplay.FALLBACK,
    },
    OpenSansSemiBold: {
      uri: interopDefault(require('../assets/font/OpenSans-SemiBold.ttf')),
      display: Font.FontDisplay.FALLBACK,
    },
    SourceSerifProSemiBold: {
      uri: interopDefault(require('../assets/font/SourceSerifPro-SemiBold.ttf')),
      display: Font.FontDisplay.FALLBACK,
    },
  });
}
