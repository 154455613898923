import { ApolloClient } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform, Linking as RNLinking } from 'react-native';
import Url from 'url-parse';

import { APP_SLUG, LOADING_TIMEOUT, SESSION_TIMEOUT } from '@src/constants';
import { preloadPersistedState, setPersistedState } from '@src/hooks/usePersistedState';
import LogBox from '@src/lib/LogBox';
import { fetchIsConnected } from '@src/lib/NetInfo';
import { initAuth, login, setAuthApolloClient, signOut } from '@src/lib/auth';
import { initFonts } from '@src/lib/initFonts';
import { addBreadcrumb } from '@src/lib/log';
import { initConfig } from '@src/lib/remoteConfig';
import Sentry from '@src/sentry';

async function getInitialURL() {
  return await RNLinking.getInitialURL();
}

let hasSimulatedError = false;

async function wrapInitStep<T>(
  step: string,
  factory: () => Promise<T>,
  errorValue?: T,
): Promise<T> {
  let start = Date.now();
  try {
    addBreadcrumb({ message: 'wrapInitStep:start ' + step });
    const result = await factory();
    Sentry.addBreadcrumb({
      message: 'wrapInitStep:success ' + step,
      data: { duration: Date.now() - start },
    });
    return result;
  } catch (e) {
    Sentry.captureException(e, { extra: { initStep: step, duration: Date.now() - start } });
    Sentry.captureException(new Error(`wrapInitStep:${step} failed`), {
      extra: { originalError: e, duration: Date.now() - start },
    });
    if (typeof errorValue !== 'undefined') {
      return errorValue;
    }
    throw e;
  }
}

export async function initApp(apolloClient: ApolloClient<unknown>) {
  const initialURL = await getInitialURL();
  const { query } = new Url(initialURL, {}, { parser: true });

  const isDetox = query.detox === 'true';
  const clearState = query.detoxClearState === 'true';

  if (clearState) {
    await signOut();
  }

  if (isDetox) {
    global.e2e = true;
    LogBox.ignoreAllLogs(true);
  }

  if (query.detoxEmail && query.detoxPassword) {
    await apolloClient.clearStore();
    setAuthApolloClient(apolloClient);
    await login(query.detoxEmail, query.detoxPassword);
  }

  if (query.skipLocalAuthenticationPrompt) {
    setPersistedState(
      'SeenLocalAuthenticationPrompt',
      JSON.parse(query.skipLocalAuthenticationPrompt),
    );
  }

  if (global.e2e && !!query.minOuiAppVersion) {
    setPersistedState('minOuiAppVersion', JSON.parse(query.minOuiAppVersion));
  }

  if (query.asyncStorage) {
    const obj: object = JSON.parse(query.asyncStorage);
    await AsyncStorage.multiSet(Object.entries(obj));
  }

  const remoteConfigPromise = wrapInitStep('remoteConfig', initConfig);

  const lastSeenPromise = wrapInitStep(
    'lastSeen',
    () =>
      AsyncStorage.getItem('lastSeen').then((lastSeenStr) => {
        const lastSeen = Number.parseInt(lastSeenStr || '');
        return !Number.isFinite(lastSeen) || Date.now() - lastSeen > SESSION_TIMEOUT;
      }),
    true,
  );

  const connectedPromise = wrapInitStep('netInfo', () => fetchIsConnected(5000));
  connectedPromise.then((isConnected) => {
    if (!isConnected) {
      Sentry.addBreadcrumb({ message: 'NOT_CONNECTED_TO_INTERNET' });
    }
  });

  const preloadSeenLocalAuthenticationPromptPromise = wrapInitStep(
    'preloadSeenLocalAuthenticationPrompt',
    () => preloadPersistedState('SeenLocalAuthenticationPrompt').then(() => true),
    true,
  );

  const fontPromise = wrapInitStep(
    'initFonts',
    () =>
      initFonts().then(() => {
        // @ts-ignore
        global.fontsLoaded = true;
        return true;
      }),
    true,
  );
  const fontTimeout = new Promise<void>((resolve) => {
    const timeout = setTimeout(() => {
      Sentry.captureMessage('FONT_TIMEOUT');
      resolve();
    }, 2000);

    fontPromise.then(() => {
      clearTimeout(timeout);
    });
  });
  const fontLoader = Promise.race([fontPromise, fontTimeout]);

  if (query.detoxSimulateLoadingError && !hasSimulatedError) {
    hasSimulatedError = true;
    throw new Error('SIMULATED LOADING ERROR');
  }

  const promise = Promise.all([
    fontLoader,
    wrapInitStep('initAuth', () => initAuth(apolloClient)),
    lastSeenPromise,
    connectedPromise,
    preloadSeenLocalAuthenticationPromptPromise,
    remoteConfigPromise,
  ]).then(([, { isLoggedIn }, reauthenticate, isConnected]) => ({
    isLoggedIn,
    reauthenticate: !!(isLoggedIn && reauthenticate),
    isConnected,
  }));

  const timeoutPromise = new Promise((_, reject) => {
    const timeout = setTimeout(() => {
      console.log('LOADING_TIMEOUT');
      reject(new Error('LOADING_TIMEOUT'));
    }, LOADING_TIMEOUT);
    promise.then(() => clearTimeout(timeout));
  });
  return Promise.race([promise, timeoutPromise]) as typeof promise;
}

export function shouldShowWebBlocker() {
  return (
    !global.e2e &&
    Platform.OS === 'web' &&
    ['oui-aviva'].includes(APP_SLUG) &&
    !window.location.hostname.includes('-dev-')
  );
}
