// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveResponseMutationVariables = Types.Exact<{
  context: Types.Scalars['String']['input'];
  key: Types.Scalars['String']['input'];
  data: Types.Scalars['Any']['input'];
}>;


export type SaveResponseMutation = { readonly respond: boolean };

export type GetResponseQueryVariables = Types.Exact<{
  context: Types.Scalars['String']['input'];
  key: Types.Scalars['String']['input'];
}>;


export type GetResponseQuery = { readonly response?: CustomScalars.GQLAny | null };

export type KvRespondMutationVariables = Types.Exact<{
  context: Types.Scalars['String']['input'];
  key: Types.Scalars['String']['input'];
  data: Types.Scalars['Any']['input'];
}>;


export type KvRespondMutation = { readonly kvRespond: { readonly __typename: 'KVResponse', readonly context: string, readonly key: string, readonly value?: CustomScalars.GQLAny | null } };

export type KvResponseQueryVariables = Types.Exact<{
  context: Types.Scalars['String']['input'];
  key: Types.Scalars['String']['input'];
}>;


export type KvResponseQuery = { readonly kvResponse: { readonly __typename: 'KVResponse', readonly context: string, readonly key: string, readonly value?: CustomScalars.GQLAny | null } };


export const SaveResponseDocument = gql`
    mutation SaveResponse($context: String!, $key: String!, $data: Any!) {
  respond(context: $context, key: $key, data: $data)
}
    `;

/**
 * __useSaveResponseMutation__
 *
 * To run a mutation, you first call `useSaveResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveResponseMutation, { data, loading, error }] = useSaveResponseMutation({
 *   variables: {
 *      context: // value for 'context'
 *      key: // value for 'key'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveResponseMutation(baseOptions?: Apollo.MutationHookOptions<SaveResponseMutation, SaveResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveResponseMutation, SaveResponseMutationVariables>(SaveResponseDocument, options);
      }
export type SaveResponseMutationHookResult = ReturnType<typeof useSaveResponseMutation>;

export function performSaveResponse(client: Apollo.ApolloClient<unknown>, variables: SaveResponseMutationVariables, options?: Omit<Apollo.MutationOptions<SaveResponseMutation, SaveResponseMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<SaveResponseMutation, SaveResponseMutationVariables>({
    mutation: SaveResponseDocument,
    variables,
    ...options,
  });
}

export const GetResponseDocument = gql`
    query GetResponse($context: String!, $key: String!) {
  response(context: $context, key: $key)
}
    `;

/**
 * __useGetResponseQuery__
 *
 * To run a query within a React component, call `useGetResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResponseQuery({
 *   variables: {
 *      context: // value for 'context'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetResponseQuery(baseOptions: Apollo.QueryHookOptions<GetResponseQuery, GetResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResponseQuery, GetResponseQueryVariables>(GetResponseDocument, options);
      }
export function useGetResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResponseQuery, GetResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResponseQuery, GetResponseQueryVariables>(GetResponseDocument, options);
        }
export type GetResponseQueryHookResult = ReturnType<typeof useGetResponseQuery>;
export type GetResponseLazyQueryHookResult = ReturnType<typeof useGetResponseLazyQuery>;

export function queryGetResponse(client: Apollo.ApolloClient<unknown>, variables: GetResponseQueryVariables, options?: Omit<Apollo.QueryOptions<GetResponseQuery, GetResponseQueryVariables>, "query" | 'variables'>) {
  return client.query<GetResponseQuery, GetResponseQueryVariables>({
    query: GetResponseDocument,
    variables,
    ...options,
  });
}

export const KvRespondDocument = gql`
    mutation KVRespond($context: String!, $key: String!, $data: Any!) {
  kvRespond(context: $context, key: $key, data: $data) {
    __typename
    context
    key
    value
  }
}
    `;

/**
 * __useKvRespondMutation__
 *
 * To run a mutation, you first call `useKvRespondMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKvRespondMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kvRespondMutation, { data, loading, error }] = useKvRespondMutation({
 *   variables: {
 *      context: // value for 'context'
 *      key: // value for 'key'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useKvRespondMutation(baseOptions?: Apollo.MutationHookOptions<KvRespondMutation, KvRespondMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KvRespondMutation, KvRespondMutationVariables>(KvRespondDocument, options);
      }
export type KvRespondMutationHookResult = ReturnType<typeof useKvRespondMutation>;

export function performKvRespond(client: Apollo.ApolloClient<unknown>, variables: KvRespondMutationVariables, options?: Omit<Apollo.MutationOptions<KvRespondMutation, KvRespondMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<KvRespondMutation, KvRespondMutationVariables>({
    mutation: KvRespondDocument,
    variables,
    ...options,
  });
}

export const KvResponseDocument = gql`
    query KVResponse($context: String!, $key: String!) {
  kvResponse(context: $context, key: $key) {
    __typename
    context
    key
    value
  }
}
    `;

/**
 * __useKvResponseQuery__
 *
 * To run a query within a React component, call `useKvResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useKvResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKvResponseQuery({
 *   variables: {
 *      context: // value for 'context'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useKvResponseQuery(baseOptions: Apollo.QueryHookOptions<KvResponseQuery, KvResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KvResponseQuery, KvResponseQueryVariables>(KvResponseDocument, options);
      }
export function useKvResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KvResponseQuery, KvResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KvResponseQuery, KvResponseQueryVariables>(KvResponseDocument, options);
        }
export type KvResponseQueryHookResult = ReturnType<typeof useKvResponseQuery>;
export type KvResponseLazyQueryHookResult = ReturnType<typeof useKvResponseLazyQuery>;

export function queryKvResponse(client: Apollo.ApolloClient<unknown>, variables: KvResponseQueryVariables, options?: Omit<Apollo.QueryOptions<KvResponseQuery, KvResponseQueryVariables>, "query" | 'variables'>) {
  return client.query<KvResponseQuery, KvResponseQueryVariables>({
    query: KvResponseDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const SaveResponseMutationSchema = z.lazy(() => z.object({
context: z.string(),
key: z.string(),
data: Types.AnySchema
}));


export const KVRespondMutationSchema = z.lazy(() => z.object({
context: z.string(),
key: z.string(),
data: Types.AnySchema
}))