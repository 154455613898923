// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClaimSignupTokenFromActivationCodeMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
  email?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ClaimSignupTokenFromActivationCodeMutation = { readonly updateOuiUserWithToken: { readonly username: string } };


export const ClaimSignupTokenFromActivationCodeDocument = gql`
    mutation ClaimSignupTokenFromActivationCode($token: String!, $password: String!, $email: String) {
  updateOuiUserWithToken(
    value: $token
    input: {email: $email, password: $password}
  ) {
    username
  }
}
    `;

/**
 * __useClaimSignupTokenFromActivationCodeMutation__
 *
 * To run a mutation, you first call `useClaimSignupTokenFromActivationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimSignupTokenFromActivationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimSignupTokenFromActivationCodeMutation, { data, loading, error }] = useClaimSignupTokenFromActivationCodeMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useClaimSignupTokenFromActivationCodeMutation(baseOptions?: Apollo.MutationHookOptions<ClaimSignupTokenFromActivationCodeMutation, ClaimSignupTokenFromActivationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimSignupTokenFromActivationCodeMutation, ClaimSignupTokenFromActivationCodeMutationVariables>(ClaimSignupTokenFromActivationCodeDocument, options);
      }
export type ClaimSignupTokenFromActivationCodeMutationHookResult = ReturnType<typeof useClaimSignupTokenFromActivationCodeMutation>;

export function performClaimSignupTokenFromActivationCode(client: Apollo.ApolloClient<unknown>, variables: ClaimSignupTokenFromActivationCodeMutationVariables, options?: Omit<Apollo.MutationOptions<ClaimSignupTokenFromActivationCodeMutation, ClaimSignupTokenFromActivationCodeMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<ClaimSignupTokenFromActivationCodeMutation, ClaimSignupTokenFromActivationCodeMutationVariables>({
    mutation: ClaimSignupTokenFromActivationCodeDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const ClaimSignupTokenFromActivationCodeMutationSchema = z.lazy(() => z.object({
token: z.string(),
password: z.string(),
email: z.string().nullish()
}))