// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTestUserMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
  testPatient: Types.TestPatientInput;
}>;


export type CreateTestUserMutation = { readonly createTestUser?: CustomScalars.GQLUserID | null };


export const CreateTestUserDocument = gql`
    mutation CreateTestUser($email: String!, $password: String!, $testPatient: TestPatientInput!) {
  createTestUser(email: $email, password: $password, testPatient: $testPatient)
}
    `;

/**
 * __useCreateTestUserMutation__
 *
 * To run a mutation, you first call `useCreateTestUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestUserMutation, { data, loading, error }] = useCreateTestUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      testPatient: // value for 'testPatient'
 *   },
 * });
 */
export function useCreateTestUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateTestUserMutation, CreateTestUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTestUserMutation, CreateTestUserMutationVariables>(CreateTestUserDocument, options);
      }
export type CreateTestUserMutationHookResult = ReturnType<typeof useCreateTestUserMutation>;

export function performCreateTestUser(client: Apollo.ApolloClient<unknown>, variables: CreateTestUserMutationVariables, options?: Omit<Apollo.MutationOptions<CreateTestUserMutation, CreateTestUserMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<CreateTestUserMutation, CreateTestUserMutationVariables>({
    mutation: CreateTestUserDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const CreateTestUserMutationSchema = z.lazy(() => z.object({
email: z.string(),
password: z.string(),
testPatient: Types.TestPatientInputSchema
}))