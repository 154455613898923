import { useNavigation } from '@react-navigation/native';
import { SafeAreaView } from 'react-native';

import { Button } from '@oui/app-core/src/components/Button';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useWindowDimensions } from '@oui/app-core/src/hooks/useWindowDimensions';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import FullLogo from '@src/assets/Full_logo.svg';
import WelcomeEnvelope from '@src/assets/welcome_envelope.svg';
import { RootStackScreenProps } from '@src/types/navigation';

export function Welcome(_props: {}) {
  const { $t } = useI18n();
  const { height } = useWindowDimensions();
  const navigation = useNavigation<RootStackScreenProps<'Welcome'>['navigation']>();

  return (
    <SafeAreaView
      style={{
        flexGrow: 1,
      }}
    >
      <ScrollView
        contentContainerStyle={{
          flex: 1,
        }}
      >
        <View
          flex={1}
          style={{
            marginTop: height < 600 ? 20 : 40,
          }}
        >
          <View
            flex={1}
            style={{
              alignItems: 'center',
            }}
          >
            <FullLogo
              height={height / 5}
              width={200}
              accessibilityLabel={$t({
                id: 'Welcome_logoAccessibilityLabel',
                defaultMessage: 'Aviva Caregiver Logo',
              })}
              accessibilityRole="image"
            />

            <View
              style={{
                paddingHorizontal: 40,
              }}
              spacing={12}
            >
              <Heading
                level={1}
                text={$t({ id: 'Welcome_title', defaultMessage: 'Welcome to Aviva Caregiver' })}
                testID="Welcome_title"
                textAlign="center"
              />
              <Text
                text={$t({
                  id: 'Welcome_message',
                  defaultMessage:
                    'To activate your account, please contact your child’s clinician for a link',
                })}
                testID="Welcome_message"
                style={{ textAlign: 'center' }}
              />
            </View>
          </View>

          <WelcomeEnvelope
            height={height / 4}
            accessibilityLabel={undefined}
            style={{
              alignSelf: 'center',
            }}
          />
        </View>
      </ScrollView>

      <View>
        <View
          style={{
            borderTopWidth: 1,
          }}
        >
          <View style={{ padding: 16, alignItems: 'center' }} spacing={8}>
            <Button
              onPress={() => navigation.navigate('Login', {})}
              text={$t({
                id: 'Welcome_navigateToLogin',
                defaultMessage: 'I already have an account',
              })}
              testID="Welcome_navigateToLoginButton"
              variant="text"
              alignSelf="center"
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}
