import { Address } from '@oui/lib/src/types';

import { Text } from '@src/components/Text';
import { View } from '@src/components/View';

export function UserAddress(
  props:
    | Address
    | {
        line1: string;
        line2: string;
        city: string;
        state: string | null;
        zip: string | null;
        country?: string;
      },
) {
  if ('line1' in props) {
    return (
      <View>
        {props.line1 ? <Text testID="UserAddress_line1" text={props.line1} /> : null}
        {props.line2 ? <Text testID="UserAddress_line2" text={props.line2} /> : null}
        <Text
          testID="UserAddress_cityStateZip"
          text={`${props.city || ''}, ${props.state || ''} ${props.zip || ''}`}
        />
      </View>
    );
  }

  return (
    <View>
      {props.line?.map((line) => (
        <Text text={line} key={line} />
      ))}
      <Text
        testID="UserAddress_cityStateZip"
        text={`${props.city || ''}, ${props.state || ''} ${props.postalCode || ''}`}
      />
    </View>
  );
}
