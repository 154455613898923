import { useNavigation } from '@react-navigation/native';

import { SessionWithState } from '@oui/app-core/src/hooks/useFeed';
import { Learn as LearnScreen } from '@oui/app-core/src/screens/Learn';
import { useNavigateToStaticSessionHook } from '@oui/app-static/src/hooks/useNavigateToStaticSessionHook';

import { TabScreenProps } from '@src/types/navigation';

export function Learn(_props: {}) {
  const navigation = useNavigation<TabScreenProps<'Learn'>['navigation']>();
  const { navigateToStaticSession } = useNavigateToStaticSessionHook({ navigation });

  const onSessionCardPress = (session: SessionWithState) => {
    navigateToStaticSession(session, 'sessions');
  };

  return <LearnScreen onSessionCardPress={onSessionCardPress} />;
}
