// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FabricateStateV2EndMutationVariables = Types.Exact<{
  content: Types.ContentType;
}>;


export type FabricateStateV2EndMutation = { readonly setOuiProgress: { readonly content: Types.ContentType, readonly completion: number, readonly completed: boolean, readonly updatedAt: CustomScalars.GQLDateTime } };


export const FabricateStateV2EndDocument = gql`
    mutation FabricateStateV2End($content: ContentType!) {
  setOuiProgress(content: $content, value: 1) {
    content
    completion
    completed
    updatedAt
  }
}
    `;

/**
 * __useFabricateStateV2EndMutation__
 *
 * To run a mutation, you first call `useFabricateStateV2EndMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFabricateStateV2EndMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fabricateStateV2EndMutation, { data, loading, error }] = useFabricateStateV2EndMutation({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useFabricateStateV2EndMutation(baseOptions?: Apollo.MutationHookOptions<FabricateStateV2EndMutation, FabricateStateV2EndMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FabricateStateV2EndMutation, FabricateStateV2EndMutationVariables>(FabricateStateV2EndDocument, options);
      }
export type FabricateStateV2EndMutationHookResult = ReturnType<typeof useFabricateStateV2EndMutation>;

export function performFabricateStateV2End(client: Apollo.ApolloClient<unknown>, variables: FabricateStateV2EndMutationVariables, options?: Omit<Apollo.MutationOptions<FabricateStateV2EndMutation, FabricateStateV2EndMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<FabricateStateV2EndMutation, FabricateStateV2EndMutationVariables>({
    mutation: FabricateStateV2EndDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const FabricateStateV2EndMutationSchema = z.lazy(() => z.object({
content: Types.ContentTypeSchema
}))