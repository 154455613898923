import { Platform } from 'react-native';

// https://moti.fyi/installation#using-inline-requires
import 'react-native-reanimated';

import { activateKeepAwakeAsync } from 'expo-keep-awake';
import registerRootComponent from 'expo/build/launch/registerRootComponent';

import 'expo-asset';

import * as SplashScreen from 'expo-splash-screen';

// init amplitude
import '@src/lib/log';
import '@src/sentry';

import loadingPromise from '@src/lib/mountApp.platform';

export * from '@src/types/global';

if (__DEV__ && Platform.OS !== 'web') {
  activateKeepAwakeAsync();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mountApp(app: React.ComponentType<any>) {
  if (Platform.OS !== 'web') {
    SplashScreen.preventAutoHideAsync().catch(() => {
      // noop, prevent unused warning about splash already hidden
    });
  }
  loadingPromise.then(() => {
    registerRootComponent(app);
  });
}
