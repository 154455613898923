import { useFocusEffect, useNavigationState } from '@react-navigation/core';
import hexToRgba from 'hex-to-rgba';
import { produce } from 'immer';
import { useCallback, useLayoutEffect } from 'react';
import { SafeAreaView, ScrollView } from 'react-native';

import { Button } from '@oui/app-core/src/components/Button';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { Markdown } from '@oui/app-core/src/components/Markdown';
import { View } from '@oui/app-core/src/components/View';
import { useCurrentPatient, useProgress } from '@oui/app-core/src/hooks/useCurrentUser';
import {
  SelfPatientDocument,
  SelfPatientQuery,
  SelfPatientQueryVariables,
} from '@oui/app-core/src/hooks/useCurrentUser.graphql.generated';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { logEvent } from '@oui/app-core/src/lib/log';
import { QuizSet } from '@oui/app-core/src/screens/QuizSet';
import { useTheme } from '@oui/app-core/src/styles';
import { NumberParam } from '@oui/app-core/src/types/navigation';
import { getStaticSession } from '@oui/lib/src/getStaticSession';

import { StaticSessionCover } from '@src/components/StaticSessionCover';
import { useSetStaticSessionCompleteMutation } from '@src/screens/StaticSession.graphql.generated';
import { StaticStackScreenProps } from '@src/types/navigation';

type Props = StaticStackScreenProps<'StaticSession'>;

function ProgressBar({ completion }: { completion: number }) {
  const { theme } = useTheme();
  return (
    <View
      style={{
        height: 6,
        left: 0,
        right: 0,
        top: 0,
        position: 'absolute',
        backgroundColor: hexToRgba(theme.color.accentTwo100, 0.3),
      }}
    >
      <View
        style={{
          left: 0,
          width: `${completion}%`,
          height: '100%',
          backgroundColor: theme.color.accentTwo100,
        }}
      />
    </View>
  );
}

export function StaticSession(props: Props) {
  const [setStaticSessionComplete] = useSetStaticSessionCompleteMutation();
  const { user } = useCurrentPatient();
  const contentType = props.route.params.slug;
  const pageIndex = Number.parseInt(props.route.params.page ?? 0);
  const [, setStaticSessionPages] = usePersistedState('staticSessionCurrentPages', {});
  const navigation = props.navigation;
  const previousRoute = useNavigationState((state) => {
    const firstStaticSession = state.routes.findIndex((r) => r.name === 'StaticSession');
    if (firstStaticSession !== -1 && firstStaticSession !== 0) {
      return state.routes[firstStaticSession - 1];
    }
    return null;
  });

  useFocusEffect(
    useCallback(() => {
      setStaticSessionPages((staticSessionPages) => {
        return produce(staticSessionPages, (draft) => {
          draft[contentType] = pageIndex;
        });
      });
    }, [contentType, pageIndex, setStaticSessionPages]),
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: previousRoute
        ? ({ tintColor }) => {
            return (
              <HeaderButtons>
                <HeaderItem
                  testID="StaticSession_exitButton"
                  accessibilityLabel="Leave session"
                  iconName={props.route.params.previousScreenIcon ?? 'home'}
                  title=""
                  color={tintColor}
                  onPress={() => {
                    navigation.navigate({ key: previousRoute?.key });
                  }}
                />
              </HeaderButtons>
            );
          }
        : () => null,
    });
  }, [navigation, props.route.params, previousRoute]);

  if (!user) return null;

  const session = getStaticSession({
    contentType,
    productVariant: user.productVariant,
    productStatic: user.productStatic,
  });
  const content = session[pageIndex];
  const completion = Math.floor((pageIndex / (session.length - 1)) * 100);
  const hasNextPage = pageIndex < session.length - 1;
  const patientID = user.ID;

  async function markAsComplete() {
    setStaticSessionPages((staticSessionPages) => {
      return produce(staticSessionPages, (draft) => {
        delete draft[contentType];
      });
    });
    await setStaticSessionComplete({
      variables: { content: contentType },
      update: (cache, result) => {
        const data = cache.readQuery<ReturnType<typeof useProgress>['data']>({
          query: SelfPatientDocument,
          variables: { patientID },
        });
        const newProgress = result.data?.setOuiProgress;
        if (newProgress && data?.patientByPatientID) {
          const newData = produce(data, (draft) => {
            if (draft.patientByPatientID) {
              draft.patientByPatientID.progress = [
                ...draft.patientByPatientID.progress.filter((p) => p.content !== contentType),
                newProgress,
              ];
            }
          });
          cache.writeQuery<SelfPatientQuery, SelfPatientQueryVariables>({
            query: SelfPatientDocument,
            variables: { patientID },
            data: newData,
          });
        }
      },
    }).then(() => logEvent(`session_completed`, { session: contentType }));
  }

  async function onNext() {
    const newPage = pageIndex + 1;
    props.navigation.push('StaticSession', {
      ...props.route.params,
      page: newPage.toString() as NumberParam,
    });
  }

  if (typeof content !== 'string') {
    switch (content.type) {
      case 'QuizSet':
        return <QuizSet slug={content.slug} onDone={onNext} />;
      case 'SessionCover':
        return (
          <StaticSessionCover
            title={content.title}
            onBack={props.navigation.goBack}
            onNext={onNext}
          />
        );
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingHorizontal: 40, paddingVertical: 20 }}
        testID={`StaticSession_${pageIndex}`}
      >
        <Markdown>{content}</Markdown>
        <View row style={{ marginTop: 20, justifyContent: 'flex-end' }}>
          {hasNextPage ? (
            <Button testID="StaticSession_nextButton" text="Next" onPress={onNext} />
          ) : (
            <Button
              testID="StaticSession_finishButton"
              text="Finish"
              onPress={async () => {
                await markAsComplete();
                if (previousRoute) {
                  props.navigation.navigate({ key: previousRoute.key });
                } else {
                  props.navigation.goBack();
                }
              }}
            />
          )}
        </View>
      </ScrollView>
      <ProgressBar completion={completion} />
    </SafeAreaView>
  );
}
