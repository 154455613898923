import color from 'color';
import hexToRgba from 'hex-to-rgba';
import { ComponentProps } from 'react';
import { TouchableOpacity } from 'react-native';

import { Icon } from '@src/components/Icon';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useI18n } from '@src/lib/i18n';
import { Color, Shadow } from '@src/styles';

export function PracticeTile(props: {
  testID: string;
  title: string;
  description: string;
  color: string;
  icon: ComponentProps<typeof Icon>['name'];
  onPress: () => void;
  lockedUntil?: number;
  practiceCount: number | undefined;
}) {
  const isDark = color(props.color).darken(0.1).isDark();
  const lockedUntil = props.lockedUntil;
  const textColor = lockedUntil ? hexToRgba(Color.text, 0.7) : isDark ? 'white' : '#104f51';
  const { $t } = useI18n();
  const completedText = $t(
    { id: 'PracticeTile_practiceCount', defaultMessage: '{count} done' },
    { count: props.practiceCount ?? 0 },
  );

  return (
    <TouchableOpacity
      testID={props.testID}
      onPress={props.onPress}
      disabled={!!lockedUntil}
      accessibilityLabel={`${props.title}. ${props.description}. ${
        lockedUntil ? $t({ id: 'PracticeTile_locked', defaultMessage: 'locked' }) : completedText
      }`}
      accessibilityRole="button"
      accessibilityState={
        lockedUntil
          ? {
              disabled: true,
            }
          : undefined
      }
      style={[
        lockedUntil
          ? { backgroundColor: '#efeff4' }
          : [Shadow.high, { backgroundColor: props.color }],
        { borderRadius: 20, padding: 16, flex: 1 },
      ]}
    >
      <View
        row
        style={{
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <View
          style={{
            width: 35,
            height: 35,
            backgroundColor: 'rgba(255,255,255,0.3)',
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon name={props.icon} color={textColor} />
        </View>
        {lockedUntil ? <Icon name="lock" color={textColor} size={20} /> : null}
      </View>
      <View style={{ flexGrow: 1 }} spacing={8}>
        <Text text={props.title} size={17} weight="semibold" color={textColor} />
        {props.description ? <Text text={props.description} size={13} color={textColor} /> : null}
      </View>
      {lockedUntil ? (
        <View
          style={{
            marginTop: 20,
          }}
        >
          <Text
            text={$t(
              { id: 'PracticeTile_lockedUntilHint', defaultMessage: 'Finish session {sessionNum}' },
              { sessionNum: lockedUntil },
            )}
            weight="semibold"
            size={13}
          />
        </View>
      ) : (
        <View
          style={{
            backgroundColor: 'rgba(255,255,255,0.3)',
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'flex-start',
            paddingHorizontal: 14,
            paddingVertical: 2,
            marginTop: 20,
          }}
        >
          <Text
            text={completedText}
            testID="PracticeTile_practiceCount"
            weight="semibold"
            size={13}
            color={textColor}
          />
        </View>
      )}
    </TouchableOpacity>
  );
}
