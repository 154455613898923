import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AccessibilityInfo, Keyboard, Platform } from 'react-native';

import { isCaptionServiceEnabledAsync } from '@oui/native-utils';

import { useAppState } from '@src/hooks/useAppState';

const AccessibilityContext = createContext({
  isCaptionServiceEnabled: false,
  isKeyboardShowing: false,
  isScreenReaderEnabled: false,
  isAnnouncingIOS: false,
  announceForAccessibility: (announcement: string) => {},
});

export function useAccessibilityContext() {
  return useContext(AccessibilityContext);
}

export function AccessibilityProvider(props: { children?: ReactNode }) {
  const [isScreenReaderEnabled, setIsScreenReaderEnabled] = useState(false);
  const [isKeyboardShowing, setIsKeyboardShowing] = useState(false);
  const [isCaptionServiceEnabled, setIsCaptionServiceEnabled] = useState(false);
  const [isAnnouncingIOS, setIsAnnouncingIOS] = useState(false);

  // iOS interrupts when calling announceForAccessibility (vs android which queues announcments
  // to be read one after the other. To workaround, we set state when we are announcing and wait for
  // it to finish before sending more announcements to the system
  const announceForAccessibility = useCallback(
    (announcement: string) => {
      if (Platform.OS === 'ios' && isScreenReaderEnabled) {
        setIsAnnouncingIOS(true);
      }
      AccessibilityInfo.announceForAccessibility(announcement);
    },
    [isScreenReaderEnabled],
  );

  useEffect(() => {
    AccessibilityInfo.isScreenReaderEnabled().then((enabled) => {
      setIsScreenReaderEnabled(enabled && Platform.OS !== 'web');
    });
    const screenReaderListener = AccessibilityInfo.addEventListener(
      'screenReaderChanged',
      setIsScreenReaderEnabled,
    );
    const announcementListener = AccessibilityInfo.addEventListener('announcementFinished', () => {
      setIsAnnouncingIOS(false);
    });
    isCaptionServiceEnabledAsync().then(setIsCaptionServiceEnabled);

    function didShow() {
      setIsKeyboardShowing(true);
    }
    function didHide() {
      setIsKeyboardShowing(false);
    }

    const showListener = Keyboard.addListener('keyboardDidShow', didShow);
    const hideListener = Keyboard.addListener('keyboardDidHide', didHide);

    return () => {
      screenReaderListener.remove();
      showListener.remove();
      hideListener.remove();
      announcementListener.remove();
    };
  }, []);

  useAppState((appState) => {
    if (appState === 'active') {
      isCaptionServiceEnabledAsync().then(setIsCaptionServiceEnabled);
    }
  });

  const value = useMemo(() => {
    return {
      isCaptionServiceEnabled,
      isKeyboardShowing,
      isScreenReaderEnabled,
      isAnnouncingIOS,
      announceForAccessibility,
    };
  }, [
    isCaptionServiceEnabled,
    isKeyboardShowing,
    isScreenReaderEnabled,
    isAnnouncingIOS,
    announceForAccessibility,
  ]);

  return (
    <AccessibilityContext.Provider value={value}>{props.children}</AccessibilityContext.Provider>
  );
}
