import { Component, ComponentProps, ReactNode, Ref, RefCallback, useCallback, useRef } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { useAccessibilityContext } from '@src/components/AccessibilityContext';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useTheme } from '@src/styles';

type AccessibleInputChildProps<T extends Component> = {
  accessibilityLabel: string;
  ref: RefCallback<T>;
  placeholder?: string;
};

export function useAccessibleInput<T extends Component>({
  error,
  label,
  placeholder,
  ref,
}: {
  error?: string;
  label?: string;
  placeholder?: string;
  ref?: Ref<T | null | undefined>;
}) {
  const innerRef = useRef<T | null | undefined>();
  const { isScreenReaderEnabled } = useAccessibilityContext();

  const refCallback = useCallback(
    (r: T | null) => {
      innerRef.current = r;
      if (ref) {
        if (typeof ref === 'function') {
          ref(r);
        } else if (ref) {
          (ref as any).current = r;
        }
      }
    },
    [ref],
  );

  return {
    accessibilityLabel: label?.includes('*')
      ? `${label.replace(/\*/g, '')}. required. ${error ? error : ''}`
      : `${label || placeholder}. ${error ? `${error}.` : ''}`,
    ref: refCallback,
    // https://github.com/facebook/react-native/issues/26739
    placeholder: isScreenReaderEnabled && label && placeholder !== label ? undefined : placeholder,
  };
}

type AccessibleInputProps<T extends Component> = {
  children: (accessibilityProps: AccessibleInputChildProps<T>) => ReactNode;
  error?: string;
  forwardRef?: Ref<T | null | undefined>;
  hint?: string;
  placeholder?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  labelColor?: string;
  labelSize?: number;
  labelHorizontal?: boolean | 'small';
  labelLineHeight?: number;
  labelAlign?: ComponentProps<typeof Text>['textAlign'];
  labelWeight?: ComponentProps<typeof Text>['weight'];
} & (
  | {
      label?: string;
      accessibilityLabel?: string;
    }
  | {
      label?: () => ReactNode;
      accessibilityLabel: string;
    }
);

export function AccessibleInput<T extends Component>({
  accessibilityLabel,
  children,
  error,
  forwardRef,
  hint,
  label,
  labelColor,
  labelSize,
  labelLineHeight,
  labelAlign,
  labelWeight,
  labelHorizontal,
  placeholder,
  style,
  testID,
}: AccessibleInputProps<T>) {
  const { theme } = useTheme();

  const accessible = useAccessibleInput({
    error,
    label: accessibilityLabel ?? (typeof label === 'string' ? label : ''),
    placeholder,
    ref: forwardRef,
  });

  if (labelHorizontal === 'small') {
    labelWeight = theme.typography.small.weight;
    labelColor = theme.typography.small.color ?? theme.color.gray400;
    labelSize = theme.typography.small.size;
  }

  return (
    <View style={style}>
      <View row={!!labelHorizontal}>
        {label && typeof label === 'string' ? (
          <Text
            accessibilityRole="none"
            text=""
            style={[labelHorizontal ? { marginRight: 10 } : { marginLeft: 16, marginBottom: 5 }]}
            weight={labelWeight ?? theme.typography.label.weight}
            color={labelColor ?? theme.typography.label.color}
            size={labelSize ?? theme.typography.label.size}
            lineHeight={labelLineHeight ?? theme.typography.label.lineHeight}
            textAlign={labelAlign}
          >
            {label.replace(/\*$/, '')}
            {label.endsWith('*') ? (
              <Text text=" *" color={theme.color.danger} weight="semibold" />
            ) : null}
          </Text>
        ) : typeof label === 'function' ? (
          <View
            style={{ marginLeft: 16 }}
            importantForAccessibility="no-hide-descendants"
            accessibilityElementsHidden
          >
            {label()}
          </View>
        ) : null}
        {children?.(accessible)}
      </View>
      {error ? (
        <Text
          accessibilityRole="none"
          text={error}
          style={{ marginLeft: 16, marginTop: 5 }}
          color={theme.color.danger}
          testID={`${testID}_error`}
        />
      ) : hint ? (
        <Text
          accessibilityRole="none"
          text={hint}
          color={theme.color.gray400}
          size={13}
          style={{ marginLeft: 16, marginTop: 5 }}
          testID={`${testID}_hint`}
        />
      ) : null}
    </View>
  );
}
