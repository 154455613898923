import { getDefaultHeaderHeight as _getDefaultHeaderHeight } from '@react-navigation/elements';
import { Platform } from 'react-native';
import { useSafeAreaFrame, useSafeAreaInsets } from 'react-native-safe-area-context';

export const getDefaultHeaderHeight: typeof _getDefaultHeaderHeight = (
  layout,
  modalPresentation,
  statusBarHeight,
) => {
  const defaultHeight = _getDefaultHeaderHeight(layout, modalPresentation, statusBarHeight);

  if (Platform.OS === 'ios' && !Platform.isPad && !modalPresentation) {
    // we want to accomodate our normal size Button in headers on portrait and landscape on iOS
    return Math.max(50 + statusBarHeight, defaultHeight);
  }

  return defaultHeight;
};

export function useDefaultHeaderHeight() {
  const dimensions = useSafeAreaFrame();
  const insets = useSafeAreaInsets();
  return getDefaultHeaderHeight(dimensions, false, insets.top);
}
