import {
  ApolloCache,
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  Observable,
  Operation,
  UriFunction,
  split,
} from '@apollo/client';
import { ErrorLink, onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { invariant } from 'ts-invariant';

import 'cross-fetch/polyfill';

import * as Sentry from '@sentry/core';

export { HttpLink };

export interface PresetConfig {
  connectToDevTools?: boolean;
  request?: (operation: Operation) => Promise<void> | void;
  uri?: string | UriFunction;
  onError?: ErrorLink.ErrorHandler;
  cache?: ApolloCache<any>;
  defaultOptions?: DefaultOptions;
  preHttpLink?: ApolloLink;
  subscriptionOptions?: {
    uri: string;
    connectionParams: () => Promise<{ Authorization: string | undefined }>;
  };
  headers?: Record<string, string | undefined>;
  isProduction: boolean;
  baseClass?: typeof ApolloClient;
}

export function makeClient<TCache>(config: PresetConfig): ApolloClient<TCache> {
  const {
    connectToDevTools,
    request,
    uri,
    onError: errorCallback,
    defaultOptions,
    preHttpLink,
    subscriptionOptions,
    headers,
    isProduction,
    baseClass,
  } = config;

  // https://github.com/Haegin/apollo-sentry-link/blob/master/src/index.js
  const operationInfo = (operation: Operation) => {
    const now = Math.floor(Date.now() / 1000);
    const requestID = operation.getContext().headers['X-RequestID'];
    const hasBearerToken = !!operation.getContext().headers['authorization'];
    const variables = isProduction ? undefined : JSON.stringify(operation.variables ?? {});

    return {
      requestID,
      type: (operation.query.definitions.find((defn: any) => defn.operation)! as any).operation,
      name: operation.operationName,
      dateNow: now,
      hasBearerToken,
      variables,
    };
  };

  let { cache } = config;

  if (!cache) {
    cache = new InMemoryCache();
  }

  const errorLink = errorCallback
    ? onError(errorCallback)
    : onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            // tslint:disable-next-line
            invariant.warn(
              `[GraphQL error]: Message: ${message}, Location: ` + `${locations}, Path: ${path}`,
            ),
          );
        }
        if (networkError) {
          // tslint:disable-next-line
          invariant.warn(`[Network error]: ${networkError}`);
        }
      });

  const requestHandler = request
    ? new ApolloLink(
        (operation, forward) =>
          new Observable((observer) => {
            let handle: any;
            Promise.resolve(operation)
              .then((oper) => request(oper))
              .then(() => {
                handle = forward!(operation).subscribe({
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                });
              })
              .catch(observer.error.bind(observer));

            return () => {
              if (handle) {
                handle.unsubscribe();
              }
            };
          }),
      )
    : false;

  let httpLink: ApolloLink = new HttpLink({
    uri: uri || '/graphql',
    fetch,
    fetchOptions: {},
    credentials: 'same-origin',
    headers: (headers as Record<string, string>) ?? {},
  });

  if (
    subscriptionOptions &&
    // don't use subscriptions in SSR
    global.window
  ) {
    const wsLink = new GraphQLWsLink(
      createClient<{ Authorization: string | undefined }>({
        url: subscriptionOptions.uri,
        lazy: true,
        lazyCloseTimeout: 30000,
        connectionParams: subscriptionOptions.connectionParams,
        shouldRetry: () => true,
      }),
    );

    httpLink = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      preHttpLink ? ApolloLink.from([preHttpLink, httpLink]) : httpLink,
    );
  }

  // https://github.com/Haegin/apollo-sentry-link/blob/master/src/index.js
  const sentryLink = new ApolloLink((operation, forward) => {
    Sentry.addBreadcrumb({
      type: 'http',
      category: 'graphql',
      data: operationInfo(operation),
      level: 'debug',
    });
    return forward!(operation);
  });

  const retryLink = new RetryLink({
    attempts: {
      max: 3,
    },
  });

  const link = ApolloLink.from(
    [errorLink, retryLink, requestHandler, sentryLink, httpLink].filter((x) => !!x) as ApolloLink[],
  );

  const _parentClass = baseClass ?? ApolloClient;
  return new _parentClass({
    connectToDevTools,
    cache,
    link,
    defaultOptions,
  });
}
