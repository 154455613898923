// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompletedQuizSetsQueryVariables = Types.Exact<{
  input: Types.CompletedQuizSetsForPatientInput;
}>;


export type CompletedQuizSetsQuery = { readonly completedQuizSetsForPatient: { readonly patientID: CustomScalars.GQLUUID, readonly completedQuizSets: ReadonlyArray<{ readonly __typename: 'CompletedPatientQuizSet', readonly quizSetID: string, readonly slug: string }> } };

export type CompleteQuizSetMutationVariables = Types.Exact<{
  input: Types.CompleteQuizSetForPatientInput;
}>;


export type CompleteQuizSetMutation = { readonly completeQuizSetForPatient: { readonly patientID: CustomScalars.GQLUUID, readonly completedQuizSet: { readonly __typename: 'CompletedPatientQuizSet', readonly quizSetID: string, readonly slug: string } } };

export type QuizSetCollectionQueryVariables = Types.Exact<{
  quizSetCollectionID: Types.Scalars['UUID']['input'];
}>;


export type QuizSetCollectionQuery = { readonly quizSetCollectionByID: { readonly sets: ReadonlyArray<{ readonly __typename: 'QuizSet', readonly quizSetID: string, readonly ID: string, readonly name: string, readonly description: string, readonly previewTime?: number | null, readonly previewOptions?: { readonly time?: number | null, readonly height?: number | null, readonly fitMode?: Types.MuxFitMode | null } | null, readonly items: ReadonlyArray<{ readonly __typename: 'LegacyQuizSetItemMultipleChoiceQuestion', readonly props: { readonly label: CustomScalars.GQLQuizSetItemQuestionLabel }, readonly dir: { readonly feedback: CustomScalars.GQLQuizSetItemQuestionDirectiveFeedback, readonly prompt: string, readonly afterAll?: string | null } } | { readonly __typename: 'LegacyQuizSetItemSingleChoiceQuestion', readonly props: { readonly label: CustomScalars.GQLQuizSetItemQuestionLabel }, readonly dir: { readonly feedback: CustomScalars.GQLQuizSetItemQuestionDirectiveFeedback, readonly prompt: string, readonly afterAll?: string | null } } | { readonly __typename: 'LegacyQuizSetItemVideo', readonly props: { readonly url: string, readonly title?: string | null, readonly compulsory?: boolean | null } }> }> } };


export const CompletedQuizSetsDocument = gql`
    query CompletedQuizSets($input: CompletedQuizSetsForPatientInput!) {
  completedQuizSetsForPatient(input: $input) {
    patientID
    completedQuizSets {
      __typename
      quizSetID
      slug
    }
  }
}
    `;

/**
 * __useCompletedQuizSetsQuery__
 *
 * To run a query within a React component, call `useCompletedQuizSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedQuizSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedQuizSetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletedQuizSetsQuery(baseOptions: Apollo.QueryHookOptions<CompletedQuizSetsQuery, CompletedQuizSetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompletedQuizSetsQuery, CompletedQuizSetsQueryVariables>(CompletedQuizSetsDocument, options);
      }
export function useCompletedQuizSetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedQuizSetsQuery, CompletedQuizSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompletedQuizSetsQuery, CompletedQuizSetsQueryVariables>(CompletedQuizSetsDocument, options);
        }
export type CompletedQuizSetsQueryHookResult = ReturnType<typeof useCompletedQuizSetsQuery>;
export type CompletedQuizSetsLazyQueryHookResult = ReturnType<typeof useCompletedQuizSetsLazyQuery>;

export function queryCompletedQuizSets(client: Apollo.ApolloClient<unknown>, variables: CompletedQuizSetsQueryVariables, options?: Omit<Apollo.QueryOptions<CompletedQuizSetsQuery, CompletedQuizSetsQueryVariables>, "query" | 'variables'>) {
  return client.query<CompletedQuizSetsQuery, CompletedQuizSetsQueryVariables>({
    query: CompletedQuizSetsDocument,
    variables,
    ...options,
  });
}

export const CompleteQuizSetDocument = gql`
    mutation CompleteQuizSet($input: CompleteQuizSetForPatientInput!) {
  completeQuizSetForPatient(input: $input) {
    patientID
    completedQuizSet {
      __typename
      quizSetID
      slug
    }
  }
}
    `;

/**
 * __useCompleteQuizSetMutation__
 *
 * To run a mutation, you first call `useCompleteQuizSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteQuizSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeQuizSetMutation, { data, loading, error }] = useCompleteQuizSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteQuizSetMutation(baseOptions?: Apollo.MutationHookOptions<CompleteQuizSetMutation, CompleteQuizSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteQuizSetMutation, CompleteQuizSetMutationVariables>(CompleteQuizSetDocument, options);
      }
export type CompleteQuizSetMutationHookResult = ReturnType<typeof useCompleteQuizSetMutation>;

export function performCompleteQuizSet(client: Apollo.ApolloClient<unknown>, variables: CompleteQuizSetMutationVariables, options?: Omit<Apollo.MutationOptions<CompleteQuizSetMutation, CompleteQuizSetMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<CompleteQuizSetMutation, CompleteQuizSetMutationVariables>({
    mutation: CompleteQuizSetDocument,
    variables,
    ...options,
  });
}

export const QuizSetCollectionDocument = gql`
    query QuizSetCollection($quizSetCollectionID: UUID!) {
  quizSetCollectionByID(quizSetCollectionID: $quizSetCollectionID) {
    sets {
      __typename
      quizSetID
      ID
      name
      description
      previewTime
      previewOptions {
        time
        height
        fitMode
      }
      items: legacyItems {
        __typename
        ... on LegacyQuizSetItemVideo {
          props {
            url
            title
            compulsory
          }
        }
        ... on LegacyQuizSetItemMultipleChoiceQuestion {
          props {
            label
          }
          dir {
            feedback
            prompt
            afterAll
          }
        }
        ... on LegacyQuizSetItemSingleChoiceQuestion {
          props {
            label
          }
          dir {
            feedback
            prompt
            afterAll
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQuizSetCollectionQuery__
 *
 * To run a query within a React component, call `useQuizSetCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizSetCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizSetCollectionQuery({
 *   variables: {
 *      quizSetCollectionID: // value for 'quizSetCollectionID'
 *   },
 * });
 */
export function useQuizSetCollectionQuery(baseOptions: Apollo.QueryHookOptions<QuizSetCollectionQuery, QuizSetCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuizSetCollectionQuery, QuizSetCollectionQueryVariables>(QuizSetCollectionDocument, options);
      }
export function useQuizSetCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizSetCollectionQuery, QuizSetCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuizSetCollectionQuery, QuizSetCollectionQueryVariables>(QuizSetCollectionDocument, options);
        }
export type QuizSetCollectionQueryHookResult = ReturnType<typeof useQuizSetCollectionQuery>;
export type QuizSetCollectionLazyQueryHookResult = ReturnType<typeof useQuizSetCollectionLazyQuery>;

export function queryQuizSetCollection(client: Apollo.ApolloClient<unknown>, variables: QuizSetCollectionQueryVariables, options?: Omit<Apollo.QueryOptions<QuizSetCollectionQuery, QuizSetCollectionQueryVariables>, "query" | 'variables'>) {
  return client.query<QuizSetCollectionQuery, QuizSetCollectionQueryVariables>({
    query: QuizSetCollectionDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const CompleteQuizSetMutationSchema = z.lazy(() => z.object({
input: Types.CompleteQuizSetForPatientInputSchema
}))