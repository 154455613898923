import { ApolloClient } from '@apollo/client';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';

import { getInstallationIDAsync } from '@src/lib/getInstallationIDAsync';
import { performSetPushToken } from '@src/lib/setDeviceInfo.graphql.generated';
import Sentry from '@src/sentry';

export async function setDeviceInfo(client: ApolloClient<unknown>) {
  const { status } =
    Platform.OS === 'web' || !Constants.isDevice
      ? { status: 'denied' } // currently don't support notifications on web
      : await Notifications.getPermissionsAsync();
  const installationID = await getInstallationIDAsync();

  let expoPushToken: Notifications.ExpoPushToken | undefined;
  let platformPushToken: string | undefined;
  if (status === 'granted') {
    expoPushToken = await Notifications.getExpoPushTokenAsync({
      projectId: Constants.expoConfig?.extra?.eas.projectId,
    });
    try {
      platformPushToken = (await Notifications.getDevicePushTokenAsync()).data;
    } catch (e) {}
  }

  performSetPushToken(client, {
    deviceID: installationID,
    platform: Platform.OS,
    expoPushToken: expoPushToken?.data,
    platformPushToken,
  }).catch((e) => Sentry.captureException(e));
}
