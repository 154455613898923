import { ApolloClient, ApolloProvider, useApolloClient } from '@apollo/client';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  DarkTheme,
  DefaultTheme,
  InitialState,
  CommonActions as NavigationActions,
  NavigationContainer,
  NavigationContainerRef,
  NavigationState,
  getActionFromState,
  getPathFromState,
  getStateFromPath,
} from '@react-navigation/native';
import { applicationId } from 'expo-application';
import { locale } from 'expo-localization';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import { produce } from 'immer';
import { stringify } from 'query-string';
import {
  Component,
  ComponentProps,
  ElementType,
  FunctionComponent,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { AppState, Platform, Linking as RNLinking, StatusBar } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Menu, MenuProvider, renderers } from 'react-native-popup-menu';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { SvgProps } from 'react-native-svg';

import { DetoxNotificationData } from '@oui/lib/src/types';
import { clearFrescoMemoryCache } from '@oui/native-utils';

import { AccessibilityProvider } from '@src/components/AccessibilityContext';
import AppContext, { DeeplinkOptions } from '@src/components/AppContext';
import { AppError } from '@src/components/AppError';
import { Button } from '@src/components/Button';
import { CurrentUserProvider } from '@src/components/CurrentUserContext';
import { DevMenu } from '@src/components/DevMenu';
import { Icon } from '@src/components/Icon';
import { InternetConnectivityProvider } from '@src/components/InternetConnectivityProvider';
import { MinimumAppVersion } from '@src/components/MinimumAppVersion';
import { OrientationProvider } from '@src/components/OrientationContext';
import { SnackbarProvider } from '@src/components/SnackbarProvider';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { APP_SLUG, manifest } from '@src/constants';
import { useAppState } from '@src/hooks/useAppState';
import { CurrentUserQuery, queryCurrentUser } from '@src/hooks/useCurrentUser.graphql.generated';
import { LogoutProvider } from '@src/hooks/useLogout';
import { setPersistedState } from '@src/hooks/usePersistedState';
import LogBox from '@src/lib/LogBox';
import { checkForUpdateAsync } from '@src/lib/checkForUpdateAsync';
import { I18nProvider } from '@src/lib/i18n';
import { initApp, shouldShowWebBlocker } from '@src/lib/initApp';
import { addBreadcrumb } from '@src/lib/log';
import { parseUrl } from '@src/lib/parseUrl';
import { PermissionsManagerProvider } from '@src/lib/permissionsManager';
import { resumableUploadManager } from '@src/lib/resumableUploadManager';
import { setDeviceInfo } from '@src/lib/setDeviceInfo';
import Sentry, { routingInstrumentation } from '@src/sentry';
import { Shadow, useTheme } from '@src/styles';
import { AuthParamList, DeeplinkConfigShape } from '@src/types/navigation';

import { Reauthenticate } from './Reauthenticate';

const LATEST_NAVIGATION_STATE_KEY = `${APP_SLUG}:lastNavigationState`;

Menu.setDefaultRenderer(renderers.Popover);
Menu.setDefaultRendererProps({ placement: 'bottom', anchorStyle: [Shadow.default] });
DefaultTheme.colors.background = 'white';
LogBox.ignoreLogs([
  'Accessing view manager configs',
  'componentWillMount',
  'componentWillReceiveProps',
  '[react-native-gesture-handler] Seems', // https://github.com/software-mansion/react-native-gesture-handler/pull/1817
]);

const AUTH_DEEPLINK_CONFIG: DeeplinkConfigShape<keyof AuthParamList> = {
  Login: 'auth/login',
  RequestResetPassword: 'auth/requestreset',
  ResetPassword: 'auth/reset',
  SignUp: 'auth/signup',
};

function useDetoxSideEffects() {
  const [forceOffline, setForceOffline] = useState(false);
  useEffect(() => {
    function handleDetoxNotification(data: DetoxNotificationData) {
      if (data.type === 'NETWORK') {
        setForceOffline(!data.payload.isInternetReachable);
      } else if (data.type === 'ASYNC_STORAGE') {
        AsyncStorage.setItem(data.payload.key, data.payload.value);
      }
    }

    const handleOpenURL = ({ url }: { url: string }) => {
      const parsedUrl = parseUrl(url);
      if (global.e2e && parsedUrl?.path === 'DETOX_NOTIFICATION') {
        const data: DetoxNotificationData = JSON.parse(parsedUrl.params.data);
        handleDetoxNotification(data);
        return;
      }

      if (parsedUrl?.params.skipLocalAuthenticationPrompt) {
        setPersistedState(
          'SeenLocalAuthenticationPrompt',
          JSON.parse(parsedUrl.params.skipLocalAuthenticationPrompt),
        );
      }
    };

    if (Platform.OS === 'web') {
      // @ts-ignore
      global.window.__detoxNotificationCallback = (data: string) => {
        handleDetoxNotification(JSON.parse(data) as DetoxNotificationData);
      };
    } else {
      const urlSubscription = RNLinking.addEventListener('url', handleOpenURL);

      return () => {
        urlSubscription.remove();
      };
    }

    return;
  }, []);
  return { forceOffline };
}

function useLowMemoryWarning() {
  useEffect(() => {
    if (Platform.OS === 'web') return;

    const onMemoryWarningListener = AppState.addEventListener('memoryWarning', (data) => {
      addBreadcrumb({ category: 'app-state-memory', data: { data }, level: 'debug' });
      clearFrescoMemoryCache();
    });

    return () => {
      onMemoryWarningListener?.remove();
    };
  }, []);
}

type AppInnerProps = {
  app: ElementType;
  initialPath: string | ((data: { ouiUser: CurrentUserQuery['currentUser'] }) => string);
  deeplinkConfig: { screens: DeeplinkOptions };
};
const AppContainerInner = forwardRef<NavigationContainerRef<{}>, AppInnerProps>((props, ref) => {
  const [initialNavigationState, setInitialNavigationState] = useState<InitialState>();
  const { flags } = useContext(AppContext);
  const { scheme } = useTheme();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigator = useRef<NavigationContainerRef<{}>>();
  const [navigatorState, setNavigatorState] = useState<NavigationContainerRef<{}> | null>(null);
  const handleNotificationRef = useRef<typeof handleNotification>();
  const Main = props.app;
  const deeplinkConfigRef = useRef<{ screens: DeeplinkOptions }>(props.deeplinkConfig);
  const latestStateRef = useRef<NavigationState>();
  const apollo = useApolloClient();
  const initialPath = props.initialPath;
  const [reauthenticateCallback, setReauthenticateCallback] = useState<{
    callback?: Function;
  }>({ callback: undefined });

  navigator.current = navigatorState ?? undefined;
  useImperativeHandle(ref, () => navigatorState!, [navigatorState]);

  const handleNotification = useCallback(
    (notification: Notifications.NotificationResponse, skipLoadingCheck = false): boolean => {
      // console.log({
      //   notification: JSON.stringify(notification, null, 2),
      //   skipLoadingCheck,
      //   navigator: !!navigator.current,
      //   loading,
      // });
      if (!skipLoadingCheck && loading) {
        return false;
      }

      addBreadcrumb({ category: 'notification', data: notification });
      Notifications.dismissNotificationAsync(notification.notification.request.identifier);
      const content = notification.notification.request.content;
      const data = content.data as AppCore.NotificationPayload;
      if (data.type === 'navigate') {
        const payload = {
          name: data.payload.name ?? data.payload.routeName,
          params: data.payload.params,
        };
        const paramsStr = payload.params ? stringify(payload.params) : '';
        const path = `${payload.name}${paramsStr ? `?${paramsStr}` : ''}`;
        const state = getStateFromPath(path, deeplinkConfigRef.current)!;
        if (navigator.current) {
          navigator.current?.dispatch(getActionFromState(state)!);
        } else {
          setInitialNavigationState(state);
        }
        return true;
      }
      return false;
    },
    [loading],
  );
  handleNotificationRef.current = handleNotification;

  useAppState((status) => {
    addBreadcrumb({ category: 'app-state', data: { status }, level: 'debug' });
  });

  useEffect(() => {
    resumableUploadManager.startPendingUploads();
  }, []);

  const { forceOffline } = useDetoxSideEffects();
  useLowMemoryWarning();

  useEffect(() => {
    if (Platform.OS === 'web') return;

    const handleOpenURL = ({ url }: { url: string }) => {
      const parsedUrl = parseUrl(url);
      const initialURLState = parsedUrl?.path
        ? getStateFromPath(parsedUrl.pathWithQuery, deeplinkConfigRef.current)
        : undefined;

      addBreadcrumb({
        category: 'navigation',
        message: 'handleOpenURL',
        data: { url, parsedUrl, initialURLState },
      });
    };

    const handleNotificationSubscription =
      Notifications.addNotificationResponseReceivedListener(handleNotification);
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
      }),
    });
    const urlSubscription = RNLinking.addEventListener('url', handleOpenURL);

    return () => {
      urlSubscription.remove();
      if (handleNotificationSubscription) {
        handleNotificationSubscription.remove();
      }
      Notifications.setNotificationHandler(null);
    };
  }, [handleNotification]);

  const init = useCallback(async () => {
    try {
      const initialURL = await RNLinking.getInitialURL();
      const { isLoggedIn, reauthenticate } = await initApp(apollo);
      let productUser: CurrentUserQuery['currentUser'] | null = null;

      if (isLoggedIn) {
        try {
          const r = await queryCurrentUser(apollo, undefined, {
            fetchPolicy: 'cache-first',
          });
          productUser = r.data.currentUser;
        } catch (e) {
          Sentry.captureException(e, (scope) => {
            scope.setExtras({ location: 'preload productUser' });
            return scope;
          });
        }
        setDeviceInfo(apollo);
      }

      const parsedUrl = parseUrl(initialURL);
      const initialURLState = parsedUrl?.path
        ? getStateFromPath(parsedUrl.pathWithQuery, deeplinkConfigRef.current)
        : undefined;

      Sentry.addBreadcrumb({
        message: 'init',
        data: {
          reauthenticate,
          initialURLState,
          parsedUrl,
          initialURL,
        },
      });

      const primaryNavigation = async () => {
        // react-navigation handles the initial url on web by default so if we're planning to
        // set initial state to the initial url ourselves, just mark the action as already handled
        // https://reactnavigation.org/docs/navigation-container/#initialstate
        let handled = false;
        if (Platform.OS === 'web' && parsedUrl?.isDeeplink) {
          handled = true;
        } else if (Platform.OS !== 'web') {
          const initialNotification = await Notifications.getLastNotificationResponseAsync();
          if (initialNotification) {
            handled = handled || handleNotificationRef.current!(initialNotification, true);
          }
        }

        if (!handled) {
          const getPrimaryNavigationState = async (): Promise<InitialState> => {
            const persistedStateStr = await AsyncStorage.getItem(LATEST_NAVIGATION_STATE_KEY);

            if (persistedStateStr) {
              AsyncStorage.removeItem(LATEST_NAVIGATION_STATE_KEY);
              const persistedState: NavigationState = JSON.parse(persistedStateStr);
              return persistedState;
            } else if (initialURLState) {
              return initialURLState;
            } else if (isLoggedIn && shouldShowWebBlocker()) {
              return getStateFromPath('WebBlocker', deeplinkConfigRef.current)!;
            }

            return getStateFromPath(
              typeof initialPath === 'function'
                ? initialPath({ ouiUser: productUser })
                : initialPath,
              deeplinkConfigRef.current,
            )!;
          };

          setInitialNavigationState((await getPrimaryNavigationState())!);
        }
      };

      // if existing credentials, go to App Home / deeplink
      // if previously logged in, but currently logged out show Login instead of Welcome
      // if app was opened normally and no user present, show "Welcome"
      if (reauthenticate) {
        setReauthenticateCallback({ callback: primaryNavigation });
      } else {
        await primaryNavigation();
      }

      setLoading(false);
      setError(false);
    } catch (e) {
      Sentry.captureException(e);
      setError(true);
    } finally {
      if (Platform.OS !== 'web') {
        SplashScreen.hideAsync();
      }
    }
  }, [initialPath, apollo]);

  useEffect(() => {
    init();
  }, [init]);

  return reauthenticateCallback.callback ? (
    <Reauthenticate
      onSuccess={async () => {
        await reauthenticateCallback.callback?.();
        setReauthenticateCallback({ callback: undefined });
      }}
      onLogout={async (forgotPassword) => {
        setLoading(true);
        setReauthenticateCallback({ callback: undefined });
        await init();
        if (forgotPassword) {
          setTimeout(() => {
            // @ts-expect-error
            navigator.current?.navigate('RequestResetPassword', {});
          }, 100);
        }
      }}
    />
  ) : error ? (
    <AppError retry={init} />
  ) : loading ? null : (
    <>
      <StatusBar
        barStyle={flags.allowDarkTheme && scheme === 'dark' ? 'light-content' : 'dark-content'}
        translucent
        backgroundColor="#00000000"
      />
      <PermissionsManagerProvider
        onOpenSettings={() => {
          if (!latestStateRef.current) return Promise.resolve();
          return AsyncStorage.setItem(
            LATEST_NAVIGATION_STATE_KEY,
            JSON.stringify(latestStateRef.current),
          );
        }}
        onCloseSettings={() => {
          return AsyncStorage.removeItem(LATEST_NAVIGATION_STATE_KEY);
        }}
      >
        <InternetConnectivityProvider
          forceOffline={forceOffline}
          onReconnect={() => {
            resumableUploadManager.startPendingUploads();
          }}
        >
          <MinimumAppVersion>
            <SnackbarProvider>
              <NavigationContainer
                linking={{
                  prefixes: [
                    `${manifest.scheme}://`,
                    `${applicationId || 'com.ouitherapeutics.app'}://`,
                    'https://oui.health',
                    'https://oui.dev',
                    'https://*.oui.dev',
                    'https://*.oui.health',
                  ],
                  config: deeplinkConfigRef.current,
                }}
                documentTitle={{
                  // we disable document title handling for two reasons
                  // 1) it messes up screen capture in detox-puppeteer (b/c we select the video source by doc title)
                  // 2) we need to create a better custom formatter and it's not immediately obvious what the logic is
                  enabled: false,
                }}
                initialState={initialNavigationState}
                theme={flags.allowDarkTheme && scheme === 'dark' ? DarkTheme : DefaultTheme}
                onReady={() => {
                  // eslint-disable-next-line
                  routingInstrumentation.registerNavigationContainer(navigator as any);
                }}
                onStateChange={(state) => {
                  latestStateRef.current = state;
                  // console.log('onStateChange', state);
                  // if (state) console.log(getPathFromState(state, deeplinkConfigRef.current));
                  if (!state) return;
                  const currentRoute = navigator.current?.getCurrentRoute();
                  const breadcrumbState = produce(state, (draft) => {
                    draft.routeNames = []; // don't need this list for debugging purposes
                  });
                  addBreadcrumb({
                    category: 'navigation',
                    data: {
                      state: breadcrumbState,
                      path: getPathFromState(state, deeplinkConfigRef.current),
                      name: currentRoute?.name,
                      params: currentRoute?.params,
                    },
                    level: 'debug',
                  });
                }}
                ref={(r) => {
                  setNavigatorState(r);
                }}
              >
                <Main />
              </NavigationContainer>
            </SnackbarProvider>
          </MinimumAppVersion>
        </InternetConnectivityProvider>
      </PermissionsManagerProvider>
    </>
  );
});

function WebUpdateAvailableToast({ onDismiss }: { onDismiss: () => void }) {
  const { Color } = useTheme();
  return (
    <View
      style={[
        {
          position: 'absolute',
          bottom: 20,
          right: 20,
          width: 300,
          padding: 20,
          borderRadius: 20,
          backgroundColor: Color.grayBackground,
        },
        Shadow.default,
      ]}
      spacing={10}
    >
      <View row style={{ justifyContent: 'space-between' }}>
        <Text text="Update available" weight="semibold" />
        <Icon accessibilityLabel="Dismiss" name="close" size={14} onPress={onDismiss} />
      </View>
      <Text
        text="A new version of the website is available. Please refresh to get the latest features and improvements."
        size={13}
      />
      <Button
        variant="text"
        text="Update"
        alignSelf="flex-end"
        size="small"
        onPress={() => {
          global.window.location.reload();
        }}
      />
    </View>
  );
}

export class AppContainer extends Component<
  AppInnerProps & {
    apollo: ApolloClient<unknown>;
    flags: AppCore.Flags;
    Logo: FunctionComponent<SvgProps & { accessibilityLabel: string | undefined }>;
    getMessages: ComponentProps<typeof I18nProvider>['getMessages'];
  },
  { hasError: boolean; updateAvailable: boolean; navigator: NavigationContainerRef<{}> | null }
> {
  state = { hasError: false, updateAvailable: false, navigator: null };
  lastUpdateCheckAt = Date.now();
  deeplinkConfig?: AppInnerProps['deeplinkConfig'];

  static getDerivedStateFromError() {
    return { navigator: null, hasError: true };
  }

  componentDidMount() {
    if (Platform.OS === 'web' && APP_SLUG !== 'oui-aviva') {
      AppState.addEventListener('change', (nextStatus) => {
        if (nextStatus === 'active' && Date.now() - this.lastUpdateCheckAt > 60 * 60 * 1000) {
          this.lastUpdateCheckAt = Date.now();
          checkForUpdateAsync().then(({ isAvailable }) => {
            if (isAvailable) {
              this.setState({ updateAvailable: true });
            }
          });
        }
      });
    }
    this.deeplinkConfig = {
      screens: {
        ...this.props.deeplinkConfig.screens,
        ...AUTH_DEEPLINK_CONFIG,
      },
    };
  }

  componentDidCatch(e: Error, info: object) {
    Sentry.withScope((scope) => {
      scope.setExtra('info', info);
      Sentry.captureException(e);
    });
  }

  dispatch = (action: NavigationActions.Action) => {
    const navigator: NavigationContainerRef<{}> = this.state.navigator!;
    navigator?.dispatch(action);
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          navigationContainer: this.state.navigator,
          flags: this.props.flags,
          locale,
          deeplinkConfig: this.deeplinkConfig,
          Logo: this.props.Logo,
        }}
      >
        <GestureHandlerRootView style={{ flex: 1 }}>
          <AccessibilityProvider>
            <SafeAreaProvider>
              <ActionSheetProvider>
                <ApolloProvider client={this.props.apollo}>
                  <OrientationProvider>
                    <LogoutProvider>
                      <CurrentUserProvider>
                        <MenuProvider>
                          <I18nProvider getMessages={this.props.getMessages}>
                            <DevMenu onNavigate={this.dispatch}>
                              {this.state.hasError ? (
                                <AppError
                                  retry={() => this.setState({ hasError: false })}
                                  type="runtime"
                                />
                              ) : (
                                <AppContainerInner
                                  app={this.props.app}
                                  ref={(r) => {
                                    if (!this.state.navigator && r) {
                                      this.setState({ navigator: r });
                                    }
                                  }}
                                  initialPath={this.props.initialPath}
                                  deeplinkConfig={
                                    this.deeplinkConfig ?? {
                                      screens: {
                                        ...this.props.deeplinkConfig.screens,
                                        ...AUTH_DEEPLINK_CONFIG,
                                      },
                                    }
                                  }
                                />
                              )}
                              {this.state.updateAvailable ? (
                                <WebUpdateAvailableToast
                                  onDismiss={() => this.setState({ updateAvailable: false })}
                                />
                              ) : null}
                            </DevMenu>
                          </I18nProvider>
                        </MenuProvider>
                      </CurrentUserProvider>
                    </LogoutProvider>
                  </OrientationProvider>
                </ApolloProvider>
              </ActionSheetProvider>
            </SafeAreaProvider>
          </AccessibilityProvider>
        </GestureHandlerRootView>
      </AppContext.Provider>
    );
  }
}
