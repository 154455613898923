// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CommonSupportUser_OuiAdmin_Fragment = { readonly __typename: 'OuiAdmin', readonly ID: CustomScalars.GQLUUID };

export type CommonSupportUser_Patient_Fragment = { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly profile: { readonly patient: { readonly ID: CustomScalars.GQLUUID }, readonly supportees: ReadonlyArray<{ readonly __typename: 'PatientSupporter', readonly supporterID: CustomScalars.GQLUUID, readonly patientID: CustomScalars.GQLUUID, readonly relation: Types.PatientSupporterRelation, readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly person: { readonly givenName?: string | null, readonly familyName?: string | null } } }> } };

export type CommonSupportUser_Practitioner_Fragment = { readonly __typename: 'Practitioner', readonly ID: CustomScalars.GQLUUID };

export type CommonSupportUser_Registrar_Fragment = { readonly __typename: 'Registrar', readonly ID: CustomScalars.GQLUUID };

export type CommonSupportUserFragment = CommonSupportUser_OuiAdmin_Fragment | CommonSupportUser_Patient_Fragment | CommonSupportUser_Practitioner_Fragment | CommonSupportUser_Registrar_Fragment;

export type SupportAccountSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SupportAccountSettingsQuery = { readonly ouiUser?: { readonly __typename: 'CurrentOuiUser', readonly userID: string, readonly pushNotificationsDisabled: boolean, readonly primaryOrganization?: { readonly __typename: 'Organization', readonly ID: CustomScalars.GQLUUID, readonly isTrialOrganization: boolean } | null, readonly user?: { readonly __typename: 'OuiAdmin', readonly ID: CustomScalars.GQLUUID } | { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly profile: { readonly patient: { readonly ID: CustomScalars.GQLUUID }, readonly supportees: ReadonlyArray<{ readonly __typename: 'PatientSupporter', readonly supporterID: CustomScalars.GQLUUID, readonly patientID: CustomScalars.GQLUUID, readonly relation: Types.PatientSupporterRelation, readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly person: { readonly givenName?: string | null, readonly familyName?: string | null } } }> } } | { readonly __typename: 'Practitioner', readonly ID: CustomScalars.GQLUUID } | { readonly __typename: 'Registrar', readonly ID: CustomScalars.GQLUUID } | null } | null, readonly currentUser: { readonly __typename: 'User', readonly ID: CustomScalars.GQLUserID, readonly email: string, readonly phone: string, readonly name: { readonly first: string, readonly last: string, readonly preferred?: string | null, readonly pfx?: string | null, readonly sfx?: string | null }, readonly demo?: { readonly DOB?: CustomScalars.GQLDate | null } | null, readonly address?: { readonly city: string, readonly country: string, readonly line1: string, readonly line2: string, readonly zip?: string | null, readonly state?: string | null } | null } };

export type SaveSupportAccountSettingsMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.UserNameInput>;
  address?: Types.InputMaybe<Types.UserAddressInput>;
  phone?: Types.InputMaybe<Types.Scalars['String']['input']>;
  relation: Types.PatientSupporterRelationInput;
}>;


export type SaveSupportAccountSettingsMutation = { readonly updateInfo: boolean, readonly setPatientSupporterRelation: { readonly __typename: 'PatientSupporter', readonly supporterID: CustomScalars.GQLUUID, readonly patientID: CustomScalars.GQLUUID, readonly relation: Types.PatientSupporterRelation } };

export type SetPushNotificationsDisabledMutationVariables = Types.Exact<{
  pushNotificationsDisabled: Types.Scalars['Boolean']['input'];
}>;


export type SetPushNotificationsDisabledMutation = { readonly setPushNotificationsDisabled?: { readonly __typename: 'CurrentOuiUser', readonly userID: string, readonly pushNotificationsDisabled: boolean } | null };

export const CommonSupportUserFragmentDoc = gql`
    fragment CommonSupportUser on OuiUserType {
  __typename
  ... on OuiAdmin {
    ID
  }
  ... on Registrar {
    ID
  }
  ... on Practitioner {
    ID
  }
  ... on Patient {
    ID
    profile {
      patient {
        ID
      }
      supportees {
        __typename
        supporterID
        patientID
        relation
        patient {
          __typename
          ID
          person {
            givenName
            familyName
          }
        }
      }
    }
  }
}
    `;
export const SupportAccountSettingsDocument = gql`
    query SupportAccountSettings {
  ouiUser {
    __typename
    userID
    primaryOrganization {
      __typename
      ID
      isTrialOrganization
    }
    user {
      ...CommonSupportUser
    }
    pushNotificationsDisabled
  }
  currentUser {
    __typename
    ID
    email
    phone
    name {
      first
      last
      preferred
      pfx
      sfx
    }
    demo {
      DOB
    }
    address {
      city
      country
      line1
      line2
      zip
      state
    }
  }
}
    ${CommonSupportUserFragmentDoc}`;

/**
 * __useSupportAccountSettingsQuery__
 *
 * To run a query within a React component, call `useSupportAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportAccountSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupportAccountSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SupportAccountSettingsQuery, SupportAccountSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupportAccountSettingsQuery, SupportAccountSettingsQueryVariables>(SupportAccountSettingsDocument, options);
      }
export function useSupportAccountSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupportAccountSettingsQuery, SupportAccountSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupportAccountSettingsQuery, SupportAccountSettingsQueryVariables>(SupportAccountSettingsDocument, options);
        }
export type SupportAccountSettingsQueryHookResult = ReturnType<typeof useSupportAccountSettingsQuery>;
export type SupportAccountSettingsLazyQueryHookResult = ReturnType<typeof useSupportAccountSettingsLazyQuery>;

export function querySupportAccountSettings(client: Apollo.ApolloClient<unknown>, variables?: SupportAccountSettingsQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<SupportAccountSettingsQuery, SupportAccountSettingsQueryVariables>, "query" | 'variables'>) {
  return client.query<SupportAccountSettingsQuery, SupportAccountSettingsQueryVariables>({
    query: SupportAccountSettingsDocument,
    variables,
    ...options,
  });
}

export const SaveSupportAccountSettingsDocument = gql`
    mutation SaveSupportAccountSettings($name: UserNameInput, $address: UserAddressInput, $phone: String, $relation: PatientSupporterRelationInput!) {
  updateInfo(name: $name, address: $address, phone: $phone)
  setPatientSupporterRelation(input: $relation) {
    __typename
    supporterID
    patientID
    relation
  }
}
    `;

/**
 * __useSaveSupportAccountSettingsMutation__
 *
 * To run a mutation, you first call `useSaveSupportAccountSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSupportAccountSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSupportAccountSettingsMutation, { data, loading, error }] = useSaveSupportAccountSettingsMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      phone: // value for 'phone'
 *      relation: // value for 'relation'
 *   },
 * });
 */
export function useSaveSupportAccountSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveSupportAccountSettingsMutation, SaveSupportAccountSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSupportAccountSettingsMutation, SaveSupportAccountSettingsMutationVariables>(SaveSupportAccountSettingsDocument, options);
      }
export type SaveSupportAccountSettingsMutationHookResult = ReturnType<typeof useSaveSupportAccountSettingsMutation>;

export function performSaveSupportAccountSettings(client: Apollo.ApolloClient<unknown>, variables: SaveSupportAccountSettingsMutationVariables, options?: Omit<Apollo.MutationOptions<SaveSupportAccountSettingsMutation, SaveSupportAccountSettingsMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<SaveSupportAccountSettingsMutation, SaveSupportAccountSettingsMutationVariables>({
    mutation: SaveSupportAccountSettingsDocument,
    variables,
    ...options,
  });
}

export const SetPushNotificationsDisabledDocument = gql`
    mutation SetPushNotificationsDisabled($pushNotificationsDisabled: Boolean!) {
  setPushNotificationsDisabled(
    pushNotificationsDisabled: $pushNotificationsDisabled
  ) {
    __typename
    userID
    pushNotificationsDisabled
  }
}
    `;

/**
 * __useSetPushNotificationsDisabledMutation__
 *
 * To run a mutation, you first call `useSetPushNotificationsDisabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPushNotificationsDisabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPushNotificationsDisabledMutation, { data, loading, error }] = useSetPushNotificationsDisabledMutation({
 *   variables: {
 *      pushNotificationsDisabled: // value for 'pushNotificationsDisabled'
 *   },
 * });
 */
export function useSetPushNotificationsDisabledMutation(baseOptions?: Apollo.MutationHookOptions<SetPushNotificationsDisabledMutation, SetPushNotificationsDisabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPushNotificationsDisabledMutation, SetPushNotificationsDisabledMutationVariables>(SetPushNotificationsDisabledDocument, options);
      }
export type SetPushNotificationsDisabledMutationHookResult = ReturnType<typeof useSetPushNotificationsDisabledMutation>;

export function performSetPushNotificationsDisabled(client: Apollo.ApolloClient<unknown>, variables: SetPushNotificationsDisabledMutationVariables, options?: Omit<Apollo.MutationOptions<SetPushNotificationsDisabledMutation, SetPushNotificationsDisabledMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<SetPushNotificationsDisabledMutation, SetPushNotificationsDisabledMutationVariables>({
    mutation: SetPushNotificationsDisabledDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const SaveSupportAccountSettingsMutationSchema = z.lazy(() => z.object({
name: Types.UserNameInputSchema.nullish(),
address: Types.UserAddressInputSchema.nullish(),
phone: z.string().nullish(),
relation: Types.PatientSupporterRelationInputSchema
}));


export const SetPushNotificationsDisabledMutationSchema = z.lazy(() => z.object({
pushNotificationsDisabled: z.boolean()
}))