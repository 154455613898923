// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetStaticSessionCompleteMutationVariables = Types.Exact<{
  content: Types.ContentType;
}>;


export type SetStaticSessionCompleteMutation = { readonly setOuiProgress: { readonly content: Types.ContentType, readonly completion: number, readonly completed: boolean, readonly updatedAt: CustomScalars.GQLDateTime } };


export const SetStaticSessionCompleteDocument = gql`
    mutation SetStaticSessionComplete($content: ContentType!) {
  setOuiProgress(content: $content, value: 1) {
    content
    completion
    completed
    updatedAt
  }
}
    `;

/**
 * __useSetStaticSessionCompleteMutation__
 *
 * To run a mutation, you first call `useSetStaticSessionCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStaticSessionCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStaticSessionCompleteMutation, { data, loading, error }] = useSetStaticSessionCompleteMutation({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSetStaticSessionCompleteMutation(baseOptions?: Apollo.MutationHookOptions<SetStaticSessionCompleteMutation, SetStaticSessionCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStaticSessionCompleteMutation, SetStaticSessionCompleteMutationVariables>(SetStaticSessionCompleteDocument, options);
      }
export type SetStaticSessionCompleteMutationHookResult = ReturnType<typeof useSetStaticSessionCompleteMutation>;

export function performSetStaticSessionComplete(client: Apollo.ApolloClient<unknown>, variables: SetStaticSessionCompleteMutationVariables, options?: Omit<Apollo.MutationOptions<SetStaticSessionCompleteMutation, SetStaticSessionCompleteMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<SetStaticSessionCompleteMutation, SetStaticSessionCompleteMutationVariables>({
    mutation: SetStaticSessionCompleteDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const SetStaticSessionCompleteMutationSchema = z.lazy(() => z.object({
content: Types.ContentTypeSchema
}))