// @ts-expect-error
import computeMd5 from 'blueimp-md5';
import * as FileSystem from 'expo-file-system';
// @ts-expect-error
import { Parser } from 'm3u8-parser';
import { Platform } from 'react-native';

type VTTCaption = {
  startMillis: number;
  endMillis: number;
  caption: string;
};

export type VTTObject = {
  captions: VTTCaption[];
};

export async function getVTTFromM3u8Uri(manifestUri: string): Promise<VTTObject> {
  const manifest = await (await fetch(manifestUri)).text();

  const parser = new Parser();
  parser.push(manifest);
  parser.end();

  const subtitlesUri = Object.values(
    (Object.values(parser.manifest.mediaGroups.SUBTITLES ?? {})[0] as object | undefined) ?? {},
  )[0]?.uri;

  if (!subtitlesUri) {
    return { captions: [] };
  }
  const subtitlesManifest = await (await fetch(subtitlesUri)).text();

  const subtitleParser = new Parser();
  subtitleParser.push(subtitlesManifest);
  subtitleParser.end();

  const subtitleVttUris: string[] =
    subtitleParser?.manifest?.segments?.map((s: { uri: string }) => s.uri) ?? [];

  const vtts = await Promise.all(subtitleVttUris.map(async (uri) => (await fetch(uri)).text()));

  // When combining VTTs from distinct files in a m3u8 manifest, it's possible that some timestamps
  // are duplicated
  const startMillisSet = new Set<number>();

  return vtts.map(parseVTT).reduce(
    (carry, vtt) => {
      for (let caption of vtt.captions) {
        if (!startMillisSet.has(caption.startMillis)) {
          startMillisSet.add(caption.startMillis);
          carry.captions.push(caption);
        }
      }

      return carry;
    },
    { captions: [] },
  );
}

// '00:00:01.040' => number
function timestampToMillis(timestamp: string): number {
  const [hours, minutes, seconds] = timestamp.split(':').map((str) => Number.parseFloat(str));
  const totalSeconds = hours * 60 * 80 + minutes * 60 + seconds;
  return totalSeconds * 1000;
}

function parseVTT(text: string): VTTObject {
  const captions: VTTCaption[] = [];

  text = text.replace(/\r/g, '');
  const parts = text.split('\n\n');

  for (let part of parts) {
    if (part.startsWith('WEBVTT') || part.startsWith('NOTE')) continue;
    const timestampStartIndex = part.match(/\d\d:/)?.index ?? 0;
    const [timestamps, ...texts] = part.slice(timestampStartIndex).split('\n');
    if (timestamps.includes(' --> ')) {
      const [start, end] = timestamps.split(' --> ');
      captions.push({
        startMillis: timestampToMillis(start),
        endMillis: timestampToMillis(end),
        caption: texts
          .join('\n')
          .replace(/\&nbsp;/g, ' ')
          // i think these <v> blocks are to identify speakers. Sometimes they put the speaker names
          // other times they use a placeholder "-"
          .replace(/\<v [\w-]*\>/, '')
          .replace(/\<\/v\>/, ''),
      });
    }
  }

  return {
    captions,
  };
}

// copied from MediaPlayer to prevent circular reference
function getCachedUri<T extends string | number>(uri: T): T extends number ? null : string {
  type Return = T extends number ? null : string;
  if (Platform.OS === 'web' || typeof uri === 'number') return null as Return;
  const extension = (uri as string).split('.').reverse()[0];
  const hash = computeMd5(uri);
  return `${FileSystem.documentDirectory}mediaplayer-preload/${hash}.${extension}` as Return;
}

async function preloadMedia(uri: string) {
  if (Platform.OS === 'web') return;
  await FileSystem.makeDirectoryAsync(`${FileSystem.documentDirectory}mediaplayer-preload`, {
    intermediates: true,
  });
  return FileSystem.downloadAsync(uri, getCachedUri(uri));
}

export async function parseRemoteVTT(uri: string) {
  if (Platform.OS === 'web') {
    const res = await fetch(uri);
    return parseVTT(await res.text());
  }

  const sourceUri = getCachedUri(uri);
  const { exists } = await FileSystem.getInfoAsync(sourceUri);
  if (!exists) {
    await preloadMedia(uri);
  }
  const data = await FileSystem.readAsStringAsync(sourceUri);
  return parseVTT(data);
}

export async function getVTTTranscript(vttOrM3u8Uri: string): Promise<string[]> {
  const vtt = await (vttOrM3u8Uri.endsWith('.vtt')
    ? parseRemoteVTT(vttOrM3u8Uri)
    : getVTTFromM3u8Uri(vttOrM3u8Uri));
  return vtt.captions.map((cap) => cap.caption.replace(/\r/g, ' ').replace(/\n/g, ' ').trim());
}
