import { defineMessages } from 'react-intl';

export const firebaseErrors = defineMessages({
  EMAIL_EXISTS: {
    id: 'AuthenticationError_emailExists',
    defaultMessage: 'This email has already been taken.',
  },
  EMAIL_NOT_FOUND: {
    id: 'AuthenticationError_emailNotFound',
    defaultMessage: 'This email + password is incorrect.',
  },
  INVALID_EMAIL: {
    id: 'AuthenticationError_invalidEmail',
    defaultMessage: 'This email is not valid.',
  },
  INVALID_PASSWORD: {
    id: 'AuthenticationError_invalidPassword',
    defaultMessage: 'This email + password is incorrect.',
  },
  INVALID_PASSWORD_NO_EMAIL: {
    id: 'AuthenticationError_invalidPasswordNoEmail',
    defaultMessage: 'This password is incorrect.',
  },
  MISSING_EMAIL: {
    id: 'AuthenticationError_missingEmail',
    defaultMessage: 'This user does not exist.',
  },
  MISSING_PASSWORD: {
    id: 'AuthenticationError_missingPassword',
    defaultMessage: 'Please enter your password.',
  },
  TOO_MANY_ATTEMPTS_TRY_LATER: {
    id: 'AuthenticationError_tooManyAttempts',
    defaultMessage: 'Too many failed login attempts. Try again in a few minutes.',
  },
  USER_DISABLED: {
    id: 'AuthenticationError_userDisabled',
    defaultMessage: 'Your account has been disabled.',
  },
  USER_NOT_FOUND: {
    id: 'AuthenticationError_userNotFound',
    defaultMessage: "Sorry, can't find that account.",
  },
});

export function isFirebaseErrorKey(str: string): str is keyof typeof firebaseErrors {
  return str in firebaseErrors;
}
