import { useMemo } from 'react';

import { OnboardingVariant, ProductVariant } from '@oui/lib/src/types/graphql.generated';

import { useAppContext } from '@src/components/AppContext';
import { Session, SessionState } from '@src/components/SessionCard';
import { IS_PRODUCTION } from '@src/constants';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { useI18n } from '@src/lib/i18n';

import { TreatmentListQuery } from './useFeed.graphql.generated';

export type SessionWithState = {
  slug: string;
  session: Session;
  state: SessionState;
};
type Feed = {
  title: string;
  data: SessionWithState[];
};

export function useFeed(treatmentV2: TreatmentListQuery['treatmentV2'] | undefined) {
  const { locale } = useAppContext();
  const { data } = useCurrentUser();
  const isCollaborativeOnboarding =
    data?.currentUser?.user?.__typename === 'Patient' &&
    data.currentUser.user.onboardingVariant === OnboardingVariant.COLLABORATIVE;
  const { $t } = useI18n();

  const unlockAllSessions =
    data?.currentUser?.user?.__typename === 'Patient' &&
    data.currentUser.user.productVariant === ProductVariant.AVIVA_INPATIENT;

  return useMemo(() => {
    let feed: Feed[] | null = null;
    let activeIndex = -1;
    let activeItem: SessionWithState = null!;

    if (treatmentV2) {
      const tmpFeed: Feed[] = [
        {
          title: 'Your feed',
          data: treatmentV2.map((treatment, i, arr) => {
            const prev = arr[i - 1];

            const state =
              treatment.progress === 'COMPLETED'
                ? 'session-complete'
                : treatment.progress === 'STARTED' || !prev
                ? 'active'
                : !prev?.progress ||
                  prev?.progress === 'STARTED' ||
                  prev?.progress === 'NOT_STARTED'
                ? unlockAllSessions
                  ? 'unlocked'
                  : 'locked'
                : 'active';

            const slug =
              !IS_PRODUCTION && locale === 'es' && treatment.slug === 'MYPLAN'
                ? 'DEMO_MYPLAN__ES'
                : treatment.slug;
            const currentItem = {
              session: {
                num: treatment.ordinal,
                title: treatment.title,
                subtitle: treatment.subtitle ?? undefined,
                details: treatment.description
                  .replace(/\\n/g, '\n')
                  .split('\n')
                  .filter((v) => !!v),
                practiceTitle: undefined,
              },
              slug,
              state,
            } as SessionWithState;

            if (state === 'active') {
              activeIndex = i;
              activeItem = currentItem;
            }

            return currentItem;
          }),
        },
      ];

      if (unlockAllSessions) {
        feed = [
          {
            title: $t({
              id: 'Learn_sessionsHeading',
              defaultMessage: 'Sessions',
            }),
            data: tmpFeed[0].data,
          },
        ];
      } else {
        feed = [
          {
            title: $t({
              id: 'Learn_acuteCrisisHeading',
              defaultMessage: 'Controlling a crisis',
            }),
            data: tmpFeed[0].data.slice(0, 2),
          },
          {
            title: $t({
              id: 'Learn_chronicCrisisHeading',
              defaultMessage: 'Manage risk factors',
            }),
            data: tmpFeed[0].data.slice(2, 9),
          },
          {
            title: $t({
              id: 'Learn_reviewHeading',
              defaultMessage: 'Review skills',
            }),
            data: tmpFeed[0].data.slice(9),
          },
        ];
      }
    }

    return { feed, activeItem, activeIndex, isCollaborativeOnboarding };
  }, [treatmentV2, isCollaborativeOnboarding, unlockAllSessions, locale, $t]);
}
