'use client';

import { PropsWithChildren, ReactNode } from 'react';
import { ScrollView } from 'react-native';

import { Button } from '@src/components/Button';
import { Icon } from '@src/components/Icon';
import { ModalContainer } from '@src/components/ModalContainer';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { Shadow, useTheme } from '@src/styles';

export function FormModalNoPadding({ children }: PropsWithChildren) {
  return <View style={{ marginHorizontal: -24 }}>{children}</View>;
}

export function FormModal(props: {
  cancelTestID?: string;
  cancelText?: string;
  children?: ReactNode;
  confirmTestID?: string;
  confirmText?: string;
  disableButton?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  title: string;
  subtitle?: string;
  visible: boolean;
  minWidth?: number;
  maxWidth?: number;
  maxHeight?: number;
  hideActions?: boolean;
}) {
  const { Color } = useTheme();
  return (
    <ModalContainer
      visible={props.visible}
      onRequestClose={() => {}}
      style={[
        {
          minWidth: props.minWidth ?? 340,
          maxWidth: props.maxWidth ?? Math.max(600, props.minWidth ?? 0),
          width: '100%',
          marginVertical: 'auto',
          backgroundColor: Color.backgroundColor,
          marginHorizontal: 10,
          borderRadius: 20,
          paddingVertical: 24,
          paddingHorizontal: 24,
        },
        Shadow.high,
      ]}
    >
      <View
        row
        style={{
          marginBottom: 20,
          justifyContent: 'space-between',
        }}
      >
        <View spacing={10}>
          <Text text={props.title} size={20} weight="bold" />
          {props.subtitle ? (
            <Text color={Color.styleGuide.Gray3} text={props.subtitle} size={15} />
          ) : null}
        </View>
        {props.onCancel ? (
          <Icon name="close" onPress={props.onCancel} size={14} accessibilityLabel="Close modal" />
        ) : null}
      </View>
      {props.maxHeight ? (
        <ScrollView
          style={{ maxHeight: props.maxHeight, marginHorizontal: -24, paddingHorizontal: 24 }}
        >
          <View
            style={{
              width: '100%',
            }}
          >
            {props.children}
          </View>
        </ScrollView>
      ) : (
        <View
          style={{
            width: '100%',
          }}
        >
          {props.children}
        </View>
      )}
      {props.hideActions ? null : (
        <View
          row
          style={{ justifyContent: props.onCancel ? 'space-between' : 'center', marginTop: 14 }}
        >
          {props.onCancel ? (
            <Button
              onPress={props.onCancel}
              text={props.cancelText ?? 'Cancel'}
              variant="text"
              testID={props.cancelTestID}
              style={{}}
            />
          ) : null}
          <Button
            disabled={props.disableButton}
            onPress={props.onConfirm}
            text={props.confirmText ?? 'Save'}
            variant="solid"
            testID={props.confirmTestID}
          />
        </View>
      )}
    </ModalContainer>
  );
}
