import { getRandomBytes } from 'expo-crypto';
import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';
import { MMKV } from 'react-native-mmkv';

import { btoa } from '@oui/lib/src/parseJwt';

type Scope = 'apollo' | 'bot';
const scopes: Scope[] = ['apollo', 'bot'];

const mmkvInstances: Record<string, MMKV | undefined> = {};

function getSecretKey(numBytes: number = 64) {
  const newKeyData = getRandomBytes(numBytes);
  const pieces = [];
  for (let byte of newKeyData) {
    pieces.push(String.fromCharCode(byte));
  }
  return btoa(pieces.join(''));
}

export async function getEncryptionKeyForMmkv(scope: Scope) {
  const storageKey = `mmkvKey_${scope}`;
  const storedSecret = await SecureStore.getItemAsync(storageKey, {
    keychainAccessible: SecureStore.WHEN_UNLOCKED_THIS_DEVICE_ONLY,
  });
  if (storedSecret) return storedSecret;
  const newKey = getSecretKey();
  await SecureStore.setItemAsync(storageKey, newKey, {});
  return newKey;
}

export async function initMmkv() {
  if (Platform.OS === 'web') return;
  await Promise.all(scopes.map((scope) => loadMmkv(scope)));
}

export const loadMmkv = async (scope: Scope) => {
  if (mmkvInstances[scope]) return mmkvInstances[scope]!;
  const newInstance = new MMKV({
    id: `${scope}_store`,
    encryptionKey: await getEncryptionKeyForMmkv(scope),
  });
  mmkvInstances[scope] = newInstance;
  return newInstance;
};

export const getMmkv = (scope: Scope): MMKV => {
  if (mmkvInstances[scope]) return mmkvInstances[scope]!;
  if (process.env.NODE_ENV === 'test') {
    mmkvInstances[scope] = new MMKV({
      id: `${scope}_store`,
    });
    return getMmkv(scope);
  }
  throw new Error('MMKV not yet loaded for ' + scope);
};

export const clearAll = async () => {
  if (Platform.OS === 'web') return;
  scopes.map((scope) => getMmkv(scope).clearAll());
};
