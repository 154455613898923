import Url from 'url-parse';

const urlToPathAndParams = (url: string) => {
  const isWebUrl = url.startsWith('http://') || url.startsWith('https://');
  if (!isWebUrl) url = url.replace('://', ':///');
  const parsedUrl = new Url(url, {}, { parser: true });
  const { query } = new Url(url, {});
  const path = parsedUrl.pathname.slice(1);
  return {
    path,
    params: parsedUrl.query,
    pathWithQuery: path ? path + query : '',
  };
};

export function parseUrl(url: string | null | undefined) {
  if (!url) return null;
  const parsedUrl = urlToPathAndParams(url);
  const isAuthDeeplink = !!(parsedUrl && parsedUrl.path && parsedUrl.path.startsWith('auth/'));
  return {
    ...parsedUrl,
    isAuthDeeplink,
    isDeeplink: !!(
      url &&
      parsedUrl &&
      url !== parsedUrl.path &&
      parsedUrl.path !== '' &&
      url.slice(0, -1) !== parsedUrl.path
    ),
  };
}
