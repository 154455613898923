import { Image, Linking } from 'react-native';

import { Button } from '@oui/app-core/src/components/Button';
import { EmailInput, getStyles } from '@oui/app-core/src/components/TextInput';
import { View } from '@oui/app-core/src/components/View';
import { APP_SLUG, CLOUD_FUNCTION_URL, manifest } from '@oui/app-core/src/constants';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useTheme } from '@oui/app-core/src/styles';

import { StaticMyPlanWrapper } from '@src/components/StaticMyPlanWrapper';

const PNG_URL = 'https://storage.googleapis.com/asset.oui.dev/static/SafetyPlanTemplate.png';
const PDF_URL = 'https://storage.googleapis.com/asset.oui.dev/static/SafetyPlanTemplate.pdf';

export function StaticMyPlan() {
  const { theme, Color } = useTheme();
  const { data, bind, validate } = useForm({ email: '' });
  return (
    <StaticMyPlanWrapper
      label="Safety Plan"
      myPlan={
        <View>
          <Image
            source={{
              uri: PNG_URL,
            }}
            style={{ width: '100%', aspectRatio: 1700 / 2200 }}
          />
          <View
            style={{ padding: 14, borderRadius: 14, backgroundColor: Color.grayBackground }}
            spacing={12}
          >
            <View>
              <EmailInput
                placeholder="name@email.com"
                {...bind('email', { accessibilityLabel: 'Email', validator: { type: 'email' } })}
                inputStyle={{ paddingRight: 80, height: 44, paddingVertical: 0 }}
                testID="StaticMyPlan_emailInput"
              />
              <Button
                testID="StaticMyPlan_emailButton"
                text="Email"
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 4,
                  height: 44,
                  elevation: getStyles(theme, Color).focused.elevation,
                }}
                size="small"
                onPress={() => {
                  if (validate()) {
                    const body = JSON.stringify({ to: data.email });
                    return fetch(`${CLOUD_FUNCTION_URL}/sendStaticSafetyPlan`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'X-Oui-Client': APP_SLUG!,
                        'X-Oui-Client-Version': manifest.version ?? '0.0.0',
                      },
                      body,
                    });
                  }
                  return;
                }}
              />
            </View>
            <Button
              text="Download"
              icon="download"
              onPress={() => {
                Linking.openURL(PDF_URL);
              }}
              style={{ backgroundColor: 'transparent' }}
              testID="StaticMyPlan_downloadButton"
              alignSelf="center"
            />
          </View>
        </View>
      }
    />
  );
}
