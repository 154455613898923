import { ArtifactButton } from '@oui/app-core/src/components/ArtifactButton';
import { View } from '@oui/app-core/src/components/View';
import { MyStoryMyPlanCompositionDataHash } from '@oui/app-core/src/hooks/useComposition';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { card } from '@oui/app-core/src/styles';
import { ProductVariant } from '@oui/lib/src/types';

import { EnvironmentSafety } from '@src/components/PatientMyPlan';
import { useIsEnvironmentSafetyLocked } from '@src/hooks/useIsEnvironmentSafetyLocked';

export function EnvironmentSafetyLocked() {
  const { $t } = useI18n();
  const { user } = useCurrentPatient();
  const unlockingSession = user?.productVariant === ProductVariant.AVIVA_INPATIENT ? 3 : 2;

  return (
    <ArtifactButton
      testID="ArtifactButton_EnvironmentSafety__locked"
      bannerText={$t(
        {
          id: 'EnvironmentSafetyLocked_banner',
          defaultMessage: 'Complete session {sessionNum}',
        },
        {
          sessionNum: unlockingSession,
        },
      )}
      assetName="MeansSafetyPlan"
      title={$t({
        id: 'EnvironmentSafetyLocked_title',
        defaultMessage: 'Make your environment safe',
      })}
      numCompleted={0}
      onPress={() => {}}
    />
  );
}

export function EnvironmentSafetySection({
  data,
  cardUI,
  highlightedSections,
}: {
  data: MyStoryMyPlanCompositionDataHash | undefined;
  cardUI?: boolean;
} & Pick<React.ComponentProps<typeof EnvironmentSafety>, 'highlightedSections'>) {
  const isEnvironmentSafetyLocked = useIsEnvironmentSafetyLocked();

  return !data ? null : isEnvironmentSafetyLocked ? (
    <EnvironmentSafetyLocked />
  ) : cardUI ? (
    <View style={[card, { padding: 20 }]}>
      <EnvironmentSafety
        data={data}
        isEditing={false}
        onEdit={() => {}}
        highlightedSections={highlightedSections}
      />
    </View>
  ) : (
    <EnvironmentSafety
      data={data}
      isEditing={false}
      onEdit={() => {}}
      highlightedSections={highlightedSections}
    />
  );
}
