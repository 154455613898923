// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestResetPasswordMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type RequestResetPasswordMutation = { readonly issueTokenByEmail: boolean };


export const RequestResetPasswordDocument = gql`
    mutation RequestResetPassword($email: String!) {
  issueTokenByEmail(email: $email)
}
    `;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, options);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;

export function performRequestResetPassword(client: Apollo.ApolloClient<unknown>, variables: RequestResetPasswordMutationVariables, options?: Omit<Apollo.MutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>({
    mutation: RequestResetPasswordDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const RequestResetPasswordMutationSchema = z.lazy(() => z.object({
email: z.string()
}))