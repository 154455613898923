// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DevMenuQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DevMenuQuery = { readonly treatmentV2?: ReadonlyArray<{ readonly slug: string }> | null };


export const DevMenuDocument = gql`
    query DevMenu {
  treatmentV2 {
    slug
  }
}
    `;

/**
 * __useDevMenuQuery__
 *
 * To run a query within a React component, call `useDevMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useDevMenuQuery(baseOptions?: Apollo.QueryHookOptions<DevMenuQuery, DevMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevMenuQuery, DevMenuQueryVariables>(DevMenuDocument, options);
      }
export function useDevMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevMenuQuery, DevMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevMenuQuery, DevMenuQueryVariables>(DevMenuDocument, options);
        }
export type DevMenuQueryHookResult = ReturnType<typeof useDevMenuQuery>;
export type DevMenuLazyQueryHookResult = ReturnType<typeof useDevMenuLazyQuery>;

export function queryDevMenu(client: Apollo.ApolloClient<unknown>, variables?: DevMenuQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<DevMenuQuery, DevMenuQueryVariables>, "query" | 'variables'>) {
  return client.query<DevMenuQuery, DevMenuQueryVariables>({
    query: DevMenuDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







