import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { TabHeader } from '@oui/app-core/src/components/TabHeader';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';

import { NextSession } from '@src/components/NextSession';
import { SessionSuggestions } from '@src/components/SessionSuggestions';
import { SupporteeMyPlanStatus } from '@src/components/SupporteeMyPlanStatus';

export function Home(_props: {}) {
  const { $t } = useI18n();
  const { theme } = useTheme();

  return (
    <ScrollView
      testID="Home"
      style={{ flex: 1 }}
      contentContainerStyle={{ flexGrow: 1 }}
      bottomOverflowColor={theme.color.gray800}
    >
      <TabHeader heading={$t({ id: 'Home_heading', defaultMessage: 'Home' })} borderBottom />
      <View style={{ padding: 20, backgroundColor: theme.color.gray800, flexGrow: 1 }}>
        <SupporteeMyPlanStatus style={{ marginBottom: 60 }} />
        <NextSession style={{ marginBottom: 60 }} />
        <SessionSuggestions />
      </View>
    </ScrollView>
  );
}
