import NetInfo from '@react-native-community/netinfo';
import { Platform } from 'react-native';

export * from '@react-native-community/netinfo';
export default NetInfo;

export const _configuration = {
  reachabilityUrl: 'https://clients3.google.com/generate_204',
  reachabilityMethod: 'HEAD',
  reachabilityTest: (response: Response): Promise<boolean> =>
    Promise.resolve(response.status === 204 || (Platform.OS === 'web' && response.status === 200)),
  reachabilityRequestTimeout: 5 * 1000,
};

export async function fetchIsConnected(
  timeoutMs: number = _configuration.reachabilityRequestTimeout,
): Promise<boolean> {
  if (Platform.OS === 'web' && global.window.location.hostname === 'localhost') {
    return Promise.resolve(true);
  }

  const responsePromise = fetch(
    Platform.OS === 'web' ? global.window.location.origin : _configuration.reachabilityUrl,
    {
      method: _configuration.reachabilityMethod,
      cache: 'no-cache',
    },
  );

  // Create promise that will reject after the request timeout has been reached
  let timeoutHandle: ReturnType<typeof setTimeout>;
  const timeoutPromise = new Promise<Response>((_, reject): void => {
    timeoutHandle = setTimeout((): void => reject('timedout'), timeoutMs);
  });

  return Promise.race([responsePromise, timeoutPromise])
    .then((response): Promise<boolean> => {
      clearTimeout(timeoutHandle);
      return _configuration.reachabilityTest(response);
    })
    .catch((_: Error | 'timedout' | 'canceled') => {
      return false;
    });
}
