// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SupporteeMyPlanQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SupporteeMyPlanQuery = { readonly ouiUser?: { readonly user?: { readonly __typename: 'OuiAdmin' } | { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID, readonly profile: { readonly patient: { readonly ID: CustomScalars.GQLUUID }, readonly supportees: ReadonlyArray<{ readonly supporterID: CustomScalars.GQLUUID, readonly patientID: CustomScalars.GQLUUID, readonly patient: { readonly ID: CustomScalars.GQLUUID, readonly person: { readonly givenName?: string | null, readonly familyName?: string | null }, readonly profile: { readonly patient: { readonly ID: CustomScalars.GQLUUID }, readonly myPlanComposition?: { readonly __typename: 'Composition', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly sections: ReadonlyArray<{ readonly __typename: 'CompositionSection', readonly ID: CustomScalars.GQLUUID, readonly title?: string | null, readonly json?: CustomScalars.GQLAny | null, readonly createdAt: CustomScalars.GQLDateTime, readonly updatedAt: CustomScalars.GQLDateTime }> } | null, readonly progress: ReadonlyArray<{ readonly __typename: 'OuiProgress', readonly content: Types.ContentType, readonly completed: boolean, readonly updatedAt: CustomScalars.GQLDateTime }> } } }> } } | { readonly __typename: 'Practitioner' } | { readonly __typename: 'Registrar' } | null } | null };


export const SupporteeMyPlanDocument = gql`
    query SupporteeMyPlan {
  ouiUser {
    user {
      __typename
      ... on Patient {
        ID
        profile {
          patient {
            ID
          }
          supportees {
            supporterID
            patientID
            patient {
              ID
              person {
                givenName
                familyName
              }
              profile {
                patient {
                  ID
                }
                myPlanComposition {
                  __typename
                  ID
                  title
                  sections {
                    __typename
                    ID
                    title
                    json
                    createdAt
                    updatedAt
                  }
                }
                progress {
                  __typename
                  content
                  completed
                  updatedAt
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSupporteeMyPlanQuery__
 *
 * To run a query within a React component, call `useSupporteeMyPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupporteeMyPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupporteeMyPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupporteeMyPlanQuery(baseOptions?: Apollo.QueryHookOptions<SupporteeMyPlanQuery, SupporteeMyPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupporteeMyPlanQuery, SupporteeMyPlanQueryVariables>(SupporteeMyPlanDocument, options);
      }
export function useSupporteeMyPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupporteeMyPlanQuery, SupporteeMyPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupporteeMyPlanQuery, SupporteeMyPlanQueryVariables>(SupporteeMyPlanDocument, options);
        }
export type SupporteeMyPlanQueryHookResult = ReturnType<typeof useSupporteeMyPlanQuery>;
export type SupporteeMyPlanLazyQueryHookResult = ReturnType<typeof useSupporteeMyPlanLazyQuery>;

export function querySupporteeMyPlan(client: Apollo.ApolloClient<unknown>, variables?: SupporteeMyPlanQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<SupporteeMyPlanQuery, SupporteeMyPlanQueryVariables>, "query" | 'variables'>) {
  return client.query<SupporteeMyPlanQuery, SupporteeMyPlanQueryVariables>({
    query: SupporteeMyPlanDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







