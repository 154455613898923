import hexToRgba from 'hex-to-rgba';
import { ComponentProps, memo, useEffect, useRef } from 'react';
import { Animated, Easing, Platform, TouchableWithoutFeedback } from 'react-native';

import { Icon } from '@src/components/Icon';
import { Text, ThemeText } from '@src/components/Text';
import { View } from '@src/components/View';
import { useIsSafeLayoutAnimating } from '@src/hooks/useSafeLayoutAnimation';
import { useTheme } from '@src/styles';

type Props = {
  disabled?: boolean;
  horizontal?: boolean;
  label?: string | JSX.Element;
  labelWeight?: ComponentProps<typeof Text>['weight'];
  onChangeValue: (value: boolean) => void;
  testID?: string;
  value?: boolean;
  variant?: 'radio' | 'checkbox';
};

// CheckboxInner is purely a perf optimization. onChangeValue is often the
// prop that changes but only affects the TouchableWithoutFeedback wrapper component
// If that changes, we dont care about rerendering the visible portion of the component
const CheckboxInner = memo(function CheckboxInner({
  horizontal,
  label,
  labelWeight,
  value,
  variant,
}: Omit<Props, 'onChangeValue' | 'testID'>) {
  const isAnimating = useIsSafeLayoutAnimating();
  const { theme, Color } = useTheme();
  const animValue = useRef(new Animated.Value(value ? 1 : 0));
  useEffect(() => {
    Animated.timing(animValue.current, {
      useNativeDriver: Platform.OS !== 'web',
      toValue: value ? 1 : 0,
      duration: 150,
      easing: Easing.inOut(Easing.linear),
    }).start();
  }, [animValue, value]);
  const fontTheme = theme.typography[theme.checkbox.optionLabel];

  return (
    <>
      {label ? (
        <ThemeText
          text={label}
          style={{
            flex: horizontal ? 1 : 0,
            marginBottom: horizontal ? 0 : 4,
            marginLeft: horizontal ? theme.checkbox.optionLabelSpacing : 0,
          }}
          fontTheme={fontTheme}
          weight={labelWeight ?? 'semibold'}
        />
      ) : null}
      <View
        key={isAnimating.toString()}
        style={[
          {
            alignItems: 'center',
            borderColor: Color.accent,
            borderRadius: variant === 'radio' ? 15 : 4,
            borderWidth: 2,
            height: theme.checkbox.size,
            justifyContent: 'center',
            overflow: variant === 'radio' ? 'hidden' : undefined,
            width: theme.checkbox.size,
          },
        ]}
      >
        <Animated.View
          style={{
            alignItems: 'center',
            backgroundColor: hexToRgba(Color.accent),
            height: theme.checkbox.size - 3,
            justifyContent: 'center',
            opacity: isAnimating ? (value ? 1 : 0) : animValue.current,
            width: theme.checkbox.size - 3,
          }}
          // @ts-expect-error
          dataSet={{ noselect: '' }}
        >
          {variant === 'radio' ? (
            <View
              style={{
                backgroundColor: 'transparent',
                borderColor: Color.backgroundColor,
                borderWidth: 3,
                height: theme.checkbox.size - 2,
                width: theme.checkbox.size - 2,
                borderRadius: 13,
              }}
            />
          ) : (
            <Animated.View
              style={{
                transform: [{ scale: isAnimating ? (value ? 1 : 0) : animValue.current }],
              }}
            >
              <Icon name="check" size={theme.checkbox.size - 10} color="white" />
            </Animated.View>
          )}
        </Animated.View>
      </View>
    </>
  );
});

export function Checkbox({ onChangeValue, testID, ...props }: Props) {
  return (
    <TouchableWithoutFeedback
      testID={testID}
      disabled={props.disabled}
      onPress={(e) => {
        if (Platform.OS === 'web') {
          e.preventDefault();
        }
        onChangeValue(!props.value);
      }}
      hitSlop={{
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      }}
      accessibilityRole={props.variant === 'radio' ? 'radio' : 'checkbox'}
      accessibilityState={{
        disabled: props.disabled,
        checked: props.value,
      }}
    >
      <View
        row={props.horizontal}
        style={[
          props.horizontal
            ? {
                flexDirection: 'row-reverse',
                alignItems: 'flex-start',
              }
            : null,
          props.disabled ? { opacity: 0.5 } : null,
        ]}
      >
        <CheckboxInner {...props} />
      </View>
    </TouchableWithoutFeedback>
  );
}
