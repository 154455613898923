// This file is automatically generated. See gqlcodegen.yml for details
export const namedAvivaOperations = {
  Query: {
    DevMenu: 'DevMenu',
    MinimumAppVersion: 'MinimumAppVersion',
    PlacesTypeaheadAutocomplete: 'PlacesTypeaheadAutocomplete',
    PlacesTypeaheadDetails: 'PlacesTypeaheadDetails',
    DetoxAdminOrganization: 'DetoxAdminOrganization',
    PracticeRatings: 'PracticeRatings',
    ActionCounts: 'ActionCounts',
    ActivityDiaryEntry: 'ActivityDiaryEntry',
    ActivityDiaryEntries: 'ActivityDiaryEntries',
    SleepDiaryEntryByDate: 'SleepDiaryEntryByDate',
    SleepDiaryEntry: 'SleepDiaryEntry',
    SleepDiaryEntries: 'SleepDiaryEntries',
    ThoughtDiaryEntry: 'ThoughtDiaryEntry',
    ThoughtDiaryEntries: 'ThoughtDiaryEntries',
    RelaxDiaryEntryCounts: 'RelaxDiaryEntryCounts',
    CompositionByID: 'CompositionByID',
    Compositions: 'Compositions',
    CurrentUser: 'CurrentUser',
    SelfPatient: 'SelfPatient',
    Organizations: 'Organizations',
    TreatmentList: 'TreatmentList',
    GetResponse: 'GetResponse',
    KVResponse: 'KVResponse',
    Learn: 'Learn',
    CompletedQuizSets: 'CompletedQuizSets',
    QuizSetCollection: 'QuizSetCollection',
    NextSession: 'NextSession',
    SessionSuggestions: 'SessionSuggestions',
    SupporteeMyPlan: 'SupporteeMyPlan',
    SupportAccountSettings: 'SupportAccountSettings',
    PatientSupporterLinkDetails: 'PatientSupporterLinkDetails',
    FinishRegistering: 'FinishRegistering'
  },
  Mutation: {
    DetoxAddPatient: 'DetoxAddPatient',
    DetoxIssueActivationToken: 'DetoxIssueActivationToken',
    DetoxIssuePractitionerToken: 'DetoxIssuePractitionerToken',
    DetoxAddPractitioner: 'DetoxAddPractitioner',
    AddAction: 'AddAction',
    AddActivityPractice: 'AddActivityPractice',
    UpdateActivityPractice: 'UpdateActivityPractice',
    DeleteActivityPractice: 'DeleteActivityPractice',
    AddSleepDiaryEntry: 'AddSleepDiaryEntry',
    UpdateSleepDiaryEntry: 'UpdateSleepDiaryEntry',
    AddThoughtDiaryEntry: 'AddThoughtDiaryEntry',
    UpdateThoughtDiaryEntry: 'UpdateThoughtDiaryEntry',
    DeleteThoughtDiaryEntry: 'DeleteThoughtDiaryEntry',
    AddRelaxDiaryEntry: 'AddRelaxDiaryEntry',
    UpdateRelaxDiaryEntry: 'UpdateRelaxDiaryEntry',
    NewCompositionWithTemplate: 'NewCompositionWithTemplate',
    UpdateCompositionSection: 'UpdateCompositionSection',
    SetMyStoryMyPlanComplete: 'SetMyStoryMyPlanComplete',
    Logout: 'Logout',
    Login: 'Login',
    Reauthenticate: 'Reauthenticate',
    FabricateStateV2End: 'FabricateStateV2End',
    SetPushToken: 'SetPushToken',
    SaveResponse: 'SaveResponse',
    KVRespond: 'KVRespond',
    CreateTestUser: 'CreateTestUser',
    CompleteQuizSet: 'CompleteQuizSet',
    RequestResetPassword: 'RequestResetPassword',
    ResetPassword: 'ResetPassword',
    ClaimSignupTokenFromActivationCode: 'ClaimSignupTokenFromActivationCode',
    SaveSupportAccountSettings: 'SaveSupportAccountSettings',
    SetPushNotificationsDisabled: 'SetPushNotificationsDisabled',
    SupportPatientRegistration: 'SupportPatientRegistration'
  },
  Fragment: {
    SleepDiaryEntry: 'SleepDiaryEntry',
    ThoughtDiaryEntryFragment: 'ThoughtDiaryEntryFragment',
    ActivityFragment: 'ActivityFragment',
    RelaxDiaryEntryFragment: 'RelaxDiaryEntryFragment',
    PracticeFragment: 'PracticeFragment',
    CompositionSectionFields: 'CompositionSectionFields',
    CompositionFields: 'CompositionFields',
    CommonUser: 'CommonUser',
    CurrentOuiUser: 'CurrentOuiUser',
    CommonSupportUser: 'CommonSupportUser'
  }
}