import AsyncStorage from '@react-native-async-storage/async-storage';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import type { AppVersion, PatientSupporterRelation } from '@oui/lib/src/types';
import { GQLDateTime } from '@oui/lib/src/types/scalars';

import type { AuthenticationType } from '@src/hooks/useReauthenticationState';

const listeners: Record<string, Array<(s: any) => void>> = {}; // eslint-disable-line

function getStorageKey(key: keyof PersistedStateTypes) {
  return `usePersistedState:${key}`;
}

type PersistedStateTypes = {
  SeenLocalAuthenticationPrompt: boolean;
  enrolledReauthenticationTypes: AuthenticationType[];
  disableLocalAuthentication: boolean;
  enableClosedCaptions: boolean;
  enableMute: boolean;
  ystPasscode: string;
  staticSessionCurrentPages: Record<string, number | undefined>;
  artifactRequest: string | null; // TODO ArtifactScreen type
  minOuiAppVersion: AppVersion | null;
  patientSupporterRelation: PatientSupporterRelation;
  supporteeMyPlanLastSeenAt: GQLDateTime;
};

export function setPersistedState<K extends keyof PersistedStateTypes>(
  key: K,
  value: PersistedStateTypes[K],
) {
  const storageKey = getStorageKey(key);
  AsyncStorage.setItem(storageKey, JSON.stringify(value));
  INITIAL_VALUES[key] = value;
  listeners[storageKey]?.forEach((cb) => cb(value));
}

const INITIAL_VALUES: Partial<
  Record<keyof PersistedStateTypes, PersistedStateTypes[keyof PersistedStateTypes]>
> = {};

export async function preloadPersistedState<K extends keyof PersistedStateTypes>(key: K) {
  const persistedValue = await AsyncStorage.getItem(getStorageKey(key));
  if (persistedValue) {
    const decoded: PersistedStateTypes[K] =
      typeof persistedValue === 'undefined' ? undefined : JSON.parse(persistedValue);
    INITIAL_VALUES[key] = decoded;
    return decoded;
  }
  return undefined;
}

export function clearPersistedState() {
  (Object.keys(INITIAL_VALUES) as Array<keyof typeof INITIAL_VALUES>).forEach((key) => {
    delete INITIAL_VALUES[key];
    listeners[key]?.forEach((cb) => cb(undefined));
  });
}

export function usePersistedState<K extends keyof PersistedStateTypes>(
  key: K,
  initialState: PersistedStateTypes[K] | (() => PersistedStateTypes[K]),
): [PersistedStateTypes[K], Dispatch<SetStateAction<PersistedStateTypes[K]>>, boolean] {
  type S = PersistedStateTypes[K];
  const [loading, setLoading] = useState(!!INITIAL_VALUES[key]);
  const [value, setValue] = useState<S>((INITIAL_VALUES[key] as S) ?? initialState);
  const initialValueRef = useRef(value);
  // We keep valueRef around because setValueWithPersistence needs to be able
  // to pass the most recent value if it's passed an updater function which
  // may be cached in a closure somewhere else
  const valueRef = useRef<S>(value);

  useEffect(() => {
    const storageKey = getStorageKey(key);
    AsyncStorage.getItem(storageKey).then((persistedValue) => {
      if (persistedValue) {
        const decoded: S =
          typeof persistedValue === 'undefined' ? undefined : JSON.parse(persistedValue);
        setValue(decoded);
        INITIAL_VALUES[key] = decoded;
        valueRef.current = decoded;
      }
      setLoading(false);
    });

    if (!listeners[storageKey]) listeners[storageKey] = [];
    listeners[storageKey].push((newValue) => {
      setValue(typeof newValue === 'undefined' ? initialValueRef.current : newValue);
    });
    return () => {
      listeners[storageKey] = listeners[storageKey].filter((cb) => cb !== setValue);
    };
  }, [key]);

  const setValueWithPersistence: Dispatch<SetStateAction<PersistedStateTypes[K]>> = useCallback(
    (newValueOrFunc) => {
      const newValue: PersistedStateTypes[K] =
        typeof newValueOrFunc === 'function'
          ? (newValueOrFunc as unknown as (prevState: S) => S)(valueRef.current)
          : newValueOrFunc;

      setPersistedState(key, newValue);
      INITIAL_VALUES[key] = newValue;
      valueRef.current = newValue;
    },
    [key],
  );

  return [value, setValueWithPersistence, loading];
}
