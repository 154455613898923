import { useContext, useRef } from 'react';
import { TextInput as RNTextInput } from 'react-native';
import * as z from 'zod';

import {
  OnboardingVariant,
  ProductVariant,
  ProductVersion,
} from '@oui/lib/src/types/graphql.generated';

import { AuthScreenContainer } from '@src/components/AuthScreenContainer';
import { Button } from '@src/components/Button';
import { ErrorPresenter } from '@src/components/ErrorPresenter';
import { Label, Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { APP_SLUG } from '@src/constants';
import {
  Controller,
  EmailInputRender,
  PickerInputRender,
  SwitchInputRender,
  TextInputRender,
  useZodForm,
} from '@src/form';
import { LogoutContext } from '@src/hooks/useLogout';
import { login } from '@src/lib/auth';
import { getPickerItemsFromEnum } from '@src/lib/getPickerItemsFromEnum';

import {
  CreateTestUserMutationSchema,
  useCreateTestUserMutation,
} from './CreateTestUser.graphql.generated';

export function CreateTestUser() {
  const { setIsLoggingOut } = useContext(LogoutContext);
  const [createTestUser, { error, loading }] = useCreateTestUserMutation();

  const { control, handleSubmit } = useZodForm(
    CreateTestUserMutationSchema.schema.merge(
      z.object({
        email: z.string().email(),
        password: z.string().min(8, 'Must be at least 8 characters'),
      }),
    ),
    {
      defaultValues: {
        password: '',
        email: '',
        testPatient: {
          productVersion: ProductVersion.AVIVA_2_0,
          productVariant:
            APP_SLUG === 'aviva-support'
              ? ProductVariant.AVIVA_SUPPORT
              : ProductVariant.AVIVA_ADULT,
          onboardingVariant: OnboardingVariant.INDEPENDENT,
          caringContactsMedium: null,
          productStatic: false,
        },
      },
    },
  );

  const emailInputRef = useRef<RNTextInput>(null);
  const passwordInputRef = useRef<RNTextInput>(null);

  const request = handleSubmit(
    async (data) => {
      const email = data.email.trim();
      await createTestUser({ variables: { ...data, email } });
      // A bit of a hack so that we can set mock state if needed before the useIsLoggedIn
      // navigates us away from this screen
      setIsLoggingOut(true);
      await login(email, data.password);
      // any data mocking should be done here
      setIsLoggingOut(false);
    },
    (errors) => {
      console.log('errors', errors);
    },
  );

  return (
    <AuthScreenContainer heading={'Create test user'}>
      <View spacing={12} style={{ paddingBottom: 100 }}>
        <Text text="Thank you for testing Aviva. We appreciate your help!" size={15} />
        <Text
          text="To file a bug report at any time, hold on the screen with three fingers until the Tester menu is shown. Select 'ReportError' and describe your issue in as much detail as possible."
          size={15}
        />
        <Label text="Create new user" />
        {error ? <ErrorPresenter error={error} /> : null}
        <Controller
          control={control}
          render={(props) =>
            EmailInputRender(props, {
              accessibilityLabel: 'Email',
              ref: emailInputRef,
              onSubmitEditing: () => passwordInputRef.current?.focus(),
            })
          }
          name="email"
        />
        <Controller
          control={control}
          render={(props) =>
            TextInputRender(props, {
              secureTextEntry: true,
              textContentType: 'newPassword',
              autoComplete: 'password',
              placeholder: 'Password',
              hint: 'Must be at least 8 characters',
              ref: passwordInputRef,
              onSubmitEditing: request,
              returnKeyType: 'next',
              testID: 'Signup_passwordInput',
            })
          }
          name="password"
        />
        {APP_SLUG === 'aviva-support' ? null : (
          <Controller
            control={control}
            render={(props) =>
              PickerInputRender(props, {
                label: 'User type',
                items: getPickerItemsFromEnum(ProductVariant).filter(
                  (i) => i.value !== ProductVariant.AVIVA_STATIC,
                ),
              })
            }
            name="testPatient.productVariant"
          />
        )}
        <View row style={{ justifyContent: 'space-between' }}>
          <Label text="Control group?" />
          <Controller
            control={control}
            render={(props) =>
              SwitchInputRender(props, {
                accessibilityLabel: 'Control',
                showOnOff: true,
              })
            }
            name="testPatient.productStatic"
          />
        </View>

        <Button text="Submit" onPress={request} loading={loading} />
      </View>
    </AuthScreenContainer>
  );
}
