import { getDeviceId } from '@amplitude/analytics-react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { v4 as uuid } from 'uuid';

import { GQLUUID } from '@oui/lib/src/types/scalars';

let _promise: Promise<GQLUUID>;

export async function getInstallationIDAsync(): Promise<GQLUUID> {
  if (_promise) return _promise;

  _promise = new Promise<GQLUUID>(async (res) => {
    let persistedInstallationID = (await AsyncStorage.getItem('installationID')) as GQLUUID | null;

    if (!persistedInstallationID) {
      persistedInstallationID = (getDeviceId() ?? uuid()) as GQLUUID;
      await AsyncStorage.setItem('installationID', persistedInstallationID);
    }

    res(persistedInstallationID);
  });

  return _promise;
}
