import interpolate from 'color-interpolate';
import { useState } from 'react';
import { Platform, Pressable } from 'react-native';
import Svg, { Circle, ClipPath, Defs, G, LinearGradient, Path, Rect, Stop } from 'react-native-svg';

import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { Shadow, card, useTheme } from '@oui/app-core/src/styles';
import { CrisisTimeline } from '@oui/lib/src/types/avro/crisisTimeline';

import { Icon } from '@src/components/Icon';

function square(num: number) {
  return Math.pow(num, 2);
}

export const COLOR_STOPS = [
  { color: '#750606', offset: 0 },
  { color: '#F5AFAF', offset: 0.4 },
  { color: '#E9A3A3', offset: 1 },
];

const COLOR_MAP = interpolate(COLOR_STOPS.map((c) => c.color));

export function getColorForX({ x }: { x: number }) {
  return COLOR_MAP(getYForXOnEllipse({ x }) / VIEWPORT_HEIGHT);
}

function Tooltip({ x, text }: { x: number; text: string }) {
  const [layout, setLayout] = useState<{ height: number }>({ height: 0 });
  const width = 150;
  const isNearLowEdge = x < width / 2;
  return (
    <View
      onLayout={(e) => {
        if (layout.height !== e.nativeEvent.layout.height) {
          setLayout(e.nativeEvent.layout);
        }
      }}
      style={[
        card,
        {
          position: 'absolute',
          backgroundColor: 'white',
          width,
          transform: [
            {
              translateX:
                (isNearLowEdge ? (-width + x) / 4 : x - width / 2) +
                VIEWPORT_HORIZONTAL_PADDING +
                CONTAINER_HORIZONTAL_PADDING,
            },
            { translateY: getYForXOnEllipse({ x: x }) - layout?.height },
          ],
          padding: 10,
        },
      ]}
    >
      <Text text={text} size={12} />
    </View>
  );
}

function getYForXOnEllipse({ x }: { x: number }) {
  if (x < 10 || x > 290) return VIEWPORT_HEIGHT;
  const offsetX = VIEWPORT_WIDTH / 2;
  const offsetY = VIEWPORT_HEIGHT;
  const radiusX = (VIEWPORT_WIDTH - VIEWPORT_HORIZONTAL_PADDING * 2) / 2;
  const radiusY = VIEWPORT_HEIGHT - WEB_VERTICAL_OFFSET;
  return (
    VIEWPORT_HEIGHT * 2 -
    (Math.sqrt((1 - square(x - offsetX) / square(radiusX)) * square(radiusY)) + offsetY)
  );
}

function getXForYOnEllipse({ y }: { y: number }) {
  if (y === 0 || y === VIEWPORT_HEIGHT) return 0;
  const offsetX = VIEWPORT_WIDTH / 2;
  const offsetY = VIEWPORT_HEIGHT;
  const radiusX = (VIEWPORT_WIDTH - VIEWPORT_HORIZONTAL_PADDING * 2) / 2;
  const radiusY = VIEWPORT_HEIGHT - WEB_VERTICAL_OFFSET;
  return (
    VIEWPORT_WIDTH -
    (Math.sqrt((1 - square(y - offsetY) / square(radiusY)) * square(radiusX)) + offsetX)
  );
}

const TOUCH_SIZE = 20;
function Point({
  x,
  onPress,
  radius = 5,
  fill = 'url(#paint0_linear)',
}: {
  onPress?: () => void;
  x: number;
  radius?: number;
  fill?: string;
}) {
  const y = getYForXOnEllipse({ x });
  return (
    <>
      <Circle cx={x} cy={y} r={radius + 0.5} fill="rgba(36, 34, 38, 0.15)" />
      <Circle cx={x} cy={y} r={radius} fill={fill} />
      <Rect
        x={x - TOUCH_SIZE / 2}
        y={y - TOUCH_SIZE / 2}
        width={TOUCH_SIZE}
        height={TOUCH_SIZE}
        fill="transparent"
        onPress={onPress}
      />
    </>
  );
}

const VIEWPORT_WIDTH = 300;
const VIEWPORT_HEIGHT = 160;
const VIEWPORT_HORIZONTAL_PADDING = 10;
const VIEWPORT_ASPECT_RATIO = VIEWPORT_WIDTH / VIEWPORT_HEIGHT;
const CONTAINER_HORIZONTAL_PADDING = 6 * VIEWPORT_ASPECT_RATIO;
const WEB_VERTICAL_OFFSET = Platform.select({ web: 5, default: 0 });
const CURVE = `M${VIEWPORT_HORIZONTAL_PADDING} ${VIEWPORT_HEIGHT} A ${
  (VIEWPORT_WIDTH - VIEWPORT_HORIZONTAL_PADDING * 2) / 2
} ${VIEWPORT_HEIGHT - WEB_VERTICAL_OFFSET} 0 0 1 ${
  VIEWPORT_WIDTH - VIEWPORT_HORIZONTAL_PADDING
} ${VIEWPORT_HEIGHT}`;

export function getXPositionsForItems(crisisTimeline: CrisisTimeline): number[] {
  const crisisIndex = crisisTimeline.timeline.findIndex(
    (i) => i.ID === crisisTimeline.crisisPeakID,
  );

  if (crisisIndex === -1) return crisisTimeline.timeline.map(() => 0);

  const numPreCrisis = crisisIndex;
  const numPostCrisis = crisisTimeline.timeline.length - crisisIndex;

  const preStep = VIEWPORT_WIDTH / 2 / (numPreCrisis + 1);
  const postStep = VIEWPORT_WIDTH / 2 / numPostCrisis;

  return crisisTimeline.timeline.map((_, i) => {
    if (i === crisisIndex) return VIEWPORT_WIDTH / 2;
    if (i < crisisIndex) return Math.max(VIEWPORT_HORIZONTAL_PADDING, (i + 0.5) * preStep);
    return Math.min(
      VIEWPORT_WIDTH - VIEWPORT_HORIZONTAL_PADDING,
      VIEWPORT_WIDTH / 2 + (i - crisisIndex + 0.5) * postStep,
    );
  });
}

export function RiskCurve({
  onPressPoint,
  activeItemIndex,
  crisisPeakID,
  timeline: items,
  preview,
  baselineY = 0,
  baselineClipped,
  focusedSection,
}: Omit<Props, 'numRiskFactors'> & { baselineY?: number; baselineClipped?: boolean }) {
  const { Color } = useTheme();

  if (focusedSection === 'falling') {
    items = [
      { ID: '0', text: '', isWarningSign: false },
      { ID: '1', text: '', isWarningSign: false },
      { ID: '2', text: '', isWarningSign: false },
      { ID: '3', text: '', isWarningSign: false },
      { ID: '4', text: '', isWarningSign: false },
    ];
    crisisPeakID = '4';
  } else if (focusedSection === 'peak' || focusedSection === 'rising') {
    items = [{ ID: '0', text: '', isWarningSign: false }];
    crisisPeakID = '0';
  }

  const baselineYPercentage = baselineY / VIEWPORT_HEIGHT;

  const arrowBottomY = 160 - baselineY;
  const yOffset = 0; //baselineY ? 10 : 0;
  const arrowMiddleX =
    VIEWPORT_WIDTH - getXForYOnEllipse({ y: arrowBottomY }) - (baselineY ? 2 : 10);
  const curveArrow =
    focusedSection !== 'rising' ? (
      <Path
        d={`M${arrowMiddleX - 7} ${150 - baselineY + yOffset}L${arrowMiddleX} ${
          160 - baselineY + yOffset
        }L${arrowMiddleX + 7} ${150 - baselineY + yOffset}`}
        stroke={COLOR_STOPS[COLOR_STOPS.length - 1].color}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    ) : null;

  const curveFill = (
    <Path
      d={CURVE}
      fill="url(#paint1_linear)"
      clipPath={
        focusedSection === 'rising'
          ? '#clip-first-half'
          : focusedSection === 'falling'
          ? '#clip-second-half'
          : undefined
      }
    />
  );
  const curve = (
    <Path
      d={CURVE}
      stroke="url(#paint0_linear)"
      fill="none"
      strokeWidth="6"
      strokeLinejoin="round"
      clipPath={
        focusedSection === 'rising'
          ? '#clip-first-half'
          : baselineY && baselineClipped
          ? '#clip-baseline'
          : undefined
      }
    />
  );
  const firstHalfCurve =
    focusedSection === 'falling' ? (
      <Path
        d={CURVE}
        stroke="#fcecec"
        fill="none"
        strokeWidth="6"
        strokeLinejoin="round"
        clipPath="#clip-first-half"
      />
    ) : null;

  const genericCurveArrow =
    baselineY && !baselineClipped ? (
      <Path
        d="M283 150L290 160L297 150"
        stroke="#ececee"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    ) : null;

  const xPositions = getXPositionsForItems({ crisisPeakID, timeline: items });
  const pointRadius = focusedSection === 'peak' ? 10 : undefined;
  const points = items.map((item, i) => (
    <Point
      radius={pointRadius}
      key={item.ID}
      x={xPositions[i]}
      onPress={() => {
        onPressPoint?.(item, i);
      }}
      fill={focusedSection === 'rising' || focusedSection === 'falling' ? '#fcecec' : undefined}
    />
  ));

  return (
    <View
      style={{
        width: '100%',
        aspectRatio: VIEWPORT_ASPECT_RATIO,
        paddingHorizontal: CONTAINER_HORIZONTAL_PADDING,
      }}
    >
      <Svg viewBox={`0 -${(pointRadius ?? 0) / 2} 300 160`}>
        <G clipPath={preview ? 'url(#clip)' : undefined}>
          {curveFill}
          {genericCurveArrow}
          {curve}
          {firstHalfCurve}
          {curveArrow}
        </G>
        {focusedSection === 'rising' || focusedSection === 'falling' ? null : (
          <Path
            d={`M${VIEWPORT_WIDTH / 2} 0 L ${VIEWPORT_WIDTH / 2} ${VIEWPORT_HEIGHT + 5}`}
            stroke={Color.styleGuide.Gray6}
            strokeDasharray="5 5"
          />
        )}
        {preview ? null : points}
        <Defs>
          <LinearGradient
            id="paint0_linear"
            x1="150"
            y1="0"
            x2="150"
            y2="150"
            gradientUnits="userSpaceOnUse"
          >
            {(baselineY
              ? [
                  ...COLOR_STOPS,
                  {
                    color: baselineClipped ? 'rgba(255,255,255,0)' : '#ececee',
                    offset: 1 - baselineYPercentage / 2,
                  },
                ]
              : COLOR_STOPS.filter((c) => !!c)
            ).map((stop, i) => (
              <Stop
                key={i}
                stopColor={stop.color}
                offset={
                  i === COLOR_STOPS.length - 1 ? stop.offset - baselineYPercentage : stop.offset
                }
              />
            ))}
          </LinearGradient>
          <LinearGradient
            id="paint1_linear"
            x1="150"
            y1="40"
            x2="150"
            y2="150"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FAF0F0" />
            <Stop offset="1" stopColor="#FBF7F7" stopOpacity="0" />
          </LinearGradient>
          <ClipPath id="clip">
            <Rect x="0" y="0" width="70" height={VIEWPORT_HEIGHT * 2} />
          </ClipPath>
          <ClipPath id="clip-baseline">
            <Rect x="0" y="-10" width={VIEWPORT_WIDTH} height={10 + VIEWPORT_HEIGHT - baselineY} />
          </ClipPath>
          <ClipPath id="clip-first-half">
            <Rect
              x="0"
              y="-10"
              width={VIEWPORT_WIDTH / 2}
              height={baselineY ? 10 + VIEWPORT_HEIGHT - baselineY : VIEWPORT_HEIGHT * 2}
            />
          </ClipPath>
          <ClipPath id="clip-second-half">
            <Rect
              x={VIEWPORT_WIDTH / 2}
              y="-10"
              width={VIEWPORT_WIDTH}
              height={VIEWPORT_HEIGHT * 2}
            />
          </ClipPath>
        </Defs>
      </Svg>
      {preview ? (
        <View
          style={[
            Shadow.low,
            {
              position: 'absolute',
              left: 70 - 7 + Platform.select({ default: CONTAINER_HORIZONTAL_PADDING, web: 0 }),
              borderRadius: 20,
              top:
                getYForXOnEllipse({ x: 70 - 7 }) -
                CONTAINER_HORIZONTAL_PADDING / VIEWPORT_ASPECT_RATIO -
                (Platform.OS === 'web' ? 14 : 0),
              backgroundColor: Color.styleGuide.Gray7,
            },
          ]}
        >
          <View style={{ padding: 7 }}>
            <Icon name="close" size={16} />
          </View>
        </View>
      ) : null}
      {typeof activeItemIndex === 'number' ? (
        <Tooltip text={items[activeItemIndex].text} x={xPositions[activeItemIndex]} />
      ) : null}
    </View>
  );
}

type Props = CrisisTimeline & {
  accessibilityLabel?: string;
  preview?: boolean;
  activeItemIndex?: number;
  onPressPoint?: (item: CrisisTimeline['timeline'][0], i: number) => void;
  onPress?: () => void;
  focusedSection?: 'peak' | 'rising' | 'falling';
  numRiskFactors: number | undefined;
};

export function RiskCurveGraph({ numRiskFactors = 0, ...props }: Props) {
  const { Color } = useTheme();
  const baselineY = [0, 15, 25, 35, 45, 55, 65, 65, 65][numRiskFactors];
  return (
    <Pressable
      onPress={props.onPress}
      accessible
      accessibilityLabel={props.accessibilityLabel}
      accessibilityRole="imagebutton"
    >
      <View row>
        <View style={{ width: 1, alignSelf: 'stretch', backgroundColor: Color.styleGuide.Gray6 }} />
        <Text
          text="Risk"
          style={{
            position: 'absolute',
            transform: [{ rotateZ: '-90deg' }, { translateY: -25 }],
            backgroundColor: 'white',
            paddingHorizontal: 10,
          }}
          weight="semibold"
        />
        <View style={{ flex: 1 }}>
          {props.focusedSection !== 'rising' && props.focusedSection !== 'falling' ? (
            <Text text="Peak of suicide crisis" textAlign="center" size={13} />
          ) : (
            <Text text="" textAlign="center" size={13} accessibilityRole="none" />
          )}
          <RiskCurve {...props} baselineClipped baselineY={baselineY} />
          <View
            style={{ alignSelf: 'stretch', height: 1, backgroundColor: Color.styleGuide.Gray6 }}
          />
        </View>
      </View>
      <Text textAlign="center" text="Time" weight="semibold" />
    </Pressable>
  );
}

export function RiskCurveBaselineGraph({
  accessibilityLabel,
  backgroundColor,
  numRiskFactors = 0,
  baselineText = 'Baseline',
}: {
  accessibilityLabel?: string;
  backgroundColor?: string;
  numRiskFactors?: number;
  baselineText?: string;
}) {
  const { Color } = useTheme();
  const [height, setHeight] = useState(VIEWPORT_HEIGHT);
  const baselineY = [0, 15, 25, 35, 45, 55, 65, 65, 65][numRiskFactors];
  const riskQualification = [
    'with no baseline risk',
    'with low baseline risk',
    'with some baseline risk',
    'with some baseline risk',
    'with higher baseline risk',
    'with higher baseline risk',
    'with severe baseline risk',
    'with severe baseline risk',
  ][numRiskFactors];
  return (
    <View
      row
      accessible
      accessibilityLabel={accessibilityLabel ?? `Your baseline risk curve ${riskQualification}`}
      accessibilityRole="image"
    >
      <View style={{ width: 1, alignSelf: 'stretch', backgroundColor: Color.styleGuide.Gray6 }} />
      <Text
        text="Risk"
        style={{
          position: 'absolute',
          transform: [{ rotateZ: '-90deg' }, { translateY: -25 }],
          backgroundColor: backgroundColor ?? 'white',
          paddingHorizontal: 10,
        }}
        weight="semibold"
      />
      <View
        style={{ flex: 1 }}
        onLayout={(e) => {
          if (e.nativeEvent.layout.height !== height) setHeight(e.nativeEvent.layout.height);
        }}
      >
        <Text text="Peak of suicide crisis" textAlign="center" size={13} />
        <RiskCurve
          timeline={[{ ID: '0', text: '', isWarningSign: false }]}
          crisisPeakID="0"
          baselineY={baselineY}
        />
        <View
          style={{
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            transform: [
              { translateY: baselineY ? -baselineY * (height / VIEWPORT_HEIGHT) + 5 : 0 },
            ],
          }}
        >
          <Text
            text={baselineText}
            style={{
              backgroundColor: backgroundColor ?? 'white',
              marginLeft: Math.max(30 + getXForYOnEllipse({ y: VIEWPORT_HEIGHT - baselineY }), 40),
              alignSelf: 'flex-start',
            }}
            weight="semibold"
          />
          <View
            style={{
              alignSelf: 'stretch',
              height: 2,
              backgroundColor: 'black',
            }}
          />
        </View>
        <View
          style={{ alignSelf: 'stretch', height: 1, backgroundColor: Color.styleGuide.Gray6 }}
        />
      </View>
    </View>
  );
}
