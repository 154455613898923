import { Label } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';

export const SupportPatientDetails = (props: { patientName: string }) => {
  const { $t } = useI18n();

  const initials = (name: string) => {
    return name
      .split(' ')
      .map((n) => n[0])
      .join('');
  };

  return (
    <View spacing={13} testID="SupportPatientDetails">
      <Label
        testID="SupportPatientDetails_message"
        text={$t({
          id: 'SupportPatientDetails_message',
          defaultMessage: 'Your account has been linked with',
        })}
      />

      <View
        row
        spacing={7}
        style={{
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            backgroundColor: '#D9EDED',
            borderRadius: 25,
            width: 25,
            height: 25,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Label
            testID="SupportPatientDetails_patientNameInitials"
            text={initials(props.patientName)}
            color="#008689"
          />
        </View>

        <Label testID="SupportPatientDetails_patientName" text={props.patientName} />
      </View>
    </View>
  );
};
