import { createContext, useCallback, useContext, useState } from 'react';
import { LayoutAnimation } from 'react-native';

const Context = createContext(false);

export function useSafeLayoutAnimation() {
  const [isAnimating, setIsAnimating] = useState(false);

  const configureNext = useCallback(
    (
      before: Function,
      config: Parameters<typeof LayoutAnimation.configureNext>[0],
      callback?: Parameters<typeof LayoutAnimation.configureNext>[1],
    ) => {
      setIsAnimating(true);
      setTimeout(() => {
        LayoutAnimation.configureNext(config, () => {
          setIsAnimating(false);
          callback?.();
        });
        before?.();
      }, 0);
    },
    [],
  );

  return {
    ...LayoutAnimation,
    configureNext,
    Provider: Context.Provider,
    isAnimating,
  };
}

export function useIsSafeLayoutAnimating() {
  return useContext(Context);
}
