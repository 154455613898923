import { useNavigation, useRoute } from '@react-navigation/native';
import { useEffect } from 'react';

import { HealthOui } from '@oui/lib/src/types/avro/socialDistractions';

type Payload = { places: HealthOui.SocialDistractionPlace[]; permissionDenied?: boolean };
export function usePlacesPickerResult(callback: (payload: Payload) => void) {
  const { setParams } = useNavigation();
  const { name, params } = useRoute<any>();

  useEffect(() => {
    if (params?._placesPickerResult) {
      const placesPickerResult: Payload = params._placesPickerResult;
      // @ts-expect-error
      setParams({ _placesPickerResult: undefined });
      callback(placesPickerResult);
    }
  }, [setParams, params?._placesPickerResult, callback]);

  return name;
}
