import * as Updates from 'expo-updates';
import { Platform } from 'react-native';

export async function checkForUpdateAsync() {
  if (Platform.OS === 'web') {
    const result = await fetch(window.location.origin).then((r) => r.text());
    const nextVersion = result?.match(/app\.([0-9a-z]*)\.chunk\.js/)?.[1];
    const currentVersion = Array.from(document.querySelectorAll('script'))
      .find((s) => s.src.match(/app\.([0-9a-z]*)\.chunk\.js/))
      ?.src.match(/app\.([0-9a-z]*)\.chunk\.js/)?.[1];

    const isAvailable = !!(nextVersion && nextVersion !== currentVersion);
    return isAvailable
      ? {
          isAvailable,
          manifest: {},
        }
      : { isAvailable };
  }

  return Updates.checkForUpdateAsync().catch((e) => {
    const ignoredErrors = ['ERR_UPDATES_DISABLED', 'EXPERIENCE_NOT_FOUND'];
    if (ignoredErrors.includes(e.code) || ignoredErrors.includes(e.errorCode)) {
      return {
        isAvailable: false,
      };
    }

    throw e;
  });
}

export async function fetchUpdateAsync() {
  if (Platform.OS === 'web') {
    return Promise.resolve();
  }
  return Updates.fetchUpdateAsync();
}

export async function reloadAsync() {
  if (Platform.OS === 'web') {
    return global.window.location.reload();
  }
  return Updates.reloadAsync();
}
