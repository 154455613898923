import { useIsFocused } from '@react-navigation/native';
import * as Localization from 'expo-localization';
import { Fragment, useEffect, useMemo } from 'react';
import { RefreshControl, StatusBar } from 'react-native';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { LearnProgress } from '@oui/app-core/src/components/LearnProgress';
import { ScrollHere, ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Session, SessionCard, SessionState } from '@oui/app-core/src/components/SessionCard';
import { TabHeader } from '@oui/app-core/src/components/TabHeader';
import { Heading } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useProgressByContent } from '@oui/app-core/src/hooks/useCurrentUser';
import { ContentType } from '@oui/lib/src/types';

import { useNavigateToStaticSessionHook } from '@src/hooks/useNavigateToStaticSessionHook';
import { StaticScreenProps } from '@src/types/navigation';

import { LearnQuery, useSetLocaleAndTimezoneMutation } from './LearnStatic.graphql.generated';

type SessionWithState = {
  slug: ContentType;
  session: Session;
  state: SessionState;
};
type Feed = {
  title: string;
  data: SessionWithState[];
};

type Props = StaticScreenProps<'Learn'>;

type TreatmentType = Array<
  Exclude<NonNullable<LearnQuery['treatmentV2']>[0], 'slug'> & { slug: ContentType }
>;

function useFeed(treatmentV2: TreatmentType | undefined) {
  return useMemo(() => {
    let feed: Feed[] | null = null;
    let activeIndex = -1;
    let activeItem: SessionWithState = null!;

    if (treatmentV2) {
      const tmpFeed: Feed[] = [
        {
          title: 'Your feed',
          data: treatmentV2.map((treatment, i, arr) => {
            const prev = arr[i - 1];

            const state =
              treatment.progress === 'COMPLETED'
                ? 'session-complete'
                : treatment.progress === 'STARTED' || !prev
                ? 'active'
                : !prev?.progress ||
                  prev?.progress === 'STARTED' ||
                  prev?.progress === 'NOT_STARTED'
                ? 'locked'
                : 'active';

            const currentItem = {
              session: {
                num: treatment.ordinal,
                title: treatment.title,
                subtitle: treatment.subtitle ?? undefined,
                details: treatment.description?.replace(/\\n/g, '\n').split('\n') ?? [],
                practiceTitle: undefined,
              },
              slug: treatment.slug,
              state,
            } as SessionWithState;

            if (state === 'active') {
              activeIndex = i;
              activeItem = currentItem;
            }

            return currentItem;
          }),
        },
      ];

      feed = [
        {
          title: 'Controlling a crisis',
          data: tmpFeed[0].data.slice(0, 2),
        },
        {
          title: 'Manage risk factors',
          data: tmpFeed[0].data.slice(2, 9),
        },
        {
          title: 'Review skills',
          data: tmpFeed[0].data.slice(9),
        },
      ];
    }

    return { feed, activeItem, activeIndex };
  }, [treatmentV2]);
}

export function LearnStatic(props: Props) {
  const isFocused = useIsFocused();
  const { data: progress, refetch } = useProgressByContent();
  const treatmentV2: TreatmentType = useMemo(
    () => [
      {
        ordinal: 1,
        title: `Session 1`,
        description: '',
        slug: ContentType.STATIC_01,
        progress: progress.STATIC_01?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
      {
        ordinal: 2,
        title: `Session 2`,
        description: '',
        slug: ContentType.STATIC_02,
        progress: progress.STATIC_02?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
      {
        ordinal: 3,
        title: `Session 3`,
        description: '',
        slug: ContentType.STATIC_03,
        progress: progress.STATIC_03?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
      {
        ordinal: 4,
        title: `Session 4`,
        description: '',
        slug: ContentType.STATIC_04,
        progress: progress.STATIC_04?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
      {
        ordinal: 5,
        title: `Session 5`,
        description: '',
        slug: ContentType.STATIC_05,
        progress: progress.STATIC_05?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
      {
        ordinal: 6,
        title: `Session 6`,
        description: '',
        slug: ContentType.STATIC_06,
        progress: progress.STATIC_06?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
      {
        ordinal: 7,
        title: `Session 7`,
        description: '',
        slug: ContentType.STATIC_07,
        progress: progress.STATIC_07?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
      {
        ordinal: 8,
        title: `Session 8`,
        description: '',
        slug: ContentType.STATIC_08,
        progress: progress.STATIC_08?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
      {
        ordinal: 9,
        title: `Session 9`,
        description: '',
        slug: ContentType.STATIC_09,
        progress: progress.STATIC_09?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
      {
        ordinal: 10,
        title: `Session 10`,
        description: '',
        slug: ContentType.STATIC_10,
        progress: progress.STATIC_10?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
      {
        ordinal: 11,
        title: `Session 11`,
        description: '',
        slug: ContentType.STATIC_11,
        progress: progress.STATIC_11?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
      {
        ordinal: 12,
        title: `Session 12`,
        description: '',
        slug: ContentType.STATIC_12,
        progress: progress.STATIC_12?.completed ? 'COMPLETED' : 'NOT_STARTED',
      },
    ],
    [progress],
  );

  const [setLocaleAndTimezone] = useSetLocaleAndTimezoneMutation();
  useEffect(() => {
    setLocaleAndTimezone({
      variables: { locale: Localization.locale, timezone: Localization.timezone },
    });
  }, [setLocaleAndTimezone]);

  const { feed, activeIndex } = useFeed(treatmentV2);
  const { navigateToStaticSession } = useNavigateToStaticSessionHook({
    navigation: props.navigation,
  });

  let currentIndex = 0;
  const numCompleted =
    treatmentV2 && treatmentV2[treatmentV2.length - 1].progress === 'COMPLETED'
      ? 12
      : Math.max(activeIndex, 0);

  return (
    <View testID="LearnStatic" style={{ flex: 1 }}>
      {isFocused ? <StatusBar backgroundColor="white" barStyle="dark-content" /> : null}
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={() => {
              refetch();
            }}
            title="Update"
            progressViewOffset={150}
          />
        }
        testID="LearnStatic_scrollView"
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingBottom: 20,
        }}
      >
        <TabHeader heading="Learn" />
        <LearnProgress numCompleted={numCompleted} numTotal={treatmentV2.length} />
        {feed ? (
          <View spacing={50} style={{ marginTop: 20 }}>
            {feed.map((section) => (
              <View spacing={10} key={section.title}>
                <View style={{ marginTop: 10 }}>
                  <Heading text={section.title} style={{}} level={2} />
                </View>
                {section.data.map((session, i) => {
                  const { slug, state, session: item } = session;
                  currentIndex += 1;

                  return (
                    <Fragment key={i}>
                      <SessionCard
                        hideHeader
                        collapsed
                        static
                        testID={`SessionCard_${slug}`}
                        state={state}
                        session={{ ...item }}
                        onPressBottom={() => {}}
                        onPressTop={() => {
                          navigateToStaticSession(session, 'sessions');
                        }}
                      />
                      {currentIndex === activeIndex ? (
                        <View style={{ marginTop: 20 }}>
                          <ScrollHere offsetY={0} />
                        </View>
                      ) : null}
                    </Fragment>
                  );
                })}
              </View>
            ))}
          </View>
        ) : (
          <ActivityIndicator />
        )}
      </ScrollView>
    </View>
  );
}
