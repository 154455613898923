import { ReactNode } from 'react';
import { StyleProp, TextStyle } from 'react-native';

import { Button } from '@src/components/Button';
import { Icon } from '@src/components/Icon';
import { ModalContainer } from '@src/components/ModalContainer';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useI18n } from '@src/lib/i18n';
import { Shadow, useTheme } from '@src/styles';

export function ConfirmationModal(props: {
  cancelTestID?: string;
  cancelText?: string;
  preTitle?: ReactNode;
  children?: ReactNode;
  confirmTestID?: string;
  confirmText?: string;
  description?: string | ReactNode;
  onCancel: null | (() => void);
  onConfirm: () => void;
  title: string;
  visible: boolean;
  showCloseIcon?: boolean;
  textStyle?: StyleProp<TextStyle>;
}) {
  const { Color } = useTheme();
  const { $t } = useI18n();

  return (
    <ModalContainer
      visible={props.visible}
      onRequestClose={() => {}}
      style={[
        {
          width: '90%',
          maxWidth: 400,
          backgroundColor: Color.backgroundColor,
          marginHorizontal: 10,
          borderRadius: 20,
          paddingVertical: 26,
          paddingHorizontal: 24,
        },
        Shadow.high,
      ]}
    >
      {props.preTitle}
      {props.showCloseIcon && !!props.onCancel ? (
        <View row>
          <Text text={props.title} size={21} weight="bold" textAlign="center" />
          <Icon
            name="close"
            onPress={props.onCancel}
            size={14}
            style={{
              marginLeft: 'auto',
            }}
            accessibilityLabel={$t({
              id: 'ConfirmationModal_closeButtonAccessibilityLabel',
              defaultMessage: 'Close modal',
            })}
          />
        </View>
      ) : (
        <Text text={props.title} size={21} weight="bold" textAlign="center" />
      )}
      {typeof props.description === 'string' ? (
        <Text
          text={props.description}
          textAlign={'center'}
          style={[props.textStyle, { marginTop: 16 }]}
        />
      ) : props.description ? (
        <View style={{ marginTop: 16 }}>{props.description}</View>
      ) : null}
      {props.children}
      <View
        row
        style={{ justifyContent: !!props.onCancel ? 'space-between' : 'center', marginTop: 32 }}
      >
        {!!props.onCancel && (
          <Button
            onPress={props.onCancel}
            text={
              props.cancelText ??
              $t({ id: 'ConfirmationModal_cancelButton', defaultMessage: 'Cancel' })
            }
            variant="text"
            testID={props.cancelTestID}
          />
        )}

        <Button
          onPress={props.onConfirm}
          text={
            props.confirmText ?? $t({ id: 'ConfirmationModal_confirmButton', defaultMessage: 'OK' })
          }
          variant="solid"
          testID={props.confirmTestID}
        />
      </View>
    </ModalContainer>
  );
}
