import { useNavigation } from '@react-navigation/core';
import { useFocusEffect } from '@react-navigation/native';
import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { v4 as uuid } from 'uuid';

import { ButtonAccordion } from '@oui/app-core/src/components/Accordion';
import { Button } from '@oui/app-core/src/components/Button';
import { CheckboxListInput } from '@oui/app-core/src/components/CheckboxListInput';
import { Divider } from '@oui/app-core/src/components/Divider';
import { ErrorPresenter } from '@oui/app-core/src/components/ErrorPresenter';
import { Icon } from '@oui/app-core/src/components/Icon';
import { ListItemTextInput } from '@oui/app-core/src/components/ListItemTextInput';
import { PhoneInput } from '@oui/app-core/src/components/PhoneInput';
import { Text } from '@oui/app-core/src/components/Text';
import { TextInput } from '@oui/app-core/src/components/TextInput';
import { UnorderedList } from '@oui/app-core/src/components/UnorderedList';
import { View } from '@oui/app-core/src/components/View';
import { MyStoryMyPlanCompositionDataHash } from '@oui/app-core/src/hooks/useComposition';
import { useContactsPickerResult } from '@oui/app-core/src/hooks/useContactsPickerResult';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { usePlacesPickerResult } from '@oui/app-core/src/hooks/usePlacesPickerResult';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { logEvent } from '@oui/app-core/src/lib/log';
import { useTheme } from '@oui/app-core/src/styles';
import {
  MyStoryMyPlanContact,
  transformExpoContactToMyStoryMyPlanContact,
} from '@oui/lib/src/transformExpoContactToResponseContact';
import { ProfessionalHelpContact } from '@oui/lib/src/types/avro';

import { ContactListItem } from '@src/components/Contact';
import { getCrisisTimelineSections } from '@src/components/CrisisTimelineList';
import { StackScreenProps } from '@src/types/navigation';

import '@src/components/Icon';

import { FormModal } from '@oui/app-core/src/components/FormModal';

export const BLANK_LOCATION = {
  ID: '',
  name: '',
  latitude: 0,
  longitude: 0,
};
export const PROFESSIONAL_SERVICES: ProfessionalHelpContact[] = [
  {
    ID: 'national-suicide-prevention-lifeline',
    firstName: 'National Suicide Prevention Lifeline',
    lastName: '',
    localDeviceID: '',
    imageBase64: 'https://storage.googleapis.com/asset.oui.dev/static/NSPL-logo.jpg',
    phone: '988',
  },
  {
    ID: 'veterans-lifeline',
    firstName: 'Veterans Crisis Line',
    lastName: '',
    localDeviceID: '',
    imageBase64: 'https://storage.googleapis.com/asset.oui.dev/static/veterans-logo.jpg',
    phone: '988',
  },
  {
    ID: 'trevor-project',
    firstName: 'The Trevor Project',
    lastName: '',
    localDeviceID: '',
    imageBase64: 'https://storage.googleapis.com/asset.oui.dev/static/trevor-logo.svg',
    phone: '1-866-488-7386',
  },
];

function Step(props: {
  testID?: string;
  num: number;
  isEditing?: boolean;
  isFocused?: boolean;
  isHighlighted?: boolean;
  title: string;
  description?: string;
  onEdit: (() => void) | null;
  editChildren: ReactNode;
  viewChildren: ReactNode;
}) {
  const { Color, theme } = useTheme();
  const isButton = !!(props.isFocused && props.onEdit && !props.isEditing);
  const { $t } = useI18n();
  const inner = (
    <View spacing={20}>
      <View
        row
        style={{
          alignItems: 'stretch',
          justifyContent: 'space-between',
        }}
        spacing={12}
      >
        <View
          row
          spacing={8}
          accessible
          accessibilityRole="header"
          flex={1}
          style={{
            paddingVertical: 12,
          }}
        >
          {props.num > 0 ? (
            <Text
              text={$t(
                { id: 'PatientMyPlan_step', defaultMessage: 'Step {number}' },
                { number: props.num },
              )}
            />
          ) : null}
          <Text text={props.title} weight="semibold" size={17} style={{ flex: 1 }} />
        </View>
        {!props.isEditing && props.onEdit ? (
          <TouchableOpacity
            onPress={props.onEdit}
            accessibilityLabel={`Edit ${props.title}`}
            accessibilityRole="button"
            testID="PatientMyPlan_editStepButton"
            style={{
              alignSelf: 'stretch',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 12,
            }}
            hitSlop={{ top: 20, left: 30, right: 30, bottom: 20 }}
          >
            <Icon name={props.isFocused ? 'arrow-right' : 'edit'} color={Color.accent} />
          </TouchableOpacity>
        ) : (
          <View
            style={{ opacity: 0, paddingLeft: 12 }}
            accessible={false}
            accessibilityElementsHidden
          >
            <Icon name="edit" accessibilityLabel={undefined} />
          </View>
        )}
      </View>
      {props.isEditing && props.description ? (
        <Text text={props.description} color={Color.styleGuide.Gray3} />
      ) : null}
      {props.isEditing ? props.editChildren : props.viewChildren}
    </View>
  );

  return isButton ? (
    <TouchableOpacity
      onPress={props.onEdit ?? undefined}
      accessibilityRole={props.onEdit ? 'button' : 'text'}
      accessibilityState={props.isFocused ? { selected: true } : undefined}
      disabled={!props.onEdit}
      testID={props.testID}
      style={
        props.num > 0 && props.isFocused && !props.isEditing
          ? {
              borderColor: Color.accent,
              borderWidth: 2,
              marginHorizontal: -20,
              marginVertical: -10,
              paddingHorizontal: 20,
              paddingVertical: 10,
            }
          : null
      }
    >
      {inner}
    </TouchableOpacity>
  ) : (
    <View
      testID={props.isHighlighted ? `${props.testID}__highlighted` : props.testID}
      style={
        props.isHighlighted
          ? {
              borderColor: theme.color.accentTwo100,
              backgroundColor: theme.color.accent300,
              borderWidth: 2,
              marginHorizontal: -20,
              marginVertical: -10,
              paddingHorizontal: 20,
              paddingVertical: 10,
            }
          : null
      }
    >
      {inner}
    </View>
  );
}

export function EnvironmentSafety({
  data,
  editingSection,
  focusedSection,
  highlightedSections,
  isEditing,
  onEdit,
  onStartEditingSection,
}: {
  data: MyStoryMyPlanCompositionDataHash;
  editingSection?: keyof MyStoryMyPlanCompositionDataHash | null;
  focusedSection?: keyof MyStoryMyPlanCompositionDataHash | null;
  highlightedSections?: Array<keyof MyStoryMyPlanCompositionDataHash> | null;
  isEditing?: boolean;
  onEdit: (data: Partial<MyStoryMyPlanCompositionDataHash>) => void;
  onStartEditingSection?: (section: keyof MyStoryMyPlanCompositionDataHash) => void;
}) {
  const { Color } = useTheme();
  const { $t } = useI18n();
  const removalStepsText = data.ENVIRONMENT_SAFETY.removalSteps.map(({ text }) => text);
  const { peak } = getCrisisTimelineSections(data.CRISIS_TIMELINE);
  // NOTE this is kinda a hack to detect if `gun` was a selected item in SoloRiskCurveStepBPeak
  // in the future would be nice to store that info explicitly or do a localized match for gun
  const showFirearmSuggetions = peak[0]?.peakMethods?.includes('gun');

  const recentHint =
    focusedSection === 'ENVIRONMENT_SAFETY' && peak[0] ? (
      <Text
        text={`Recently, ${peak[0].text}. I will do the following to secure items that I may use for suicide:`}
      />
    ) : null;

  return (
    <View spacing={20}>
      <Step
        testID="PatientMyPlan_environmentSafety"
        num={0}
        title={$t({
          id: 'PatientMyPlan_environmentSafety_title',
          defaultMessage: 'Making your environment safe',
        })}
        isEditing={editingSection === 'ENVIRONMENT_SAFETY'}
        isFocused={focusedSection === 'ENVIRONMENT_SAFETY'}
        isHighlighted={highlightedSections?.includes('ENVIRONMENT_SAFETY')}
        onEdit={
          (
            focusedSection
              ? MY_PLAN_STEPS.indexOf(focusedSection) >= MY_PLAN_STEPS.indexOf('ENVIRONMENT_SAFETY')
              : isEditing
          )
            ? () => onStartEditingSection?.('ENVIRONMENT_SAFETY')
            : null
        }
        editChildren={
          <View spacing={40}>
            <View spacing={12}>
              <Text
                text={$t({
                  id: 'PatientMyPlan_environmentSafety_removalStepsHeading',
                  defaultMessage: 'Ways to secure lethal means',
                })}
                weight="semibold"
                accessibilityRole="none"
              />
              {recentHint}
              <>
                <ListItemTextInput
                  testID="PatientMyPlan_environmentSafety_removalStepsInput"
                  accessibilityLabel={$t({
                    id: 'PatientMyPlan_environmentSafety_removalStepsLabel',
                    defaultMessage: 'Ways to secure lethal means',
                  })}
                  autoFocus
                  value={data.ENVIRONMENT_SAFETY.removalSteps ?? []}
                  onChangeValue={(newRemovalSteps) =>
                    onEdit({
                      ENVIRONMENT_SAFETY: {
                        ...data.ENVIRONMENT_SAFETY,
                        removalSteps: newRemovalSteps,
                      },
                    })
                  }
                  bulletColor={Color.accent}
                />
                <ButtonAccordion
                  text={$t({
                    id: 'PatientMyPlan_environmentSafety_selectButton',
                    defaultMessage: 'Select from suggested ways',
                  })}
                  defaultExpanded={focusedSection === 'ENVIRONMENT_SAFETY'}
                >
                  <CheckboxListInput
                    accessibilityLabel={$t({
                      id: 'PatientMyPlan_environmentSafety_suggestedItems',
                      defaultMessage: 'Suggested items',
                    })}
                    items={{
                      ...(showFirearmSuggetions ? SUGGESTED_GUN_REMOVAL_STEPS_ITEMS : null),
                      ...SUGGESTED_REMOVAL_STEPS_ITEMS,
                    }}
                    value={removalStepsText}
                    onChangeValue={(values) => {
                      const oldValues = removalStepsText.filter((v) => !values.includes(v));
                      const newValues = values.filter((v) => !removalStepsText.includes(v));
                      onEdit({
                        ENVIRONMENT_SAFETY: {
                          ...data.ENVIRONMENT_SAFETY,
                          removalSteps: [
                            ...data.ENVIRONMENT_SAFETY.removalSteps.filter(
                              ({ text }) => !oldValues.includes(text),
                            ),
                            ...newValues.map((text) => ({ ID: uuid(), text })),
                          ],
                        },
                      });
                    }}
                  />
                </ButtonAccordion>
              </>
            </View>
            <View spacing={12}>
              <Text
                text={$t({
                  id: 'PatientMyPlan_environmentSafety_supportContactsHeading',
                  defaultMessage: 'Who will help me secure these',
                })}
                weight="semibold"
              />
              <PatientMyPlanEditContacts
                section="ENVIRONMENT_SAFETY"
                contacts={data.ENVIRONMENT_SAFETY.supportContacts}
                onEdit={({ contacts }) => {
                  onEdit({
                    ENVIRONMENT_SAFETY: { ...data.ENVIRONMENT_SAFETY, supportContacts: contacts },
                  });
                }}
              />
            </View>
          </View>
        }
        viewChildren={
          data.ENVIRONMENT_SAFETY.removalSteps.length ? (
            <View spacing={40}>
              <View spacing={12}>
                <Text
                  text={$t({
                    id: 'PatientMyPlan_environmentSafety_removalStepsHeading',
                    defaultMessage: 'Ways to secure lethal means',
                  })}
                  weight="semibold"
                />
                {recentHint}
                <UnorderedList
                  items={data.ENVIRONMENT_SAFETY.removalSteps.map(({ text }) => text) ?? []}
                  color={Color.styleGuide.LogoCyan}
                  weight="normal"
                />
              </View>
              <View spacing={12}>
                <Text
                  text={$t({
                    id: 'PatientMyPlan_environmentSafety_supportContactsHeading',
                    defaultMessage: 'Who will help me secure these',
                  })}
                  weight="semibold"
                />
                {data.ENVIRONMENT_SAFETY.supportContacts.map((contact, i, arr) => (
                  <Fragment key={contact.ID}>
                    <ContactListItem
                      imageSize={40}
                      imageBorderRadius
                      nameWeight="normal"
                      name={{ first: contact.firstName, last: contact.lastName }}
                      image={
                        contact.imageAssetKey
                          ? { context: 'ENVIRONMENT_SAFETY', key: contact.imageAssetKey }
                          : contact.imageBase64
                      }
                    />
                    {i < arr.length - 1 ? <Divider /> : null}
                  </Fragment>
                ))}
              </View>
            </View>
          ) : null
        }
      />
    </View>
  );
}

function PatientMyPlanEditPlaces({
  value,
  onChangeValue,
}: {
  value: MyStoryMyPlanCompositionDataHash['SOCIAL_DISTRACTIONS']['places'];
  onChangeValue: (
    newValue: MyStoryMyPlanCompositionDataHash['SOCIAL_DISTRACTIONS']['places'],
  ) => unknown;
}) {
  const { Color } = useTheme();
  const { $t } = useI18n();
  const [fullname, setFullname] = useState('');
  const { navigate } = useNavigation<StackScreenProps<'EditMyPlan'>['navigation']>();
  const [showManualLocationModal, setShowManualLocationModal] = useState(false);

  function addManualPlace(place: string) {
    onChangeValue([
      ...value,
      {
        name: place,
        ID: uuid(),
        latitude: 0,
        longitude: 0,
      },
    ]);
  }

  function removePlace(ID: string) {
    onChangeValue(value.filter((v) => v.ID !== ID));
  }

  const returnRoute = usePlacesPickerResult(({ places, permissionDenied }) => {
    if (!permissionDenied) {
      onChangeValue([...value, ...places]);
    }
  });

  return (
    <View flex={1}>
      <View>
        {value.length ? (
          value.map((place, i, arr) => (
            <Fragment key={place.ID}>
              <View row>
                <View flex={1}>
                  <ContactListItem
                    imageSize={40}
                    nameWeight="normal"
                    name={{ first: place.name, last: '' }}
                  />
                </View>
                <Icon
                  accessibilityLabel={$t({
                    id: 'PatientMyPlanEditPlaces_removeButton',
                    defaultMessage: 'Remove place',
                  })}
                  size={14}
                  name="close"
                  onPress={() => removePlace(place.ID)}
                  color={Color.styleGuide.Gray4}
                />
              </View>
              {i < arr.length - 1 ? <Divider /> : null}
            </Fragment>
          ))
        ) : (
          <View
            row
            spacing={12}
            accessible
            accessibilityLabel={$t({
              id: 'PatientMyPlanEditPlaces_placeholderAccessibilityLabel',
              defaultMessage: 'Location placeholder',
            })}
          >
            <View
              row
              style={{
                borderRadius: 10,
                backgroundColor: Color.styleGuide.Gray7,
                width: 35,
                height: 35,
              }}
            />
            <Text
              text={$t({
                id: 'PatientMyPlanEditPlaces_placeholder',
                defaultMessage: 'Add a location',
              })}
              color={Color.styleGuide.Gray5}
            />
          </View>
        )}
      </View>
      <View style={{ marginTop: 40 }} spacing={12}>
        <Button
          text={$t({ id: 'PatientMyPlanEditPlaces_addButton', defaultMessage: 'Add from map' })}
          testID="PatientMyPlanEditPlaces_addButton"
          alignSelf="center"
          icon="location"
          onPress={() => {
            navigate('PlacesPicker', { returnRoute });
          }}
        />

        <Button
          text={$t({
            id: 'PatientMyPlanEditPlaces_addManuallyButton',
            defaultMessage: 'Or add manually',
          })}
          testID="PatientMyPlanEditPlaces_addManuallyButton"
          alignSelf="center"
          variant="text"
          size="small"
          color={Color.styleGuide.Gray4}
          onPress={() => {
            setShowManualLocationModal(true);
          }}
        />

        <FormModal
          visible={!!showManualLocationModal}
          onCancel={() => {
            setFullname('');
            setShowManualLocationModal(false);
          }}
          onConfirm={async () => {
            addManualPlace(fullname);
            setFullname('');
            setShowManualLocationModal(false);
          }}
          disableButton={!fullname.length}
          confirmText={$t({
            id: 'PatientMyPlanEditPlaces_saveManuallyButton',
            defaultMessage: 'Add place',
          })}
          confirmTestID="PatientMyPlanEditPlaces_saveManuallyButton"
          title={$t({
            id: 'PatientMyPlanEditPlaces_saveManuallyTitle',
            defaultMessage: 'Place',
          })}
        >
          <View
            style={{
              backgroundColor: Color.grayBackground,
              marginHorizontal: -20,
              padding: 20,
            }}
          >
            <TextInput
              value={fullname}
              onChangeValue={setFullname}
              placeholder={$t({
                id: 'PatientMyPlanEditPlaces_locationInputPlaceholder',
                defaultMessage: 'Location name',
              })}
              testID="PatientMyPlanEditPlaces_locationInput"
            />
          </View>
        </FormModal>
      </View>
    </View>
  );
}

function PatientMyPlanEditContacts({
  section,
  contacts,
  onEdit,
}: {
  section: keyof MyStoryMyPlanCompositionDataHash;
  contacts: ReadonlyArray<MyStoryMyPlanContact>;
  onEdit: (data: { contacts: MyStoryMyPlanContact[] }) => void;
}) {
  const [showManualContactModal, setShowManualContactModal] = useState(false);

  const { Color } = useTheme();
  const { $t } = useI18n();
  const { navigate } = useNavigation<StackScreenProps<'EditMyPlan'>['navigation']>();
  const { validate, clear, bind, data, humanErrors } = useForm<MyStoryMyPlanContact>({
    ID: '',
    firstName: '',
    lastName: '',
    phone: '',
    // These fields are part of MyStoryMyPlanContact but aren't actually used in this form
    localDeviceID: '',
    imageBase64: '',
  });

  function addNewContacts(newContacts: MyStoryMyPlanContact[]) {
    onEdit({ contacts: [...contacts, ...newContacts] });
  }

  const returnRoute = useContactsPickerResult(
    async ({ contacts: expoContacts, permissionDenied }) => {
      if (!permissionDenied) {
        const newContacts = expoContacts.map((expoContact) => {
          const transformedContact = transformExpoContactToMyStoryMyPlanContact(expoContact);
          if (expoContact.image?.uri) {
            transformedContact.imageAssetKey = expoContact.image.uri;
          }
          return transformedContact;
        });
        addNewContacts(newContacts);
      }
    },
  );

  return (
    <View>
      {contacts.length ? (
        contacts.map((contact, i, arr) => (
          <Fragment key={i}>
            <View row style={{ justifyContent: 'space-between' }}>
              <View flex={1}>
                <ContactListItem
                  imageSize={40}
                  imageBorderRadius
                  nameWeight="normal"
                  name={{ first: contact.firstName, last: contact.lastName }}
                  image={
                    contact.imageAssetKey
                      ? { context: section, key: contact.imageAssetKey }
                      : contact.imageBase64
                  }
                />
              </View>
              <Icon
                accessibilityLabel={$t({
                  id: 'PatientMyPlanEditContacts_removeButton',
                  defaultMessage: 'Remove contact',
                })}
                name="close"
                color={Color.styleGuide.Gray4}
                size={10}
                onPress={() => {
                  onEdit({ contacts: contacts?.filter((_, j) => i !== j) });
                }}
              />
            </View>
            {i < arr.length - 1 ? <Divider /> : null}
          </Fragment>
        ))
      ) : (
        <View
          row
          spacing={12}
          accessibilityLabel={$t({
            id: 'PatientMyPlanEditContacts_placeholderAccessibilityLabel',
            defaultMessage: 'Contact placeholder',
          })}
          accessible
        >
          <View
            row
            style={{
              borderRadius: 30,
              backgroundColor: Color.styleGuide.Gray7,
              width: 35,
              height: 35,
            }}
          />
          <Text
            text={$t({
              id: 'PatientMyPlanEditContacts_placeholder',
              defaultMessage: 'Add a contact',
            })}
            color={Color.styleGuide.Gray5}
          />
        </View>
      )}
      <View style={{ marginTop: 40 }} spacing={12}>
        <Button
          text={$t({
            id: 'PatientMyPlanEditContacts_addButton',
            defaultMessage: 'Add from contacts',
          })}
          testID="PatientMyPlanEditContacts_addButton"
          alignSelf="center"
          icon="plus"
          onPress={() => {
            navigate('ContactsPicker', { returnRoute });
          }}
        />

        <Button
          text={$t({
            id: 'PatientMyPlanEditContacts_addManuallyButton',
            defaultMessage: 'Or add manually',
          })}
          testID="PatientMyPlanEditContacts_addManuallyButton"
          alignSelf="center"
          variant="text"
          size="small"
          color={Color.styleGuide.Gray4}
          onPress={() => {
            setShowManualContactModal(true);
          }}
        />

        <FormModal
          visible={!!showManualContactModal}
          onCancel={() => {
            clear();
            setShowManualContactModal(false);
          }}
          onConfirm={async () => {
            if (validate()) {
              addNewContacts([{ ...data, ID: uuid() }]);
              clear();
              setShowManualContactModal(false);
            }
          }}
          confirmText={$t({
            id: 'PatientMyPlanEditContacts_saveManuallyButton',
            defaultMessage: 'Add contact',
          })}
          confirmTestID="PatientMyPlanEditContacts_saveManuallyButton"
          title={$t({
            id: 'PatientMyPlanEditContacts_saveManuallyTitle',
            defaultMessage: 'Contact details',
          })}
        >
          <View
            spacing={20}
            style={{
              backgroundColor: Color.grayBackground,
              marginHorizontal: -20,
              padding: 20,
            }}
          >
            {Object.keys(humanErrors).length ? <ErrorPresenter formErrors={humanErrors} /> : null}
            <TextInput
              placeholder={$t({
                id: 'PatientMyPlanEditContacts_givenNamePlaceholder',
                defaultMessage: 'Sally',
              })}
              {...bind(['firstName'], {
                validator: { type: 'present' },
                label: $t({
                  id: 'PatientMyPlanEditContacts_givenNameLabel',
                  defaultMessage: 'First name*',
                }),
              })}
            />
            <TextInput
              placeholder={$t({
                id: 'PatientMyPlanEditContacts_familyNamePlaceholder',
                defaultMessage: 'Smith',
              })}
              {...bind(['lastName'], {
                validator: { type: 'present' },
                label: $t({
                  id: 'PatientMyPlanEditContacts_familyNameLabel',
                  defaultMessage: 'Last name*',
                }),
              })}
            />
            <PhoneInput
              {...bind(['phone'], {
                validator: { type: 'phone', allowBlank: true },
                label: $t({
                  id: 'PatientMyPlanEditContacts_phoneLabel',
                  defaultMessage: 'Phone number',
                }),
              })}
            />
          </View>
        </FormModal>
      </View>
    </View>
  );
}

export const MY_PLAN_STEPS: Array<keyof MyStoryMyPlanCompositionDataHash> = [
  'WARNING_SIGNS',
  'COPING_STRATEGIES',
  'SOCIAL_DISTRACTIONS',
  'HELP_CONTACTS',
  'PROFESSIONAL_HELP_CONTACTS',
  'ENVIRONMENT_SAFETY',
];

const SUGGESTED_COPING_STRATEGIES = [
  'Watch a movie or TV show',
  'Listen to music',
  'Sing',
  'Play with a pet',
  'Go for a walk or exercising',
  'Take a warm bath or shower',
  'Read a book',
  'Read spiritual or religious material',
  'Meditate',
  'Do relaxation or breathing exercises',
  'Pray',
  'Do a puzzle',
  'Think about a positive upcoming event',
  'Think about positive memories',
  'Think about loved ones',
  'Think about the future',
  'Focus on the important things in my life',
  'Look at pictures of friends',
  'Read letters or emails from family members',
  'Eat a favorite food',
  'Cook or bake',
  'Play a sport',
];
const SUGGESTED_COPING_STRATEGIES_ITEMS = SUGGESTED_COPING_STRATEGIES.reduce<
  Record<string, string>
>((carry, el) => {
  carry[el] = el;
  return carry;
}, {});

const INPATIENT_SUGGESTED_COPING_STRATEGIES = [
  'Do relaxation or mindfulness',
  'Watch a movie or TV show',
  'Listen to music',
  'Sing',
  'Play with a pet',
  'Go for a walk or exercising',
  'Take a warm bath or shower',
  'Read a book',
  'Read spiritual or religious material',
  'Meditate',
  'Pray',
  'Do a puzzle',
  'Think about a positive upcoming event',
  'Think about positive memories',
  'Think about loved ones',
  'Think about the future',
  'Focus on the important things in my life',
  'Look at pictures of friends',
  'Read letters or emails from family members',
  'Eat a favorite food',
  'Cook or bake',
  'Play a sport',
];

const INPATIENT_SUGGESTED_COPING_STRATEGIES_ITEMS = INPATIENT_SUGGESTED_COPING_STRATEGIES.reduce<
  Record<string, string>
>((carry, el) => {
  carry[el] = el;
  return carry;
}, {});

const SUGGESTED_GUN_REMOVAL_STEPS = [
  'Give key piece of gun to family or friend',
  'Store gun in safe held by family or friend',
  'Store unloaded gun & ammo separately',
  'Use a caplock or trigger lock',
];
const SUGGESTED_GUN_REMOVAL_STEPS_ITEMS = SUGGESTED_GUN_REMOVAL_STEPS.reduce<
  Record<string, string>
>((carry, el) => {
  carry[el] = el;
  return carry;
}, {});
const SUGGESTED_REMOVAL_STEPS = [
  'Give to family or friend',
  'Dispose of',
  'Tell someone I live with to hold it for me',
  'Lock away and give key to someone else',
];
const SUGGESTED_REMOVAL_STEPS_ITEMS = SUGGESTED_REMOVAL_STEPS.reduce<Record<string, string>>(
  (carry, el) => {
    carry[el] = el;
    return carry;
  },
  {},
);

export function PatientMyPlan({
  data,
  editingSection,
  focusedSection,
  highlightedSections,
  isEditing,
  onEdit,
  onStartEditingSection,
}: {
  data: MyStoryMyPlanCompositionDataHash;
  editingSection?: keyof MyStoryMyPlanCompositionDataHash | null;
  focusedSection?: keyof MyStoryMyPlanCompositionDataHash | null;
  highlightedSections?: Array<keyof MyStoryMyPlanCompositionDataHash> | null;
  isEditing?: boolean;
  onEdit: (data: Partial<MyStoryMyPlanCompositionDataHash>) => void;
  onStartEditingSection?: (section: keyof MyStoryMyPlanCompositionDataHash) => void;
}) {
  const { Color } = useTheme();
  const { $t } = useI18n();
  const { navigate } = useNavigation<StackScreenProps<'MyPlan'>['navigation']>();
  const warningSignSuggestionItems = useMemo(() => {
    const { before } = getCrisisTimelineSections(data.CRISIS_TIMELINE);
    return before.reduce<Record<string, string>>((carry, { ID, text }) => {
      carry[ID] = text;
      return carry;
    }, {});
  }, [data.CRISIS_TIMELINE]);

  useFocusEffect(
    useCallback(() => {
      logEvent('view_myplan');
    }, []),
  );

  const { user } = useCurrentPatient();

  const warningSignIDs = data.WARNING_SIGNS.map(({ ID }) => ID);
  const warningSigns = (
    <Step
      testID="PatientMyPlan_warningSigns"
      num={1}
      title={$t({ id: 'PatientMyPlan_warningSigns_title', defaultMessage: 'Warning signs' })}
      description={$t({
        id: 'PatientMyPlan_warningSigns_description',
        defaultMessage:
          'Warning signs are thoughts, feelings, or behaviors that you have before a suicidal crisis escalates. These serve as a cue to use the MyPlan.',
      })}
      isEditing={editingSection === 'WARNING_SIGNS'}
      isFocused={focusedSection === 'WARNING_SIGNS'}
      isHighlighted={highlightedSections?.includes('WARNING_SIGNS')}
      onEdit={
        (
          focusedSection
            ? MY_PLAN_STEPS.indexOf(focusedSection) >= MY_PLAN_STEPS.indexOf('WARNING_SIGNS')
            : isEditing
        )
          ? () => onStartEditingSection?.('WARNING_SIGNS')
          : null
      }
      editChildren={
        <>
          <ListItemTextInput
            accessibilityLabel={$t({
              id: 'PatientMyPlan_warningSigns_title',
              defaultMessage: 'Warning signs',
            })}
            testID="PatientMyPlan_warningSignsInput"
            autoFocus
            value={data.WARNING_SIGNS ?? []}
            onChangeValue={(newWarningSigns) => onEdit({ WARNING_SIGNS: newWarningSigns })}
            bulletColor={Color.accent}
          />
          {Object.keys(warningSignSuggestionItems).length ? (
            <ButtonAccordion
              text={$t({
                id: 'PatientMyPlan_warningSigns_selectButton',
                defaultMessage: 'Select from your risk curve',
              })}
              defaultExpanded={focusedSection === 'WARNING_SIGNS'}
            >
              <CheckboxListInput
                accessibilityLabel={$t({
                  id: 'PatientMyPlan_warningSigns_suggestedItems',
                  defaultMessage: 'Suggested items',
                })}
                items={warningSignSuggestionItems}
                value={warningSignIDs}
                onChangeValue={(values) => {
                  const oldValues = warningSignIDs.filter((v) => !values.includes(v));
                  const newValues = values.filter((v) => !warningSignIDs.includes(v));
                  onEdit({
                    WARNING_SIGNS: [
                      ...data.WARNING_SIGNS.filter(({ ID }) => !oldValues.includes(ID)),
                      ...newValues.map((ID) => ({ ID, text: warningSignSuggestionItems[ID] })),
                    ],
                  });
                }}
              />
            </ButtonAccordion>
          ) : null}
        </>
      }
      viewChildren={
        data.WARNING_SIGNS.length ? (
          <UnorderedList
            testID="PatientMyPlan_warningSignsList"
            items={data.WARNING_SIGNS.map(({ text }) => text) ?? []}
            color="#CB3D3D"
            weight="normal"
          />
        ) : null
      }
    />
  );

  const selfManagementTexts = data.COPING_STRATEGIES.map(({ text }) => text);
  const selfManagement = (
    <Step
      testID="PatientMyPlan_selfManagement"
      num={2}
      title={$t({ id: 'PatientMyPlan_selfManagement_title', defaultMessage: 'Coping strategies' })}
      description={$t({
        id: 'PatientMyPlan_selfManagement_description',
        defaultMessage:
          'These are skills and techniques you find helpful for coping during times of stress.',
      })}
      isEditing={editingSection === 'COPING_STRATEGIES'}
      isFocused={focusedSection === 'COPING_STRATEGIES'}
      isHighlighted={highlightedSections?.includes('COPING_STRATEGIES')}
      onEdit={
        (
          focusedSection
            ? MY_PLAN_STEPS.indexOf(focusedSection) >= MY_PLAN_STEPS.indexOf('COPING_STRATEGIES')
            : isEditing
        )
          ? () => onStartEditingSection?.('COPING_STRATEGIES')
          : null
      }
      editChildren={
        <>
          <ListItemTextInput
            accessibilityLabel={$t({
              id: 'PatientMyPlan_selfManagement_title',
              defaultMessage: 'Coping strategies',
            })}
            testID="PatientMyPlan_selfManagementInput"
            autoFocus
            value={data.COPING_STRATEGIES ?? []}
            onChangeValue={(newCoping) => onEdit({ COPING_STRATEGIES: newCoping })}
            bulletColor={Color.styleGuide.LogoCyan}
          />
          <ButtonAccordion
            text={$t({
              id: 'PatientMyPlan_selfManagement_selectButton',
              defaultMessage: 'Select from suggested strategies',
            })}
            defaultExpanded={focusedSection === 'COPING_STRATEGIES'}
          >
            <CheckboxListInput
              accessibilityLabel={$t({
                id: 'PatientMyPlan_selfManagement_suggestedItems',
                defaultMessage: 'Suggested items',
              })}
              items={
                user?.productVariant === 'AVIVA_INPATIENT'
                  ? INPATIENT_SUGGESTED_COPING_STRATEGIES_ITEMS
                  : SUGGESTED_COPING_STRATEGIES_ITEMS
              }
              customLabels={{
                'Do relaxation or mindfulness': (
                  <Button
                    text={'Do relaxation or mindfulness'}
                    variant="text"
                    alignSelf="center"
                    size="small"
                    onPress={() => {
                      // @ts-expect-error TODO
                      navigate('Relaxation', {});
                    }}
                  />
                ),
              }}
              value={selfManagementTexts}
              onChangeValue={(values) => {
                const oldValues = selfManagementTexts.filter((v) => !values.includes(v));
                const newValues = values.filter((v) => !selfManagementTexts.includes(v));
                onEdit({
                  COPING_STRATEGIES: [
                    ...data.COPING_STRATEGIES.filter(({ text }) => !oldValues.includes(text)),
                    ...newValues.map((text) => ({ ID: uuid(), text })),
                  ],
                });
              }}
            />
          </ButtonAccordion>
        </>
      }
      viewChildren={
        data.COPING_STRATEGIES.length ? (
          <UnorderedList
            testID="PatientMyPlan_selfManagementList"
            items={data.COPING_STRATEGIES.map(({ text }) => text) ?? []}
            customLabels={{
              'Do relaxation or mindfulness': (
                <View
                  row
                  spacing={6}
                  style={{
                    marginTop: -4,
                    marginLeft: -2,
                  }}
                >
                  <Button
                    text={'Do relaxation or mindfulness'}
                    variant="text"
                    size="small"
                    onPress={() => {
                      // @ts-expect-error TODO
                      navigate('Relaxation', {});
                    }}
                  />
                  <Icon
                    name="arrow-right"
                    color={Color.accent}
                    size={16}
                    style={{
                      marginTop: 2,
                    }}
                  />
                </View>
              ),
            }}
            color={Color.styleGuide.LogoCyan}
            weight="normal"
          />
        ) : null
      }
    />
  );

  const socialDistractions = (
    <Step
      testID="PatientMyPlan_socialDistractions"
      num={3}
      title={$t({
        id: 'PatientMyPlan_socialDistractions_title',
        defaultMessage: 'Social distractions',
      })}
      description={$t({
        id: 'PatientMyPlan_socialDistractions_description',
        defaultMessage:
          'Talking to others or going to places with other people help you not think about your problems which allows time for the suicidal thoughts to subside. People in this step are not to be used to share or discuss a crisis.',
      })}
      isEditing={editingSection === 'SOCIAL_DISTRACTIONS'}
      isFocused={focusedSection === 'SOCIAL_DISTRACTIONS'}
      isHighlighted={highlightedSections?.includes('SOCIAL_DISTRACTIONS')}
      onEdit={
        (
          focusedSection
            ? MY_PLAN_STEPS.indexOf(focusedSection) >= MY_PLAN_STEPS.indexOf('SOCIAL_DISTRACTIONS')
            : isEditing
        )
          ? () => onStartEditingSection?.('SOCIAL_DISTRACTIONS')
          : null
      }
      editChildren={
        <View spacing={12}>
          <Text
            text={$t({
              id: 'PatientMyPlan_socialDistractions_peopleHeader',
              defaultMessage: 'People that provide distraction',
            })}
            weight="semibold"
            size={17}
            accessibilityRole="header"
            color={Color.styleGuide.Gray2}
            style={{ marginTop: 20 }}
          />
          <PatientMyPlanEditContacts
            section="SOCIAL_DISTRACTIONS"
            contacts={data.SOCIAL_DISTRACTIONS.contacts}
            onEdit={({ contacts: newContacts }) =>
              onEdit({
                SOCIAL_DISTRACTIONS: { ...data.SOCIAL_DISTRACTIONS, contacts: newContacts },
              })
            }
          />
          <Text
            text={$t({
              id: 'PatientMyPlan_socialDistractions_placesHeader',
              defaultMessage: 'Places that provide distraction',
            })}
            weight="semibold"
            size={17}
            accessibilityRole="header"
            color={Color.styleGuide.Gray2}
            style={{ marginTop: 30 }}
          />
          <PatientMyPlanEditPlaces
            value={data.SOCIAL_DISTRACTIONS.places}
            onChangeValue={(newPlaces) =>
              onEdit({
                SOCIAL_DISTRACTIONS: { ...data.SOCIAL_DISTRACTIONS, places: newPlaces },
              })
            }
          />
        </View>
      }
      viewChildren={
        data.SOCIAL_DISTRACTIONS.contacts.length || data.SOCIAL_DISTRACTIONS.places.length ? (
          <View>
            {data.SOCIAL_DISTRACTIONS.contacts.map((c, i, arr) => (
              <Fragment key={i}>
                <ContactListItem
                  imageSize={40}
                  imageBorderRadius
                  name={{ first: c.firstName, last: c.lastName }}
                  nameWeight="normal"
                  image={
                    c.imageAssetKey
                      ? { context: 'SOCIAL_DISTRACTIONS', key: c.imageAssetKey }
                      : c.imageBase64
                  }
                  phone={isEditing ? undefined : c.phone}
                />
                {i < arr.length - 1 || data.SOCIAL_DISTRACTIONS.places.length ? <Divider /> : null}
              </Fragment>
            ))}
            {data.SOCIAL_DISTRACTIONS.places.map((place, i, arr) => (
              <Fragment key={place.ID}>
                <ContactListItem
                  testID={`PatientMyPlan_socialDistractions_place_${i}`}
                  imageSize={40}
                  key={place.ID}
                  name={{ first: place.name, last: '' }}
                  nameWeight="normal"
                />
                {i < arr.length - 1 ? <Divider /> : null}
              </Fragment>
            ))}
          </View>
        ) : null
      }
    />
  );

  const helpContacts = (
    <Step
      testID="PatientMyPlan_helpContacts"
      num={4}
      title={$t({
        id: 'PatientMyPlan_helpContacts_title',
        defaultMessage: 'People I can ask for help',
      })}
      description={$t({
        id: 'PatientMyPlan_helpContacts_description',
        defaultMessage:
          'These are people you can reach out to when you need help if you are reaching or in a crisis.',
      })}
      isEditing={editingSection === 'HELP_CONTACTS'}
      isFocused={focusedSection === 'HELP_CONTACTS'}
      isHighlighted={highlightedSections?.includes('HELP_CONTACTS')}
      onEdit={
        (
          focusedSection
            ? MY_PLAN_STEPS.indexOf(focusedSection) >= MY_PLAN_STEPS.indexOf('HELP_CONTACTS')
            : isEditing
        )
          ? () => onStartEditingSection?.('HELP_CONTACTS')
          : null
      }
      editChildren={
        <PatientMyPlanEditContacts
          section="HELP_CONTACTS"
          contacts={data.HELP_CONTACTS}
          onEdit={({ contacts: newContacts }) =>
            onEdit({
              HELP_CONTACTS: newContacts,
            })
          }
        />
      }
      viewChildren={
        data.HELP_CONTACTS.length ? (
          <View>
            {data.HELP_CONTACTS.map((contact, i, arr) => (
              <Fragment key={contact.ID}>
                <ContactListItem
                  imageSize={40}
                  imageBorderRadius
                  nameWeight="normal"
                  name={{ first: contact.firstName, last: contact.lastName }}
                  image={
                    contact.imageAssetKey
                      ? { context: 'HELP_CONTACTS', key: contact.imageAssetKey }
                      : contact.imageBase64
                  }
                />
                {i === arr.length - 1 ? null : <Divider />}
              </Fragment>
            ))}
          </View>
        ) : null
      }
    />
  );

  const professionalHelpContacts = (
    <Step
      testID="PatientMyPlan_professionalHelpContacts"
      num={5}
      title={$t({
        id: 'PatientMyPlan_professionalHelpContacts_title',
        defaultMessage: 'Professional help',
      })}
      description={$t({
        id: 'PatientMyPlan_professionalHelpContacts_description',
        defaultMessage: 'These are professionals who can help you in a crisis.',
      })}
      isEditing={editingSection === 'PROFESSIONAL_HELP_CONTACTS'}
      isFocused={focusedSection === 'PROFESSIONAL_HELP_CONTACTS'}
      isHighlighted={highlightedSections?.includes('PROFESSIONAL_HELP_CONTACTS')}
      onEdit={
        (
          focusedSection
            ? MY_PLAN_STEPS.indexOf(focusedSection) >=
              MY_PLAN_STEPS.indexOf('PROFESSIONAL_HELP_CONTACTS')
            : isEditing
        )
          ? () => onStartEditingSection?.('PROFESSIONAL_HELP_CONTACTS')
          : null
      }
      editChildren={
        <PatientMyPlanEditContacts
          section="PROFESSIONAL_HELP_CONTACTS"
          contacts={data.PROFESSIONAL_HELP_CONTACTS.map(({ contact }) => contact)}
          onEdit={({ contacts: newContacts }) =>
            onEdit({
              PROFESSIONAL_HELP_CONTACTS: newContacts.map((c) => ({
                contact: c,
                location: BLANK_LOCATION,
              })),
            })
          }
        />
      }
      viewChildren={
        data.PROFESSIONAL_HELP_CONTACTS.length ? (
          <View>
            {data.PROFESSIONAL_HELP_CONTACTS.map(({ contact }, i, arr) => (
              <Fragment key={contact.ID}>
                <ContactListItem
                  imageSize={40}
                  imageBorderRadius
                  nameWeight="normal"
                  name={{ first: contact.firstName, last: contact.lastName }}
                  phone={contact.phone}
                  image={
                    contact.imageAssetKey
                      ? { context: 'PROFESSIONAL_HELP_CONTACTS', key: contact.imageAssetKey }
                      : contact.imageBase64
                  }
                />
                {i === arr.length - 1 ? null : <Divider />}
              </Fragment>
            ))}
          </View>
        ) : null
      }
    />
  );

  switch (editingSection) {
    case 'WARNING_SIGNS': {
      return warningSigns;
    }
    case 'COPING_STRATEGIES': {
      return selfManagement;
    }
    case 'SOCIAL_DISTRACTIONS': {
      return socialDistractions;
    }
    case 'HELP_CONTACTS': {
      return helpContacts;
    }
    case 'PROFESSIONAL_HELP_CONTACTS': {
      return professionalHelpContacts;
    }
    default: {
      return (
        <View spacing={focusedSection ? 30 : 50}>
          {warningSigns}
          {selfManagement}
          {socialDistractions}
          {helpContacts}
          {professionalHelpContacts}
        </View>
      );
    }
  }
}
