import isEqual from 'lodash/isEqual';
import { ComponentProps, ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Checkbox } from '@src/components/Checkbox';
import { View } from '@src/components/View';
import { AccessibleInput } from '@src/hooks/useAccessibleInput';
import { useTheme } from '@src/styles';

type Props<T> = {
  disabled?: boolean;
  error?: string;
  horizontal?: boolean;
  items: {
    label: string;
    value: T;
    key?: string;
    selectedChild?: ReactNode;
    selectedStyle?: StyleProp<ViewStyle>;
    style?: StyleProp<ViewStyle>;
  }[];
  onChangeValue?: (value: T) => void;
  style?: StyleProp<ViewStyle>;
  innerStyle?: StyleProp<ViewStyle>;
  testID?: string;
  value?: T | null;
  labelWeight?: ComponentProps<typeof Checkbox>['labelWeight'];
  optionLabelWeight?: ComponentProps<typeof Checkbox>['labelWeight'];
  spacing?: number;
  labelSize?: 'large' | 'normal';
  labelSpacing?: number;
} & (
  | {
      label: string;
      accessibilityLabel?: string;
    }
  | {
      label?: () => ReactNode;
      accessibilityLabel: string;
    }
);

function RadioInput<T>({
  disabled,
  horizontal,
  items,
  onChangeValue,
  value,
  innerStyle,
  labelSize,
  labelSpacing,
  ...props
}: Props<T>) {
  const { theme } = useTheme();

  return (
    <AccessibleInput
      {...props}
      labelSize={labelSize === 'large' ? 21 : undefined}
      labelLineHeight={labelSize === 'large' ? 28 : undefined}
    >
      {(accessibleProps) => (
        <View
          {...accessibleProps}
          accessibilityRole="radiogroup"
          spacing={props.spacing ?? theme.checkbox.optionSpacing}
          row={horizontal}
          style={[
            { marginLeft: 16 },
            horizontal ? { minHeight: 50 } : null,
            innerStyle,
            labelSize === 'large' ? { marginTop: (labelSpacing ?? 20) - 5 } : null,
          ]}
          testID={props.testID}
        >
          {items.map((item, i) => {
            const isSelected = isEqual(value, item.value);
            return (
              <View
                key={item.key || item.label}
                style={isSelected ? [item.style, item.selectedStyle] : item.style}
                spacing={10}
              >
                <Checkbox
                  disabled={disabled}
                  value={isSelected}
                  onChangeValue={() => onChangeValue && onChangeValue(item.value)}
                  variant="radio"
                  label={item.label}
                  labelWeight={props.optionLabelWeight ?? props.labelWeight}
                  horizontal
                  testID={props.testID ? `${props.testID}_choice_${i}` : undefined}
                />
                {isSelected ? item.selectedChild : null}
              </View>
            );
          })}
        </View>
      )}
    </AccessibleInput>
  );
}

export { RadioInput };
/**
 * @deprecated Prefer named export
 */
export default RadioInput;
