import { useNavigation } from '@react-navigation/native';
import { StyleProp, ViewStyle } from 'react-native';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { SegmentedProgressBar } from '@oui/app-core/src/components/SegmentedProgressBar';
import { SessionCard } from '@oui/app-core/src/components/SessionCard';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { useFeed } from '@oui/app-core/src/hooks/useFeed';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useNavigateToStaticSessionHook } from '@oui/app-static/src/hooks/useNavigateToStaticSessionHook';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { RootStackScreenProps } from '@src/types/navigation';

import { useNextSessionQuery } from './NextSession.graphql.generated';

export function NextSession({ style }: { style?: StyleProp<ViewStyle> }) {
  const { user } = useCurrentPatient();
  const { $t } = useI18n();
  const navigation = useNavigation<RootStackScreenProps<'home'>['navigation']>();
  const { navigateToStaticSession } = useNavigateToStaticSessionHook({ navigation });

  const { data, loading } = useNextSessionQuery({
    skip: !user,
    variables: {
      patientID: user?.ID as GQLUUID,
    },
  });

  const { activeItem } = useFeed(data?.treatmentV2);

  const completedCount =
    data?.patientByPatientID?.progress?.filter((item) => item.completed).length ?? 0;
  const totalCount = data?.treatmentV2?.length ?? 0;
  const allCompleted = completedCount === totalCount;

  return (
    <>
      {loading ? <ActivityIndicator testID="NextSession_activityIndicator" /> : null}
      {!allCompleted ? (
        <View style={style}>
          <Heading
            text={$t({
              id: 'NextSession_header',
              defaultMessage: 'Next session',
            })}
            level={2}
            style={{ marginBottom: 12 }}
          />
          {data ? (
            <View spacing={10} testID={`NextSession`}>
              <SessionCard
                collapsed
                testID={`SessionCard_${activeItem.slug}`}
                state={activeItem.state}
                session={activeItem.session}
                onPressBottom={() => {}}
                onPressTop={() => {
                  navigateToStaticSession(activeItem, 'home');
                }}
              />

              <View
                row
                spacing={10}
                style={{ marginLeft: 6 }}
                accessible
                accessibilityLabel={$t(
                  {
                    id: 'NextSession_sessionsDoneAccessibilityLabel',
                    defaultMessage: 'Session progress: {completedCount} of {totalCount} complete',
                  },
                  {
                    completedCount,
                    totalCount,
                  },
                )}
              >
                <Text
                  text={$t({ id: 'NextSession_sessionsDone', defaultMessage: 'Sessions done' })}
                  weight="semibold"
                />
                <Text
                  text={$t(
                    {
                      id: 'NextSession_sessionProgress',
                      defaultMessage: `{completedCount}/{totalCount}`,
                    },
                    {
                      completedCount,
                      totalCount,
                    },
                  )}
                  testID="NextSession_sessionProgress"
                />
                <SegmentedProgressBar length={totalCount} progress={completedCount} />
              </View>
            </View>
          ) : null}
        </View>
      ) : null}
    </>
  );
}
