// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NextSessionQueryVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type NextSessionQuery = { readonly patientByPatientID?: { readonly __typename: 'PatientProfile', readonly patient: { readonly __typename: 'Patient', readonly ID: CustomScalars.GQLUUID }, readonly progress: ReadonlyArray<{ readonly content: Types.ContentType, readonly completion: number, readonly completed: boolean, readonly updatedAt: CustomScalars.GQLDateTime }> } | null, readonly treatmentV2?: ReadonlyArray<{ readonly ordinal: number, readonly slug: string, readonly title: string, readonly subtitle?: string | null, readonly description: string, readonly progress: string, readonly artifact?: { readonly slug: string, readonly title: string, readonly badge?: string | null } | null }> | null };


export const NextSessionDocument = gql`
    query NextSession($patientID: UUID!) {
  patientByPatientID(patientID: $patientID) {
    __typename
    patient {
      __typename
      ID
    }
    progress {
      content
      completion
      completed
      updatedAt
    }
  }
  treatmentV2 {
    ordinal
    slug
    title
    subtitle
    description
    progress
    artifact {
      slug
      title
      badge
    }
  }
}
    `;

/**
 * __useNextSessionQuery__
 *
 * To run a query within a React component, call `useNextSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextSessionQuery({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function useNextSessionQuery(baseOptions: Apollo.QueryHookOptions<NextSessionQuery, NextSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NextSessionQuery, NextSessionQueryVariables>(NextSessionDocument, options);
      }
export function useNextSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NextSessionQuery, NextSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NextSessionQuery, NextSessionQueryVariables>(NextSessionDocument, options);
        }
export type NextSessionQueryHookResult = ReturnType<typeof useNextSessionQuery>;
export type NextSessionLazyQueryHookResult = ReturnType<typeof useNextSessionLazyQuery>;

export function queryNextSession(client: Apollo.ApolloClient<unknown>, variables: NextSessionQueryVariables, options?: Omit<Apollo.QueryOptions<NextSessionQuery, NextSessionQueryVariables>, "query" | 'variables'>) {
  return client.query<NextSessionQuery, NextSessionQueryVariables>({
    query: NextSessionDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







