// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LogoutMutationVariables = Types.Exact<{
  deviceID: Types.Scalars['UUID']['input'];
}>;


export type LogoutMutation = { readonly logoutDevice: boolean };


export const LogoutDocument = gql`
    mutation Logout($deviceID: UUID!) {
  logoutDevice(installationID: $deviceID)
}
    `;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      deviceID: // value for 'deviceID'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;

export function performLogout(client: Apollo.ApolloClient<unknown>, variables: LogoutMutationVariables, options?: Omit<Apollo.MutationOptions<LogoutMutation, LogoutMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<LogoutMutation, LogoutMutationVariables>({
    mutation: LogoutDocument,
    variables,
    ...options,
  });
}

// @ts-ignore

import { z } from 'zod';







export const LogoutMutationSchema = z.lazy(() => z.object({
deviceID: Types.UUIDSchema
}))